import "./Business_information.css";
import * as React from "react";
import { Paper, Grid } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import Iframe from "react-iframe";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import getLanguage from "../../Translation/Translation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button, { ButtonProps } from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Pagination 
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as datasheetActions from "../../../actions/datasheet.create.action";
import { DataSheetCreate } from "../../../types/data_sheet.type";
import { MEMBER_ID, server, TOKEN } from "../../../services/Constants";
import * as directorActions from "../../../actions/director.action";
import * as shareholderrActions from "../../../actions/shareholder.action";
import * as dataSheetCreateActions from "../../../actions/datasheet.create.action";
import * as loginActions from "../../../actions/login.action";
import { Search as SearchIcon, Tune } from "@mui/icons-material";

type Business_informationProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1,
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center",
}));

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };
}

function createData(name: string, calories: number, fat: number) {
  return { name, calories, fat };
}

const Business_information: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const dataSheetCreateReducer = useSelector((state: RootReducers) => state.dataSheetCreateReducer);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 

  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    dispatch(datasheetActions.getAllDataSheets(Number(localStorage.getItem(MEMBER_ID))));
    dispatch(directorActions.setDirectorFetchingToState());
    dispatch(shareholderrActions.setShare_HolderrFetchingToState());
  }, []);

  // const rows = [
  //   {id:1,company_name_en:'DCM',calories:111,fat:11.0},
  //   {id:2,company_name_en:'ABC',calories:305,fat:3.7},
  //   {id:3,company_name_en:'XYZ',calories:452,fat:25.0},
  //   {id:4,company_name_en:'DEF',calories:262,fat:16.0},
  //   {id:5,company_name_en:'PPP',calories:159,fat:26.0},
  // ]
  // .sort((a, b) => (a.calories < b.calories ? -1 : 1));
  const [rows, setrows] = React.useState<DataSheetCreate[]>([]);
  const [searched, setSearched] = React.useState<string>("");
  const [searchrows, setsearchrows] = React.useState(rows);
  const [showcloseicon, setshowcloseicon] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2); // จำนวนแถวที่จะแสดงในแต่ละหน้า
  const [currentPage, setCurrentPage] = React.useState(1); 

  
  
  React.useEffect(() => {
    if (dataSheetCreateReducer.result_data_list) {
      setrows(dataSheetCreateReducer.result_data_list);
      setsearchrows(dataSheetCreateReducer.result_data_list);
    }
  }, [dataSheetCreateReducer]);

  const conFirmDelete = (id: string) => {
    dispatch(dataSheetCreateActions.updateStatutsDataSheet(Number(id), { status: "I" }));
  };

  const ButtonAddBusiness_information = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#ffffff",
    backgroundColor: "#254E58",
    borderColor: "#162E34",
    "&:hover": {
      backgroundColor: "#162E34",
      color: "#ffffff",
      borderColor: "#254E58",
    },
  }));

  const ButtonEditBusiness_information = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#ffffff",
    backgroundColor: "#4F4A41",
    borderColor: "#4F4A21",
    "&:hover": {
      backgroundColor: "#4F4A21",
      color: "#ffffff",
      borderColor: "#4F4A41",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: "#cccccc",
    },
  }));

  const ButtonDeleteBusiness_information = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#ffffff",
    backgroundColor: "#c30010",
    borderColor: "#de0a26",
    "&:hover": {
      backgroundColor: "#de0a26",
      color: "#ffffff",
      borderColor: "#c30010",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: "#cccccc",
    },
  }));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ฟังก์ชันค้นหาข้อมูล
  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const filteredRows = rows.filter((row) => {
      return row.th_company_name?.toString().toLowerCase().includes(searchedVal.toLowerCase());
    });
    setsearchrows(filteredRows);
    setCurrentPage(1); // รีเซ็ตหน้าเป็นหน้าแรกเมื่อมีการค้นหาใหม่
  };

  // ฟังก์ชันเปลี่ยนหน้า
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value); // เมื่อคลิกเปลี่ยนหน้า
  };

  // ฟังก์ชันสำหรับการแสดงข้อมูลตามหน้า
  const currentRows = searchrows.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // คำนวณช่วงหน้าแสดง
  const getPageRange = () => {
    const totalPages = Math.ceil(searchrows.length / rowsPerPage);
    const startPage = Math.max(1, currentPage - 2); // แสดงหน้าตั้งแต่หน้า 1 ถ้าอยู่หน้า 3 จะเริ่มแสดงจากหน้า 1
    const endPage = Math.min(totalPages, startPage + 4); // แสดงได้สูงสุด 5 หน้า

    return { startPage, endPage };
  };

  const { startPage, endPage } = getPageRange();

  const handleClickClearSearch = () => {
    setSearched("");
    setsearchrows(rows);
    setshowcloseicon(false);
  };

  return (
    <>
      <Container fixed sx={{ mt: 3}} >
        <Box 
            sx={{ }}
        >
          <Grid 
            container 
            alignItems="center" 
            className="bs-container"
          >
            {/* TextField */}
            <Grid item xs={0} sm={0} md={0} lg={1} xl={1} />
            <Grid 
                item 
                xs={12} 
                sm={6} 
                md={6} 
                lg={5} 
                xl={5}
            >
                <TextField 
                    value={searched}
                    onChange={(e) => {
                      requestSearch(e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <IconButton
                                sx={{
                                    marginLeft: -1.8, 
                                    color: searched ? "#000" : "inherit",
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        ),
                        endAdornment: (
                            <IconButton 
                                onClick={() => {
                                    requestSearch("");
                                    setSearched("");
                                }}
                                sx={{
                                    marginRight: -1.8, // ชิดขวามากขึ้น
                                    "&:hover": {
                                        color: "#000", // เปลี่ยนสีเป็นสีดำเข้มเมื่อ hover
                                    },
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ),
                    }}
                    fullWidth
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 3, // ทำขอบมน
                        },
                        "& .MuiInput-underline:before": {
                            borderBottom: 1,
                            borderColor: "divider",
                        },
                    }}
                />
            </Grid>
            {/* Button */}
            <Grid 
                item 
                container 
                xs={12} 
                  sm={6} 
                  md={6} 
                  lg={5} 
                  xl={5}
                  justifyContent="flex-end"
              >
                  <Button 
                      size="large"
                      variant="contained"
                      sx={{
                          marginTop: { xs: 4, sm: 0 }, // เว้นระยะเฉพาะหน้าจอเล็ก
                          width: 190,
                          backgroundColor: "#BF896B",
                          textTransform: "none", 
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderRadius: 3,
                          "&:hover": {
                              backgroundColor: "#9e6a52", // สีพื้นหลังเมื่อ hover (เข้มกว่าเดิม)
                          },
                      }}
                      className=""
                      onClick={() => {
                        navigate("/datasheet/create");
                      }}
                  >
                      {t("Add New Business")}
                  </Button>
              </Grid>
              <Grid item xs={0} sm={0} md={0} lg={1} xl={1} />
          </Grid>
            
          <Grid container className="b-container">
            { !isSmallScreen ?(
              <>
              <Grid item xs={12}  sx={{ height: "54vh",display: 'flex', flexDirection: 'column' }} >
                {currentRows.length > 0 ? (
                  currentRows.map((row: any, index: number) => (
                    <Card key={index}  className="bt-container"  sx={{  border: "none", boxShadow: "none",}}> 
                      <CardContent 
                        sx={{   
                          border: "1px solid #e9e9e9", 
                          boxShadow: "none",
                          borderRadius: "8px",
                          marginBottom: "30px"
                        }}>
                        <Grid container>
                          {/* คอลัมน์ที่แสดงข้อมูลบริษัท */}
                          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                            <Typography sx={{ fontSize: '22px', fontWeight: "600", color: "#000" }}>
                              {row.th_company_name}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", marginTop: "15px", paddingLeft: "10px" }}>
                              {`Registration No.`}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", marginTop: "5px", paddingLeft: "10px" }}>
                              {`Issued on`}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", marginTop: "5px", paddingLeft: "10px" }}>
                              {`Address`}
                            </Typography>
                          </Grid>

                          {/* คอลัมน์ที่แสดงสถานะและข้อมูลอื่น ๆ */}
                          <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                            <Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between", // จัดให้ข้อความอยู่ซ้ายและปุ่มอยู่ขวา
                                  alignItems: "center", // จัดตำแหน่งให้แนวตั้งอยู่กลาง
                                  height: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    marginTop: "9px",
                                    fontWeight: "600",
                                    color: row.status === "Active" ? "#3A7B1F" : "#9b9b9b",
                                  }}
                                >
                                  {row.status}
                                </Typography>
                                
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row", // ใช้แถว (row) เพื่อให้ปุ่มอยู่ในแถวเดียวกัน
                                    alignItems: "center", // จัดตำแหน่งปุ่มให้อยู่กลางในแนวตั้ง
                                    gap: 1, // เพิ่มระยะห่างระหว่างปุ่ม
                                  }}
                                >
                                  <Button
                                    disabled={row.status !== "Active"}
                                    variant="contained"
                                    sx={{
                                      width: 80, // ปรับขนาดปุ่ม
                                      height: 30,
                                      backgroundColor: "#BF896B",
                                      textTransform: "none",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      borderRadius: 3,
                                      "&:hover": { backgroundColor: "#9e6a52" },
                                    }}
                                    onClick={() => {
                                      if (row.status === "Active") {
                                        navigate(`/datasheet/edit/${row.id}`);
                                      }
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    disabled={row.status !== "Active"}
                                    variant="contained"
                                    sx={{
                                      width: 80, // ปรับขนาดปุ่ม
                                      height: 30,
                                      backgroundColor: "#ff6363",
                                      textTransform: "none",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      borderRadius: 3,
                                      "&:hover": { backgroundColor: "#b20000" },
                                    }}
                                    onClick={() => {
                                      if (row.status === "Active") {
                                        conFirmDelete(row.id)
                                      }
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Box>
                              </Box>
                            </Grid>
                            <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", marginTop: "15px" }}>
                              {row.th_regis_no}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", marginTop: "5px" }}>
                              {row.th_issued_on}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", marginTop: "5px" }}>
                              {row.th_address}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography gutterBottom variant="h5" component="h2" sx={{ color: '#4F4A41', textAlign: 'center', mt: 8 }}>
                    You have no Business Information
                  </Typography>
                )}
              </Grid>

              {searchrows.length > 2 && rowsPerPage > 0 && (
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
                  <Pagination
                    count={Math.ceil(searchrows.length / rowsPerPage)}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                    sx={{
                      "& .MuiPaginationItem-root": {
                          fontWeight: "500",
                          fontSize: "16px", // ขนาดตัวอักษร
                          fontFamily: "'Roboto', sans-serif", // ฟอนต์
                          "&:hover": {
                            backgroundColor: "transparent", // ไม่ให้มีพื้นหลังเมื่อ hover
                            color: "#9e6a52", // สีตัวเลขเมื่อ hover
                          },
                          "&.Mui-selected": {
                            backgroundColor: "transparent", // สีพื้นหลังเมื่อเลือก
                            color: "#9e6a52", // สีตัวเลขเมื่อเลือก
                          },
                          "&.MuiPaginationItem-root.Mui-selected:hover": {
                            backgroundColor: "#9b9b9b", // สีพื้นหลังเมื่อเลือก และ hover
                            color: "#9e6a52", // สีตัวเลขเมื่อเลือกและ hover
                          },
                        }
                    }}
                  />
                </Grid>
              )}
              </>
            ): (
              <>
                <Grid item xs={12} >
                  {currentRows.length > 0 ? (
                    currentRows.map((row: any, index: number) => (
                      <>
                        <Card 
                          key={index} 
                          className="bt-container"
                          sx={{   
                            border: "1px solid #e9e9e9", 
                            boxShadow: "none",
                            borderRadius: "8px",
                            marginBottom: "30px"
                          }}
                        > 
                          <CardContent >
                            <Grid container justifyContent="space-between" alignItems="start" className="rp-b" >
                                  <Typography sx={{ fontSize: '22px', fontWeight: "600", color: "#000" }}>
                                  </Typography>
                                  <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row", // ใช้แถว (row) เพื่อให้ปุ่มอยู่ในแถวเดียวกัน
                                        alignItems: "center", // จัดตำแหน่งปุ่มให้อยู่กลางในแนวตั้ง
                                        gap: 1, // เพิ่มระยะห่างระหว่างปุ่ม
                                      }}
                                    >
                                      <Button
                                        disabled={row.status !== "Active"}
                                        variant="contained"
                                        sx={{
                                          width: 80, // ปรับขนาดปุ่ม
                                          height: 30,
                                          backgroundColor: "#BF896B",
                                          textTransform: "none",
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                          borderRadius: 3,
                                          "&:hover": { backgroundColor: "#9e6a52" },
                                        }}
                                        onClick={() => {
                                          if (row.status === "Active") {
                                            navigate(`/datasheet/edit/${row.id}`);
                                          }
                                        }}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        disabled={row.status !== "Active"}
                                        variant="contained"
                                        sx={{
                                          width: 80, // ปรับขนาดปุ่ม
                                          height: 30,
                                          backgroundColor: "#ff6363",
                                          textTransform: "none",
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                          borderRadius: 3,
                                          "&:hover": { backgroundColor: "#b20000" },
                                        }}
                                        onClick={() => {
                                          if (row.status === "Active") {
                                            conFirmDelete(row.id)
                                          }
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </Box>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="start" className="rp-m" sx={{marginTop: "20px"}}>
                              {/* คอลัมน์ที่แสดงข้อมูลบริษัท */}
                                  <Typography sx={{ fontSize: '22px', fontWeight: "600", color: "#000" }}>
                                    {row.th_company_name}
                                  </Typography>
                                  <Typography sx={{ fontSize: '16px', marginTop: "6px", fontWeight: "600", color: row.status === 'Active' ? '#3A7B1F' : '#9b9b9b' }}>
                                    {row.status}
                                  </Typography>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="start" sx={{marginTop: "15px"}} className="rp-m">
    
                              <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b",  paddingLeft: "10px" }}>
                                {`Registration No.`}
                              </Typography>
                              <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", }}>
                                {row.th_regis_no}
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="start" sx={{marginTop: "15px"}} className="rp-m">
    
                              <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b",  paddingLeft: "10px" }}>
                                {`Issued on`}
                              </Typography>
                              <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", }}>
                                {row.th_issued_on}
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="start" sx={{margin: "15px 0 0 0"}} className="rp-m">
    
                              <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b",  paddingLeft: "10px" }}>
                                {`Address`}
                              </Typography>
                              <Typography sx={{ fontSize: '16px', fontWeight: "500", color: "#9b9b9b", }}>
                                {row.th_address}
                              </Typography>
                            </Grid>

                          </CardContent>
                        </Card>
                      </>

                    ))
                  ) : (
                    <Typography gutterBottom variant="h5" component="h2" sx={{ color: '#4F4A41', textAlign: 'center', mt: 8 }}>
                      You have no Business Information
                    </Typography>
                  )}
                </Grid>

                {searchrows.length > 2 && rowsPerPage > 0 && (
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
                    <Pagination
                      count={Math.ceil(searchrows.length / rowsPerPage)}
                      page={currentPage}
                      onChange={handleChangePage}
                      color="primary"
                      sx={{
                        "& .MuiPaginationItem-root": {
                            fontWeight: "500",
                            fontSize: "16px", // ขนาดตัวอักษร
                            fontFamily: "'Roboto', sans-serif", // ฟอนต์
                            "&:hover": {
                              backgroundColor: "transparent", // ไม่ให้มีพื้นหลังเมื่อ hover
                              color: "#9e6a52", // สีตัวเลขเมื่อ hover
                            },
                            "&.Mui-selected": {
                              backgroundColor: "transparent", // สีพื้นหลังเมื่อเลือก
                              color: "#9e6a52", // สีตัวเลขเมื่อเลือก
                            },
                            "&.MuiPaginationItem-root.Mui-selected:hover": {
                              backgroundColor: "#9b9b9b", // สีพื้นหลังเมื่อเลือก และ hover
                              color: "#9e6a52", // สีตัวเลขเมื่อเลือกและ hover
                            },
                          }
                      }}
                    />
                  </Grid>
                )}
              </>
            )}

          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Business_information;
