import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link, useNavigate,useMatch } from "react-router-dom";
import { useAppDispatch } from "../../..";
import Avatar from "@mui/material/Avatar";
import PersonIcon from '@mui/icons-material/Person';
import { server, TOKEN,MEMBER_ID,reqeuestMassageFileUrl,reqeuestMassageImageUrl } from "../../../services/Constants";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography,useMediaQuery, useTheme  } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";
import "./RequestTrackingSelectPage.css";
import * as loginActions from "../../../actions/login.action";
import * as RequestMassageActions from "../../../actions/request_massage.action";
import * as requestActions from "../../../actions/request.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import Container from '@mui/material/Container';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent,DialogActions,DialogContentText } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as requestSubTypeListActions from "../../../actions/requestsubtype.list.action";
import * as requestEditActions from "../../../actions/request.edit.actions";
import * as userActions from "../../../actions/user.action";
import * as RequestAssignActions from "../../../actions/request_assign.action";
import * as RequestLogActions from "../../../actions/request_log.action";
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import i18next from 'i18next';
const Swal = require('sweetalert2')


export default function RequestTrackingSelectPage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const loginReducer = useSelector((state: RootReducers) => state.loginReducer);
    const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
    const RequestAssignStateReducer = useSelector((state: RootReducers) => state.RequestAssignStateReducer);
    const RequestLogStateReducer = useSelector((state: RootReducers) => state.RequestLogStateReducer);
    const RequestMassageStateReducer = useSelector((state: RootReducers) => state.RequestMassageStateReducer);
    const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const match = useMatch("my-request-tracking-select/:mode/:id");
    const req_id = match?.params.id;
    const req_mode = match?.params.mode;
    const [disable_h, setDisableH] = React.useState<boolean>(false);
    const [checkedUserList, setCheckedUserList] = React.useState([]);
    const [checkedUserOwner, setCheckedUserOwner] = React.useState([]);
    const [status_select, setStatusSelect] = React.useState<number>(0)
    const [checkOwnerAssign, setcheckOwnerAssign] = React.useState<number>(0)
    const [skipped, setSkipped] = React.useState<any[]>([]);
    const [status_list, setStatusList] = React.useState<any[]>([]);
    const [assign_permission, setassign_permission] = React.useState(0);
    const [reqestList, setRequestList] = React.useState<any[]>([]);
    let [user_list, setUserList] = React.useState<any[]>([]);
    let [log_list, setLogList] = React.useState<any[]>([]);
    let [check_role, setcheck_role] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
            //   navigate("/login");
            dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
        const callawaitfunction = async () =>{
            await dispatch(RequestMassageActions.loadRequestMassage(Number(req_id)));
            await dispatch(requestActions.loadRequestByIdDetail(Number(req_id)));
            await dispatch(userActions.loadUserAuto(Number(req_id)));
            await dispatch(RequestAssignActions.loadRequestById(Number(req_id)));
            // await dispatch(RequestAssignActions.loadRequestAssignlevel(Number(req_id)));
            await dispatch(RequestLogActions.loadRequestLogById(Number(req_id)));
            if(req_mode == 'H'){
                setDisableH(true)
            }else{
                setDisableH(false)
                if(req_mode == 'R'){
                    setcheck_role(true)
                }
            }
        }
        callawaitfunction();
    }, []);
    React.useEffect(() => {
        if (loginReducer.resulttoken) {
            // assign_permission = loginReducer.resulttoken.assign_permission;
            setassign_permission(Number(loginReducer.resulttoken.assign_permission))
        }
    }, [loginReducer.resulttoken]);
    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        UserStateReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;
        })
        setUserList(dataList)
        console.log("dataList")
        console.log(dataList)
    }, [UserStateReducer.result]);
    React.useEffect(() => {
        let  dataList:any = [];
        let index = 0;
        console.log("dataList log")
        console.log(RequestLogStateReducer.result)
        RequestLogStateReducer.result.forEach((item) =>{
            // dataList[index]= {text : item.type_table}
            if(Number(item.status) == 1){
                item.status_name = `${t('Draft')}`
            }else if(Number(item.status) == 2){
                item.status_name = `${t(`Not Started`)}`
            }else if(Number(item.status) == 3){
                item.status_name = `${t('In Progress')}`
            }else if(Number(item.status) == 4){
                item.status_name = `${t(`Done`)}`
            }else if(Number(item.status) == 5){
                item.status_name = `${t('Cancel')}`
            }else if(Number(item.status) == 6){
                item.status_name = `${t('Overdue')}`
            }else if(Number(item.status) == 7){
                item.status_name = `${t('Pending')}`
    
            }else if(Number(item.status) == 8){
                item.status_name = `${t('Assign')}`
            }else if(Number(item.status) == 9){
                item.status_name = `${t('Closed')}`
            }else if(Number(item.status) == 10){
                item.status_name = item.description
            }

            if(item.type_table == "assign"){
                dataList[index] = {date_show: item.updated_at, type:`data`,name_by : item.first_name_by, text: `${t('assigned this ticket to')}`,status: '', name_to: item.first_name_to}
            }else if(item.type_table == "log"){
                if(item.status_name == 'Cancel'){
                    dataList[index] = {date_show: item.updated_at, type:`data`,name_by : item.first_name_by, text: `${t('cancelled this ticket')}`,status: '', name_to: item.first_name_to}    
                }else if(item.status_name == 'Done'){
                    dataList[index] = {date_show: item.updated_at, type:`data`,name_by : item.first_name_by, text: `${t('done this ticket')}`,status: '', name_to: item.first_name_to}
                }else if(item.status_name == 'Closed'){
                    dataList[index] = {date_show: item.updated_at, type:`data`,name_by : item.first_name_by, text: `${t('closed this ticket')}`,status: '', name_to: item.first_name_to}
                }else if(item.status == 10){
                    dataList[index] = {date_show: item.updated_at, type:`data`,name_by : item.first_name_by, text: `${t('has changed the request due date to')}`,status: item.status_name, name_to: item.first_name_to}
                }else{
                    dataList[index] = {date_show: item.updated_at, type:`data`,name_by : item.first_name_by, text: `${t('changed status to')}`,status: item.status_name, name_to: item.first_name_to}
                }
            }else if(item.type_table =="message"){
                if(item.file_name != ''){
                    if(item.image_name != ''){
                        // alert("testHH")
                        let file_show = item.file_name.split(",")
                        for(let i=0;i < file_show.length;i++){
                            dataList[index] = {date_show: item.updated_at, type:`file_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:file_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                        let image_show = item.image_name.split(",")
                        for(let i=0;i < image_show.length;i++){
                            dataList[index] = {date_show: item.updated_at, type:`image_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:image_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                        
                    }else{
                        let file_show = item.file_name.split(",")
                        for(let i=0;i < file_show.length;i++){
                            dataList[index] = {date_show: item.updated_at, type:`file_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:file_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                    }

                }else{
                    if(item.image_name != ''){
                        let image_show = item.image_name.split(",")
                        for(let i=0;i < image_show.length;i++){
                            dataList[index] = {date_show: item.updated_at, type:`image_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:image_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                    }
                    // else{
                    //     dataList[index].text = `${item.first_name_by} send ${item.description} to this ticket`;
                    // }
                }
            }else{
                dataList[index] = { name : item.first_name_by, text: "",status: '',name_to: item.first_name_to };
            }
            index += 1;
            // console.log(dataList[index].text)
        })  
        console.log(dataList)
        setLogList(dataList)
    }, [RequestLogStateReducer.result,i18next.language]);

    React.useEffect(() => {
        if(RequestAssignStateReducer.isResAll){
            // alert("list")
            let level_owner_ass:any = [];
            if(user_list.length > 0 && RequestAssignStateReducer.result.length > 0){
                console.log("user_list in assign")
                console.log(user_list)
                let data_assign_select:any = [];
                RequestAssignStateReducer.result.forEach((item ,index) =>{
                    if(Number(item.level) == 2 || Number(item.level) == 3){
                        data_assign_select.push(user_list.find(option => Number(option.id) === Number(item.user_assign_to)));
                    }
                    if(Number(item.level) == 1){
                        level_owner_ass.push(item.user_assign_to)
                    }
                })
                console.log("checkedUserList")
                console.log(data_assign_select)
                setCheckedUserList(data_assign_select)
                console.log(level_owner_ass)
                let assign_owner = 0;
                for(let i=0;i<level_owner_ass.length;i++){
                    if(Number(level_owner_ass[i]) == Number(localStorage.getItem(MEMBER_ID))){
                        assign_owner = 1;
                    }
                }
                console.log(`assign_owner:` + assign_owner)
                setcheckOwnerAssign(Number(assign_owner))
                if(RequestAssignStateReducer.result[0].user_owner){
                    setCheckedUserOner(Number(RequestAssignStateReducer.result[0].user_owner))
                    setCheckedUserOwner(user_list.find(option => Number(option.id) === Number(RequestAssignStateReducer.result[0].user_owner)))
                }else{
                    setCheckedUserOwner(user_list.find(option => Number(option.id) === Number(RequestAssignStateReducer.result[0].user_owner)) ||null)
                }

            }
        }
    }, [RequestAssignStateReducer.isResAll,RequestAssignStateReducer.result,user_list]);
    // React.useEffect(() => {
    //     if(RequestAssignStateReducer.isResOwner){
    //         alert("level")
    //         // RequestAssignStateReducer.result.forEach((item ,index) =>{
    //         //     console.log("datalevel")
    //         //     console.log(item)
    //         // })
    //     }
    // }, [RequestAssignStateReducer.isResOwner]);

    const [disable_s9, setdisable_s9] = React.useState<boolean>(false);
    React.useEffect(() => {
        if(requesReducer.result.length  > 0){
            let  dataList:any[] = [];
            let index = 0;
            requesReducer.result.forEach((item,index) =>{
                if(index == 0){
                    if(Number(item.status) == 9){
                        setdisable_s9(true)
                    }else{
                        setdisable_s9(false)
                    }
                }
                if(Number(item.status) == 1)  {
                    item.request_status_show = `${t('Draft')}`;
                }else if(Number(item.status) == 2){
                    item.request_status_show = `${t('Not Started')}`;
                }else if(Number(item.status) == 3){
                    item.request_status_show = `${t('In Progress')}`;
                }else if(Number(item.status) == 4){
                    item.request_status_show = `${t('Done')}`;
                }else if(Number(item.status) == 5){
                    item.request_status_show = `${t('Cancel')}`;
                }else if(Number(item.status) == 6){
                    item.request_status_show = `${t('Overdue')}`;
                }else if(Number(item.status) == 7){
                    item.request_status_show = `${t('Pending')}`;
                }else if(Number(item.status) == 8){
                    item.request_status_show = `${t('Assign')}`;
                }else if(Number(item.status) == 9){
                    item.request_status_show = `${t('Closed')}`;
                }else{
                    item.request_status_show = `${t('Close')}`;
                }
                if(i18next.language == 'en'){
                    item.request_name_show = item.request_type_name_en;
                    item.request_sub_show = item.request_sub_name_en;
                }else if(i18next.language == 'th'){
                    item.request_name_show = item.request_type_name;
                    item.request_sub_show = item.request_sub_name;
                }else{
                    return;
                }
                dataList[index] = item;
                index += 1;
            })
            console.log("dataList request")
            console.log(dataList)
            setRequestList(dataList)
        }
    }, [requesReducer.result,i18next.language]);
    
    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        requesReducer.res_skip.forEach((item) =>{
            dataList[index] = item;
            index += 1;
        })
        console.log("res_skip:" + JSON.stringify(dataList))
        setSkipped(dataList)
    }, [requesReducer.res_skip]);

    React.useEffect(() => {
        if(reqestList.length > 0){
            let data_list:any = [];
            if(check_role || reqestList[0].status == 9){
                data_list = [
                    {status_id: 3,status_name: `${t('In Progress')}`},
                    {status_id: 7,status_name: `${t('Pending')}`},
                    {status_id: 6,status_name: `${t('Overdue')}`},
                    {status_id: 5,status_name: `${t('Cancel')}`},
                    {status_id: 4,status_name: `${t(`Done`)}`},
                    {status_id: 9,status_name: `${t(`Closed`)}`},
                ]
            }else{
                data_list = [
                    {status_id: 3,status_name: `${t('In Progress')}`},
                    {status_id: 7,status_name: `${t('Pending')}`},
                    {status_id: 6,status_name: `${t('Overdue')}`},
                    {status_id: 5,status_name: `${t('Cancel')}`},
                    {status_id: 4,status_name: `${t(`Done`)}`},
                ]
            }
        setStatusList(data_list)
    }
    }, [i18next.language,check_role,reqestList]);
    // {status_id: 3,status_name: `${t('In Progress')}`},
    // {status_id: 7,status_name: `${t('Pending')}`},
    // {status_id: 6,status_name: `${t('Overdue')}`},
    // {status_id: 5,status_name: `${t('Cancel')}`},
    // {status_id: 4,status_name: `${t(`Done`)}`},

    const [dataDefAuto, setDataDefAuto] = React.useState({})
        // if(reqestList.length > 0){
        //     const foundRole = status_list.find(option => Number(option.status_id) === Number(reqestList[0].status));
        //     return foundRole || null;
        // }
    // });
    
    React.useEffect(() => {
        if(reqestList.length > 0){
            const foundRole = status_list.find(option => Number(option.status_id) === Number(reqestList[0].status));
            setDataDefAuto(foundRole|| null);
            setStatusSelect(Number(reqestList[0].status))
            console.log("dataDefAuto 2")
            console.log(foundRole)
            console.log(dataDefAuto)
        }
    }, [status_list, reqestList]);


    const [openDialog, setOpenDialog] = React.useState<boolean>(false);



    const [DataMassageList, setDataMassageList] = React.useState<any[]>([]);
    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        RequestMassageStateReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;
            if(item.file_name != ""){
                item.file_name_show = item.file_name.split(",");
            }else{
                item.file_name_show = []
            }
            if(item.image_name != ""){
                item.file_image_show = item.image_name.split(",");
            }else{
                item.file_image_show = []
            }
        })
        setDataMassageList(dataList)
    }, [RequestMassageStateReducer.result]);
    const [Reply, setReply] = React.useState("");
    React.useEffect(() => {
        if (RequestMassageStateReducer.isAdddata) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated successfully.")}`,
                icon: "success",
            });
            dispatch(RequestMassageActions.setMassageFetchingToState());
            dispatch(RequestMassageActions.loadRequestMassage(Number(req_id)));
            dispatch(RequestLogActions.loadRequestLogById(Number(req_id)));
        } 
    }, [RequestMassageStateReducer]);
    // data test


    //get data input
    const [datasheet_id, setCompanySelected] = React.useState(0);
    const [statusD, setStatusD] = React.useState("1");
    const [statusS, setStatusS] = React.useState("2");
    const [remark, setRemark] = React.useState("");
    
    const [req_type, setRequestTypeSelected] = React.useState<any[]>([]);

    const [req_sub_type, setRequestSubTypeSelected] = React.useState<any[]>([]);
    const start = Date.now();
    const [requestDate, setRequestDate] = React.useState<Dayjs | null>();
    const [tempDate, setTempDate] = React.useState<Dayjs | null>();
    const [requestDateValue, setRequestValue] = React.useState<Dayjs | null>(dayjs(start));
    //date 
    const [DataRequesttableData, setDataRequesttableData] = React.useState<any[]>([]);
    const [checkedUserOner, setCheckedUserOner] = React.useState(0);
   
    const DataRequestColumns: GridColDef[] = [
        {
            headerName: "Descriptions",
            field: "request_no",
            flex: 1 
        },
        {
            headerName: "Data",
            field: "create_date",
            flex: 1
        },

    ];
    
    const steps = [
        `${t('Not Started')}`,
        `${t('Assign')}`,
        `${t('In Progress')}`,
        `${t('Pending')}`,
        `${t('Overdue')}`,
        `${t('Cancel')}`,
        `${t('Done')}`,
        `${t('Closed')}`,
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    
    React.useEffect(() => {
        setRequestDate(null)
        if(reqestList.length > 0){
            // alert(reqestList[0].status)
            if(Number(reqestList[0].status) == 2){
                setActiveStep(0)
            }else if(Number(reqestList[0].status) == 8){
                setActiveStep(1)
            }else if(Number(reqestList[0].status) == 3){
                setActiveStep(2)
            }else if(Number(reqestList[0].status) == 7){
                setActiveStep(3)
            }else if(Number(reqestList[0].status) == 6){
                setActiveStep(4)
            }else if(Number(reqestList[0].status) == 5){
                setActiveStep(5)
            }else if(Number(reqestList[0].status) == 4){
                setActiveStep(6)
            }else if(Number(reqestList[0].status) == 9){
                setActiveStep(7)
            }else {
                setActiveStep(0)
            }
            // console.log("duedate: " + reqestList[0].duedate)
            if(reqestList[0].duedate){
                setRequestDate(dayjs(reqestList[0].duedate, 'DD/MM/YYYY'))
            }
        }
        console.log("reqestList")
        console.log(reqestList)
    }, [reqestList]);

    //   const handleNext = () => {
    //     setActiveStep(reqestList[0].request_status_show);
    //   };

    const ScrollContainer = styled('div')({
        maxHeight: '500px',
        height:'auto',
        overflowY: 'scroll',
    });
    const Content = styled('div')({
        marginRight: "5px",
        height: 'auto',
    });

    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    }, []);

    const handleadRequestMS = (event: any) => {
        event.preventDefault(); 

        if(imageName.length == 0 && FileName.length == 0 && (Reply == "" || !Reply)){
            const timer = setTimeout(() => {       
                Swal.fire({
                    title: `${t("Information Saved")}`,
                    text: `${t("The information has been saved and updated successfully.")}`,
                    icon: "success",
                });
                dispatch(RequestMassageActions.setMassageFetchingToState());
                dispatch(RequestMassageActions.loadRequestMassage(Number(req_id)));
                dispatch(RequestLogActions.loadRequestLogById(Number(req_id)));
            }, 3000);
        }else{
            let formData = new FormData();
            if (imageName.length > 0) {
                let getImagename = "";
                imageName.forEach((item, index) => {
                formData.append(`file`, item);
                if (index > 0) {
                    getImagename += `,${item.name.replace(/\s/g, '_')}`;
                } else {
                    getImagename += `${item.name.replace(/\s/g, '_')}`;
                }
                });
                formData.append(`image_name`, getImagename);
            }else{
                formData.append(`image_name`, "");
            }
            if (FileName.length > 0) {
                let getfilename = "";
                FileName.forEach((item, index) => {
                formData.append(`file`, item);
                if (index > 0) {
                    getfilename += `,${item.name.replace(/\s/g, '_')}`;
                } else {
                    getfilename += `${item.name.replace(/\s/g, '_')}`;
                }
                });
                formData.append(`file_name`, getfilename);
            }else{
                formData.append(`file_name`, "");
            }

            formData.append(`request_id`, `${req_id}`);
            formData.append(`user_create`, `${localStorage.getItem(MEMBER_ID)}`);
            formData.append(`sequence`, "0");
            formData.append(`description`, Reply);
            console.log(formData)
            dispatch(RequestMassageActions.addRequestMassage(formData,navigate));
            let clear = "";
            setReply(clear)
            setFileName([])
            setFilebase64([])
            setImageName([])
            setImageFilebase64([])
        }
    };
    // const handleadRequestUpdateStatus = (event: any) =>{
    //     event.preventDefault(); 
    //     if(status_select == 0){
    //         Swal.fire({
    //             title: `${t("Check Data")}`,
    //             text: `กรุณาเพิ่มข้อมูลสถานะ`,
    //             icon: "warning",
    //         });
    //         return;
    //     }   
    //     Swal.fire({
    //         title: `${t('Are you sure?')}`,
    //         text: `${t("You won't be able to revert this!")}`,
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: `${t('Yes')}`,
    //         cancelButtonText: `${t('Cancel')}`,
    //     }).then((result:any) => {
    //         if (result.isConfirmed) {
    //             handleConfirmUpdate(event)
    //         }
    //     })
    // }
    const handleAssign = async (event: any) => {
        event.preventDefault();
        if (
            (
                Number(reqestList[0].status != 2) || 
                Number(reqestList[0].status != 8) || 
                Number(reqestList[0].status != 9)
            ) && (Number(reqestList[0].status) != Number(status_select))
        ) {
            await dispatch(requestEditActions.editRequestTrakingEditStatus(
                Number(req_id),
                Number(status_select),
                navigate
            ));
        }

        if (requestDate && (requestDate.format('DD/MM/YYYY')) != reqestList[0].duedate) {
            const dateUpdate: any = requestDate.format('YYYY-MM-DD');
    
            await dispatch(requestEditActions.editRequestEditDuedate(
                Number(req_id),
                dateUpdate,
                navigate
            ));
        }


        if (Number(reqestList[0].status == 8)) {
            if (checkedUserOner == 0) {
                Swal.fire({
                    title: `${t("Check Data")}`,
                    text: `กรุณาเลือกพนักงานเจ้าของงาน`,
                    icon: "warning",
                });
                return;
            }
        }
    
        if (checkedUserList.length == 0) {
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `กรุณาเลือกพนักงาน`,
                icon: "warning",
            });
            return;
        }
    
        let dataset = {
            req_id: 0,
            status: 0,
            status_check: 0,
            user_assign_to: [],
            user_owner: 0,
            level: 0,
            check_owner_assign: 0,
        };
        let status_req = 0;
    
        if (Number(reqestList[0].status == 2)) {
            status_req = 8;
            dataset.level = 1;
        } else if (Number(reqestList[0].status == 8)) {
            status_req = 3;
            dataset.level = 2;
        } else {
            status_req = Number(reqestList[0].status);
            dataset.level = 3;
        }
    
        dataset.req_id = Number(req_id);
        dataset.status = Number(status_req);
        dataset.status_check = Number(reqestList[0].status);
        dataset.user_assign_to = checkedUserList;
        dataset.user_owner = checkedUserOner;
    
        if (Number(checkOwnerAssign) == 1) {
            if (Number(checkedUserOner) == Number(RequestAssignStateReducer.result[0].user_owner)) {
                dataset.check_owner_assign = 0;
            } else {
                dataset.check_owner_assign = Number(checkOwnerAssign);
            }
        }
    
        console.log(dataset);
        dispatch(RequestAssignActions.updaterequestAssign(dataset, navigate));
    };

    // const handleConfirmUpdate = async(event:any) => {
    //         if(Number(reqestList[0].status) != Number(status_select)){
    //             let data_mas: any;
    //             let formData = new FormData();
    //             if(imageName.length == 0 && FileName.length == 0 && (Reply == "" || !Reply)){
    //                 // console.log("req_id:"+req_id) 
    //                 data_mas = null;
    //             }else{
 
    //                 if (imageName.length > 0) {
    //                     let getImagename = "";
    //                     imageName.forEach((item, index) => {
    //                     formData.append(`file`, item);
    //                     if (index > 0) {
    //                         getImagename += `,${item.name.replace(/\s/g, '_')}`;
    //                     } else {
    //                         getImagename += `${item.name.replace(/\s/g, '_')}`;
    //                     }
    //                     });
    //                     formData.append(`image_name`, getImagename);
    //                 }else{
    //                     formData.append(`image_name`, "");
    //                 }
    //                 if (FileName.length > 0) {
    //                     let getfilename = "";
    //                     FileName.forEach((item, index) => {
    //                     formData.append(`file`, item);
    //                     if (index > 0) {
    //                         getfilename += `,${item.name.replace(/\s/g, '_')}`;
    //                     } else {
    //                         getfilename += `${item.name.replace(/\s/g, '_')}`;
    //                     }
    //                     });
    //                     formData.append(`file_name`, getfilename);
    //                 }else{
    //                     formData.append(`file_name`, "");
    //                 }
        
    //                 formData.append(`request_id`, `${req_id}`);
    //                 formData.append(`user_create`, `${localStorage.getItem(MEMBER_ID)}`);
    //                 formData.append(`sequence`, "0");
    //                 formData.append(`description`, Reply);
    //                 console.log(formData)
    //                 data_mas = formData;
    //             }
    //             dispatch(requestEditActions.editRequestTrakingEditStatus(
    //                 Number(req_id),
    //                 Number(status_select),
    //                 data_mas,
    //                 navigate
    //             )) 
 
    //         }else{
    //             if(imageName.length == 0 && FileName.length == 0 && (Reply == "" || !Reply)){
    //                 window.location.reload();
    //                 Swal.fire({
    //                     position: 'center',
    //                     icon: 'success',
    //                     title: 'บันทึกข้อมูลคำขอสำเร็จ',
    //                     showConfirmButton: false,
    //                     timer: 1500
    //                 })

    //             }else{
    //                 let formData = new FormData();

    //                 if (imageName.length > 0) {
    //                     let getImagename = "";
    //                     imageName.forEach((item, index) => {
    //                     formData.append(`file`, item);
    //                     if (index > 0) {
    //                         getImagename += `,${item.name.replace(/\s/g, '_')}`;
    //                     } else {
    //                         getImagename += `${item.name.replace(/\s/g, '_')}`;
    //                     }
    //                     });
    //                     formData.append(`image_name`, getImagename);
    //                 }else{
    //                     formData.append(`image_name`, "");
    //                 }
    //                 if (FileName.length > 0) {
    //                     let getfilename = "";
    //                     FileName.forEach((item, index) => {
    //                         formData.append(`file`, item);
    //                         if (index > 0) {
    //                             getfilename += `,${item.name.replace(/\s/g, '_')}`;
    //                         } else {
    //                             getfilename += `${item.name.replace(/\s/g, '_')}`;
    //                         }
    //                     });
    //                     formData.append(`file_name`, getfilename);
    //                 }else{
    //                     formData.append(`file_name`, "");
    //                 }
            
    //                 formData.append(`request_id`, `${req_id}`);
    //                 formData.append(`user_create`, `${localStorage.getItem(MEMBER_ID)}`);
    //                 formData.append(`sequence`, "0");
    //                 formData.append(`description`, Reply);
    //                 console.log(formData)

    //                 dispatch(requestEditActions.editRequestTrakingEditStatus(
    //                     Number(req_id),
    //                     0,
    //                     formData,
    //                     navigate
    //                 )) 
    //             }
    //         }
    //         // handleadRequestMS(event)
    // };
    const handleConfirmUpdate = async(event:any) => {
                event.preventDefault(); 
                let data_mas: any;
                let formData = new FormData();
                if(imageName.length == 0 && FileName.length == 0 && (Reply == "" || !Reply)){
                    Swal.fire({
                        title: `${t("Failed")}`,
                        text: `${t("Please enter all the details.")}`,
                        icon: "warning",
                    });
                    return;  
                }else{
 
                    if (imageName.length > 0) {
                        let getImagename = "";
                        imageName.forEach((item, index) => {
                        formData.append(`file`, item);
                        if (index > 0) {
                            getImagename += `,${item.name.replace(/\s/g, '_')}`;
                        } else {
                            getImagename += `${item.name.replace(/\s/g, '_')}`;
                        }
                        });
                        formData.append(`image_name`, getImagename);
                    }else{
                        formData.append(`image_name`, "");
                    }
                    if (FileName.length > 0) {
                        let getfilename = "";
                        FileName.forEach((item, index) => {
                        formData.append(`file`, item);
                        if (index > 0) {
                            getfilename += `,${item.name.replace(/\s/g, '_')}`;
                        } else {
                            getfilename += `${item.name.replace(/\s/g, '_')}`;
                        }
                        });
                        formData.append(`file_name`, getfilename);
                    }else{
                        formData.append(`file_name`, "");
                    }
        
                    formData.append(`request_id`, `${req_id}`);
                    formData.append(`user_create`, `${localStorage.getItem(MEMBER_ID)}`);
                    formData.append(`sequence`, "0");
                    formData.append(`description`, Reply);

                    data_mas = formData;

                    dispatch(requestEditActions.editRequestTrakingSendMasge(
                        data_mas,
                        navigate
                    )) 
                }
    };

    //-------------------------------------------file uoload -------------------------------//
    const [Filebase64, setFilebase64] = React.useState<any[]>([]);
    const [FileName, setFileName] = React.useState<any[]>([]);

    const handleFileUpload = (event: any) => {
        let input = event.target;
        let count = input.files.length;
        let index = 0;
        if (input.files) {
            while (count--) {
                let reader = new FileReader();
                reader.onload = (e:any) => {
                        // base64
                    let newStatebase64 = Filebase64;
                    newStatebase64.push(e.target.result);
                    setFilebase64([...newStatebase64]);
  
                };
                if (input.files[index].type === "application/pdf") {
                        // data
                    let newState = FileName;
                    // newState[0] = input.files[index]
                        // setImageFile([...imageFile, input.files[index]]);
                    newState.push(input.files[index]);

                    setFileName([...newState]);
                    reader.readAsDataURL(input.files[index]);

                }
                index++;
            }
           
        }
    };
    const [imageFilebase64, setImageFilebase64] = React.useState<any[]>([]);
    const [imageName, setImageName] = React.useState<any[]>([]);
    const handleFileUploadImage = (event: any) => {
        console.log(imageFilebase64)
        let input = event.target;
        let count = input.files.length;
        let index = 0;
        if (input.files) {
            while (count--) {
                let reader = new FileReader();
                reader.onload = (e:any) => {
  
                        // base64
                    let newStatebase64 = imageFilebase64;
                    newStatebase64.push(e.target.result);
                    // setImageFilebase64(newStatebase64)
                    setImageFilebase64([...newStatebase64]);

                };
                if (input.files[index].type.includes("image")) { // ตรวจสอบว่าไฟล์เป็นรูปภาพหรือไม่
                    let newState = imageName;
                    newState.push(input.files[index]);
                    setImageName(newState);
                    reader.readAsDataURL(input.files[index]);
                }
                index++;
            }
            console.log("imageName")
            console.log(imageName)
            console.log(imageFilebase64)
        }
    };
    
    const handleDeleteFile = (index: number) => {
        let split_file = [...FileName];
        let split_filebase64 = [...Filebase64];

        split_file.splice(index,1);
        split_filebase64.splice(index,1);


        setFileName([...split_file])
        setFilebase64([...split_filebase64])



    }
    const handleDeleteImage = (index: number) => {
        let split_image = [...imageName];
        let split_imagefilebase64 = [...imageFilebase64];

        split_image.splice(index,1);
        split_imagefilebase64.splice(index,1);


        setImageName([...split_image])
        setImageFilebase64([...split_imagefilebase64])
    }
    //------------------------------------end file upload ------------------------------//
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [imageNameShow, setImageNameShow] = React.useState(String);
    const [indexOpenImage, setindexOpenImage] = React.useState(Number);

    const handleOpenDialog = (index:number,imageName:string) => {
        setindexOpenImage(index);
        setImageNameShow(imageName);
        setDialogOpen(true);
    }
  
    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const [dialogOpenBox, setDialogOpenBox] = React.useState(false);
    const [imageNameShowBox, setimageNameShowBox] = React.useState(String);
    const handleOpenDialogBox = (imageName:string) => {
        setimageNameShowBox(imageName);
        setDialogOpenBox(true);
    }
  
    const handleCloseDialogBox = () => {
        setDialogOpenBox(false);
    }
    const ClearValue = () => {
        window.location.reload();
    };

    const handleUpdateDuedate = (newValue: Dayjs | null) => {
        if (newValue) {

            const dateUpdate = newValue.format('YYYY-MM-DD');
            dispatch(requestEditActions.editRequestEditDuedate(
                Number(req_id),
                dateUpdate,
                navigate
            ))
        } else {
            console.log("No date selected");
        }
    };

    return (

        <Container fixed sx={{ mt: 3}} >
        <Grid  
            xs={12} sm={12} md={12} lg={12} xl={12} 
            container
            justifyContent="center"
            sx={
                { 
                    margin: '0 auto'
                }
            }
        >
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                margin: "50px 0 0 -150px",
                            }}
                            className="back-bottom"
                        >
                            <ChevronLeftIcon 
                                sx={{
                                    fontSize: "35px",
                                    '&:hover': {
                                        color: '#BF896B', // สีเมื่อ hover
                                    },
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if(req_mode == 'H'){
                                        navigate("/tracking-history")
                                    }else{
                                        navigate("/my-request-tracking")
                                    }
                                }}
                            />
                              {/* <Typography 
                                        gutterBottom 
                                        variant="h5" 
                                        component="h2" 
                                        sx={{
                                            color:'#4F4A41',
                                            textAlign: "left",
                                            marginTop: 2,
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            if(req_mode == 'H'){
                                                navigate("/tracking-history")
                                            }else{
                                                navigate("/my-request-tracking")
                                            }
                                        }}
                                    >
                                        {t(`<`)}
                                    </Typography> */}
                        </Grid>
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:"-55px",
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
                <CardContent>
                    <Grid container sx={{marginBottom: "10px"}}>
                        
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                           
                        >
                            {
                                reqestList.length > 0 && (
                                        <Typography 
                                            gutterBottom 
                                            sx={{
                                                color:'#252525',
                                                textAlign: "left",
                                                fontSize: "28px",
                                                fontWeight: "500"
                                            }}
                                        >
                                            {t(`Request No. `)}{reqestList[0].request_no}
                                        </Typography>
                                    )
                            }
                        </Grid>
                    </Grid>
                    <Grid container>
                        {/* datatable */}
                        {
                            reqestList.length > 0 && (
                            <>
                            <Grid 
                                xs={12} sm={12} md={5} lg={5} xl={5} 
                                direction="column" 
                                container item
                                sx={{
                                    
                                    marginBottom: '1rem'
                                }}
                            >
                            {
                                !disable_h 
                                && Number(reqestList[0].status) != 4 
                                && Number(reqestList[0].status) != 5 
                                && Number(reqestList[0].status) != 9 ?
                                <>
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            marginBottom: '1rem',
                                        }}
                                    >   
                                            <Grid 
                                                xs={12} sm={12} md={12} lg={12} xl={12} 
                                                className="box-assign"
                                            >   {
                                                
                                                reqestList.length>0 && 
                                                (Number(reqestList[0].status) == 8 || Number(checkOwnerAssign) == 1)?
                                                <>
                                                <Grid container>
                                                    <Grid 
                                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                                    >
                                                        <Typography 
                                                            component='div' 
                                                            variant="body1"
                                                            sx={{ 
                                                                fontSize: "18px",
                                                                marginBottom: '0.4rem'
                                                            }}
                                                        >{t(`Assigned Owner`)} </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid 
                                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                                        sx={{
                                                            paddingBottom: "1rem",
                                                        }}
                                                    >
                                                            <Autocomplete
                                                                value={checkedUserOwner}
                                                                disableClearable={true}
                                                                // onChange={(event, value: any) => setCompanySelected(value?.id)} 
                                                                size="small"
                                                                clearOnEscape
                                                                disablePortal
                                                                options={user_list}
                                                                getOptionLabel={(options) => options.first_name}
                                                                isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                                                sx={{ minWidth: '100%' }}
                                                                onChange={(e,value: any) => {
                                                                    setCheckedUserOner(value?.id)
                                                                    setCheckedUserOwner(value)
                                                                }}
                                                                renderInput={(params) => <TextField {...params}  variant="outlined"  />}
                                                            />

                                                        
                                                    </Grid>
                                                </Grid>
                                                </>
                                                :""}
                                                <Grid container>
                                                    <Grid 
                                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                                        sx={{
                                                            paddingBottom: "0.05rem",
                                                        }}
                                                    >
                                                        <Typography 
                                                            component='div' 
                                                            variant="body1"
                                                            sx={{ 
                                                                fontSize: "18px",
                                                                marginBottom: '0.4rem'
                                                            }}
                                                        >{t(`Assigned To`)} </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid 
                                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                                    >
                                                        <Autocomplete
                                                            value={checkedUserList}
                                                            multiple
                                                            size="small"
                                                            fullWidth
                                                            limitTags={10}
                                                            id="multiple-limit-tags"
                                                            options={user_list || null}
                                                            getOptionLabel={(option) => option.first_name}
                                                            isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                                            defaultValue={[]}
                                                            onChange={(event, newValue:any) => {
                                                                setCheckedUserList(newValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    variant="outlined"
                                                                    {...params}  
                                                                />
                                                            )}
                                                            sx={{
                                                                //  width:500,
                                                                "& .MuiInput-underline:before": {
                                                                borderBottom: 1,
                                                                borderColor: "divider",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            display: 'flex',   // ใช้ flexbox เพื่อให้อยู่ในบรรทัดเดียวกัน
                                            marginBottom: "2rem",
       
                                        }}
        
                                    >
                                        <Grid 
                                            container
                                            sx={{
                                                display: 'flex',   // ใช้ flexbox เพื่อให้อยู่ในบรรทัดเดียวกัน
                                                alignItems: 'flex-end',  // จัดให้ทั้งสองอยู่ในแนวเดียวกัน
                                            }}
                                        >
                                                {
                                                    reqestList.length > 0 && !disable_h && reqestList[0].status != 9 ? (
                                                        <>
                                                            {
                                                                reqestList.length > 0 && (
                                                                    Number(reqestList[0].status) != 2 && Number(reqestList[0].status) != 8
                                                                ) ? (
                                                                    <>
                                                                        <Grid xs={12} sm={5} md={5} lg={5} xl={5} className="box-res">
                                                                            <Typography 
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                }}
                                                                            >
                                                                                {t(`Status`)}
                                                                            </Typography>
                                                                            <Autocomplete
                                                                                fullWidth
                                                                                value={dataDefAuto}
                                                                                options={status_list}
                                                                                getOptionLabel={(options) => options.status_name}
                                                                                filterSelectedOptions
                                                                                disableClearable={true}
                                                                                size="small"
                                                                                disabled={disable_s9}
                                                                                onChange={(event, value: any) => {
                                                                                    setStatusSelect(value?.status_id);
                                                                                    setDataDefAuto(value);
                                                                                }} 
                                                                                disablePortal
                                                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} sm={5} md={5} lg={5} xl={5} className="box-res">
                                                                            <Typography 
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                }}
                                                                            >
                                                                                {t(`Due Date`)}
                                                                            </Typography>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                                                                <MobileDatePicker
                                                                                    value={tempDate || requestDate} // แสดงค่าชั่วคราวหรือค่าปัจจุบัน
                                                                                    onChange={(newValue) => {
                                                                                        setTempDate(newValue); // อัปเดตค่าชั่วคราวเมื่อเลือก
                                                                                    }}
                                                                                    onClose={() => {
                                                                                        if (tempDate) {
                                                                                            setRequestDate(tempDate); // ยืนยันค่าที่เลือก
                                                                                            // handleUpdateDuedate(tempDate); // เรียกฟังก์ชันเมื่อกด OK
                                                                                            setTempDate(null); // เคลียร์ค่าชั่วคราว
                                                                                        }
                                                                                    }}
                                                                                    slotProps={{ 
                                                                                        textField: { 
                                                                                            size: 'small',
                                                                                            sx: { 
                                                                                                width: '100%',
                                                                                            },
                                                                                        } 
                                                                                    }}
                                                                                    minDate={dayjs()}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Grid>
                                                                    </>
                                                                ) : null
                                                            }
                                                        </>
                                                    ) : null
                                                }
                                                <Grid xs={12} sm={2} md={2} lg={2} xl={2} className="box-res-buttom">
                                                    <Button 
                                                        onClick={(e)=>{
                                                            handleAssign(e)
                                                        }}
                                                        sx={{
                                                            backgroundColor: '#3E8E7E',
                                                            '&:hover': {backgroundColor: '#125B50'},
                                                            padding: "8px 0 8px 0",
                                                            width: '100%',
                                                        }}
                                                        variant="contained"
                                                    >
                                                        {t(`Save`)}
                                                    </Button>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            :
                                <>
                                    {
                                        check_role && 
                                        <>
                                            <Grid container>
                                                <Grid 
                                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                                    sx={{
                                                        display: 'flex',   // ใช้ flexbox เพื่อให้อยู่ในบรรทัดเดียวกัน
                                                        marginBottom: "2rem",
                
                                                    }}
                    
                                                >
                                                    <Grid 
                                                        container
                                                        sx={{
                                                            display: 'flex',   // ใช้ flexbox เพื่อให้อยู่ในบรรทัดเดียวกัน
                                                            alignItems: 'flex-end',  // จัดให้ทั้งสองอยู่ในแนวเดียวกัน
                                                        }}
                                                    >
                                                            {
                                                                reqestList.length > 0 && !disable_h && reqestList[0].status != 9 ? (
                                                                    <>
                                                                        {
                                                                            reqestList.length > 0 && (
                                                                                Number(reqestList[0].status) != 2 && Number(reqestList[0].status) != 8
                                                                            ) ? (
                                                                                <>
                                                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} className="box-res">
                                                                                        <Typography 
                                                                                            sx={{
                                                                                                fontSize: "16px",
                                                                                            }}
                                                                                        >
                                                                                            {t(`Status`)}
                                                                                        </Typography>
                                                                                        <Autocomplete
                                                                                            fullWidth
                                                                                            value={dataDefAuto}
                                                                                            options={status_list}
                                                                                            getOptionLabel={(options) => options.status_name}
                                                                                            filterSelectedOptions
                                                                                            disableClearable={true}
                                                                                            size="small"
                                                                                            disabled={disable_s9}
                                                                                            onChange={(event, value: any) => {
                                                                                                setStatusSelect(value?.status_id);
                                                                                                setDataDefAuto(value);
                                                                                            }} 
                                                                                            disablePortal
                                                                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} className="box-res">
                                                                                        <Typography 
                                                                                            sx={{
                                                                                                fontSize: "16px",
                                                                                            }}
                                                                                        >
                                                                                            {t(`Due Date`)}
                                                                                        </Typography>
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                                                                            <MobileDatePicker
                                                                                                value={tempDate || requestDate} // แสดงค่าชั่วคราวหรือค่าปัจจุบัน
                                                                                                onChange={(newValue) => {
                                                                                                    setTempDate(newValue); // อัปเดตค่าชั่วคราวเมื่อเลือก
                                                                                                }}
                                                                                                onClose={() => {
                                                                                                    if (tempDate) {
                                                                                                        setRequestDate(tempDate); // ยืนยันค่าที่เลือก
                                                                                                        // handleUpdateDuedate(tempDate); // เรียกฟังก์ชันเมื่อกด OK
                                                                                                        setTempDate(null); // เคลียร์ค่าชั่วคราว
                                                                                                    }
                                                                                                }}
                                                                                                slotProps={{ 
                                                                                                    textField: { 
                                                                                                        size: 'small',
                                                                                                        sx: { 
                                                                                                            width: '100%',
                                                                                                        },
                                                                                                    } 
                                                                                                }}
                                                                                                minDate={dayjs()}
                                                                                            />
                                                                                        </LocalizationProvider>
                                                                                    </Grid>
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                ) : null
                                                            }
                                                             {
                                                                !disable_h 
                                                                && Number(reqestList[0].status) != 9 ?(
                                                                <>
                                                                    <Grid xs={12} sm={2} md={2} lg={2} xl={2} className="box-res-buttom">
                                                                        <Button 
                                                                            onClick={(e)=>{
                                                                                handleAssign(e)
                                                                            }}
                                                                            sx={{
                                                                                backgroundColor: '#3E8E7E',
                                                                                '&:hover': {backgroundColor: '#125B50'},
                                                                                padding: "8px 0 8px 0",
                                                                                width: '100%',
                                                                            }}
                                                                            variant="contained"
                                                                        >
                                                                            {t(`Save`)}
                                                                        </Button>
                                                                        
                                                                    </Grid>
                                                                    </>
                                                                ): ""}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                    }

                                </>
                            }
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <Accordion sx={{padding: "0 15px 0 15px"}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                id="panel1a-header"
                                                sx={{
                                                }}
                                            >
                                                    <Typography 
                                                        sx={{
                                                            color:'#252525',
                                                            fontSize: "18px",
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                        {t(`Request Information`)}
                                                    </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{marginTop: "-15px"}}>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Business Name`)}</Typography>
                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.company_name && reqestList[0]?.company_name !== "" ? reqestList[0]?.company_name : "-"}
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Requested Person`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.contact_name && reqestList[0]?.contact_name !== "" ? reqestList[0]?.contact_name : "-"}
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Requested Date`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.create_as && reqestList[0]?.create_as !== "" ? reqestList[0]?.create_as : "-"}
                                                    </Grid>
                                                </Grid>
                                                {
                                                    (req_mode == "H" || reqestList[0].status == 9)  &&(
                                                        <Grid container sx={{marginBottom: "10px"}}>
                                                            <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                                <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Due Date`)}</Typography>

                                                            </Grid>
                                                            <Grid 
                                                                xs={12} sm={7} md={7} lg={7} xl={7} 
                                                                sx={{
                                                                    textAlign: "right",
                                                                    fontSize: "14px",
                                                                }}
                                                            >
                                                                {reqestList[0]?.duedate && reqestList[0]?.duedate !== "" ? reqestList[0]?.duedate : "-"}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Request Type`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.request_name_show && reqestList[0]?.request_name_show !== "" ? reqestList[0]?.request_name_show : "-"}
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Sub Request Type`)}</Typography>

                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {
                                                            reqestList.length > 0 &&
                                                            reqestList.map((item, index) => (
                                                                index < (reqestList.length-1) ?(
                                                                    <>
                                                                    
                                                                        {item.request_sub_show} ,<br />
                                                                    </>
                                                                ):(
                                                                    <>
                                                                        {item.request_sub_show}
                                                                    </>
                                                                )
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Additional Information`)}</Typography>
                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.remark && reqestList[0]?.remark !== "" ? reqestList[0]?.remark : "-"}
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Ticket Owner`)}</Typography>
                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.admin_name && reqestList[0]?.admin_name !== "" ? reqestList[0]?.admin_name : "-"}
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Most Recent Update`)}</Typography>
                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.update_as && reqestList[0]?.update_as !== "" ? reqestList[0]?.update_as : "-"}
                                                    </Grid>
                                                </Grid>
                                              
                                                <Grid container sx={{marginBottom: "10px"}}>
                                                    <Grid xs={12} sm={5} md={5} lg={5} xl={5} >
                                                        <Typography sx={{fontWeight: "600",fontSize: "14px",}}>{t(`Status`)}</Typography>
                                                    </Grid>
                                                    <Grid 
                                                        xs={12} sm={7} md={7} lg={7} xl={7} 
                                                        sx={{
                                                            textAlign: "right",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {reqestList[0]?.request_status_show && reqestList[0]?.request_status_show !== "" ? reqestList[0]?.request_status_show : "-"}
                                                    </Grid>
                                                </Grid>
                                              
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                        
                                <br />
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <Accordion sx={{padding: "0 15px 0 15px"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                id="panel1a-header"
                                                sx={{
                                    
                                                }}
                                            >
                                                <Typography 
                                                    sx={{
                                                        color:'#252525',
                                                        fontSize: "18px",
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    {t(`All Files`)}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{marginTop: "-20px",fontSize: "14px"}}>
                                                <Grid 
                                                    container
                                                >
                                                    {
                                                        DataMassageList.length > 0 && 
                                                        DataMassageList.map((item, index) => (
                                                            item.file_name_show.map((data:any, indexf:number) => (
                                                                                        
                                                                <>
                                                                <Grid 
                                                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                                                    sx={{
        
                                                                    }}
                                                                >
                                                                    <Button  
                                                                        size="small"
                                                                        href="#"
                                                                        target="_blank"
                                                                        onClick={() => {
                                                                            let pdf_newTab:any = window.open("","_blank");
                                                                            pdf_newTab.document.write(
                                                                                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                                    `${reqeuestMassageFileUrl}/${item.file_name_show[indexf]}` +
                                                                                "'></iframe></body></html>"
                                                                            );
                                                                            pdf_newTab.document.close();
                                                                        }} 
                                                                    >
                                                                        {item.file_name_show[indexf]}
                                                                    </Button>
                                                                </Grid>
                                                                </>
                                                            ))
                                                        ))
                                                    }
                                                    
                                                </Grid>
                                                <Grid 
                                                    container
                                                >
                                                    {
                                                        DataMassageList.length > 0 && 
                                                        DataMassageList.map((item, index) => (
                                                            item.file_image_show.map((data:any, indexf:number) => (
                                                                                        
                                                                <>
                                                                <Grid 
                                                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                                                    sx={{
               
                                                                    }}
                                                                >
                                                                    <Button  
                                                                        size="small"
                                                                        href="#"
                                                                        target="_blank"
                                                                        sx={{
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleOpenDialogBox(item.file_image_show[indexf]);
                                                                        }}
                                                                    >
                                                                        {item.file_image_show[indexf]}
                                                                    </Button>
                                                                </Grid>
                                                                </>
                                                            ))
                                                        ))
                                                    }
                                                    
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>

                            </Grid>
                            
                            <Grid 
                                xs={12} sm={12} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    
                                    marginBottom: '1rem'
                                }}
                            >

                            </Grid>
                            </>
                            )
                        }
                        {/* data msege */}
                        <Grid 
                            xs={12} sm={12} md={6} lg={6} xl={6} 
                            direction="column" 
                            container item
                            sx={{
                                
                                marginBottom: '1rem'
                            }}
                        >
                            <Grid container>
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    sx={{
                                        marginLeft: isSmallScreen ? '-40px' : null,
                                        textAlign: isSmallScreen ? 'center':'left',
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Typography 
                                        sx={{
                                            fontSize: isSmallScreen ? "18px": null,
                                            fontWeight: isSmallScreen ? "600": null,
                                        }}
                                    >
                                        {t(`Status`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    sx={{
                                        
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Box 
                                        sx={{
                                            width: isSmallScreen ? '100%' : '100%', // ลดความกว้างเมื่อหน้าจอเล็ก
                                            display: 'flex',
                                            justifyContent: isSmallScreen ? 'center' : 'flex-start',
                                            alignItems: isSmallScreen ? 'center' : 'flex-start',
                                            flexDirection: isSmallScreen ? 'column' : 'row', // ปรับทิศทางของ Stepper
                                        }}
                                    >
                                    
                                        <Stepper 
                                            activeStep={activeStep}  
                                            alternativeLabel={!isSmallScreen} // เปลี่ยนเป็นแนวตั้งถ้าอยู่ในจอเล็ก
                                            orientation={isSmallScreen ? 'vertical' : 'horizontal'} 
                                        >
                                    
                                        {
                                        steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            skipped.map((item)=>{
                                                if (Number(item) == index) {
                                                    stepProps.completed = false;
                                                }
                                            })

                                                return (
                                                    <Step key={label} {...stepProps}>
                                                    <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })
                                        }
                                        </Stepper>   
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container>
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    sx={{
                                        
                                        marginBottom: '1rem'
                                    }}
                                >
                                    {
                                        DataMassageList.length > 0 && 
                                        <ScrollContainer ref={scrollContainerRef}>
                                            <Content>

                                           
                                            
                                                <>
                                                {
                                                    DataMassageList.map((item, index) => (
                                                        <>
                                                            
                                                            <Box
                                                                sx={{
                                                
                                                                    width: "100%",
                                                                    height: "auto",
                                                                    border: 1,
                                                                    borderColor: 'grey.500',
                                                                    borderRadius: 1,
                                                                    padding: "0 20px 10px 20px",
                                                                    marginBottom: "15px",
                                                                    overflow: "visible", 
                                                                    
                                                                }}
                                                            >
                                                                <>
                                                                    <Grid 
                                                                        container 
                                                                        sx={{ 
                                                                            display: "flex", 
                                                                            flexDirection: "row", // จัดเป็นแนวนอน
                                                                            margin: "10px 0 0 0",
                                                                            alignItems: "center", // ให้จัดตำแหน่งเริ่มต้นด้านบน
                                                                            justifyContent: "start", // 
                                                                            height: "auto",
                                                                        }}
                                                                    >
                                                                    
                                                                        <Grid xs={2} sm={1} md={1} lg={1} xl={1} >
                                                                            {item.avatarUrl ? (
                                                                                <img
                                                                                    src={item.avatarUrl} // URL ของภาพโปรไฟล์
                                                                                    style={{
                                                                                        width: '150px',
                                                                                        height: '150px',
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Avatar 
                                                                                    alt="Profile Icon" 
                                                                                    sx={{ width: 35, height: 35}} // สีพื้นหลังไอคอนโปรไฟล์
                                                                                >
                                                                                    <PersonIcon sx={{ fontSize: 30, color: 'white' }} /> {/* ใช้ไอคอนโปรไฟล์ */}
                                                                                </Avatar>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid xs={10} sm={11} md={11} lg={11} xl={11}>
                                                                            <Grid>
                                                                                <Typography 
                                                                                    sx={{
                                                                                        marginLeft: '0.5rem',
                                                                                        whiteSpace: "pre-line"
                                                                                    }}
                                                                                    
                                                                                >
                                                                                    {item.name_show}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid>
                                                                                <Typography 
                                                                                    sx={{
                                                                                        marginLeft: '0.5rem',
                                                                                        marginTop:'-5px',
                                                                                        whiteSpace: "pre-line"
                                                                                    }}
                                                                                    
                                                                                >
                                                                                    {item.description}
                                                                                </Typography>
                                                                            </Grid> 
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid 
                                                                        container 
                                                                        sx={{ 
                                                                            display: "flex", 
                                                                            flexDirection: "row", // จัดเป็นแนวนอน
                                                                            margin: item.file_name_show.length || item.file_image_show.length >0 ? "10px 0 50px 0": "10px 0 0 0",
                                                                            alignItems: "start", // ให้จัดตำแหน่งเริ่มต้นด้านบน
                                                                            justifyContent: "start", // 
                                                                            height: "auto",
                                                                        }}
                                                                    >
                                                                    
                                                                        <Grid xs={2} sm={1} md={1} lg={1} xl={1} />
                                                                        <Grid xs={10} sm={11} md={11} lg={11} xl={11}>
                                                                        {
                                                                           item.file_name_show.length > 0 || 
                                                                           item.file_image_show.length >0 ? (
                                                                            <>
                                                                             <Grid>
                                                                                <Typography 
                                                                                    sx={{
                                                                                        marginLeft: '0.4rem',
                                                                                        whiteSpace: "pre-line",
                                                                                    }}
                                                                                    
                                                                                >
                                                                                    {t(`Attached file:`)}
                                                                                </Typography>
                                                                             </Grid>
                                                                            </>

                                                                           ): (<></>)
                                                                        }
                                                                          
                                                                            {
        
                                                                                item.file_name_show.map((data:any, indexf:number) => (
                                                                                    <Grid sx={{marginBottom: "-3px"}}>
                                                                                        <Button  
                                                                                            size="small"
                                                                                            sx={{
                                                                                                lineHeight: '1.1',
                                                                                                textDecoration: "underline",
                                                                                                marginRight: '0.5rem',
                                                                                                fontSize: "12px",
                                                                                                color: '#CE9461',
                                                                                                '&:hover': {color: '#CC704B'},
                                                                                            }}
                                                                                            href="#"
                                                                                            target="_blank"
                                                                                            onClick={() => {
                                                                                            let pdf_newTab:any = window.open("","_blank");
                                                                                            pdf_newTab.document.write(
                                                                                                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                                                `${reqeuestMassageFileUrl}/${item.file_name_show[indexf]}` +
                                                                                                "'></iframe></body></html>"
                                                                                            );
                                                                                            pdf_newTab.document.close();
                                                                                        }} >
                                                                                            {item.file_name_show[indexf]}
                                                                                        </Button>
                                                                                    </Grid>
                                                                                ))
                                                                            }
                                                                           
                                                                          
                                                                            {
        
                                                                                item.file_image_show.map((data:any, indexf:number) => (
                                                                            
                                                                                    <>
                                                                                    <Grid sx={{marginBottom: "-3px"}}>
                                                                                        <Button  
                                                                                            size="small"
                                                                                            href="#"
                                                                                            target="_blank"
                                                                                            sx={{
                                                                                                lineHeight: '1.1',
                                                                                                textDecoration: "underline",
                                                                                                marginRight: '0.5rem',
                                                                                                fontSize: "12px",
                                                                                                color: '#CE9461',
                                                                                                '&:hover': {color: '#CC704B'},
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                handleOpenDialogBox(item.file_image_show[indexf]);
                                                                                            }}
                                                                                        >
                                                                                            {item.file_image_show[indexf]}
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                            <Grid 
                                                                                sx={{
                                                                                    textAlign: "right",
                                                                                    marginBottom:  item.file_name_show.length || item.file_image_show.length >0 ? "-50px" : "-35px",
                                                                                    marginTop: item.file_name_show.length || item.file_image_show.length >0 ? "10px" : "-20px"
                                                                                }}
                                                                            >
                                                                                <Typography 
                                                                                    sx={{
                                                                                        marginTop: "8px",
                                                                                        marginLeft: '0.5rem',
                                                                                        whiteSpace: "pre-line",
                                                                                        fontSize: "14px",
                                                                                        color: "gray",
                                                                                    }}
                                                                                    
                                                                                >
                                                                                    {item.created_at_date}
                                                                                </Typography>
                                                                            </Grid>
                                                                    
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            </Box>
                                                        </>
                                                    ))
                                                }
                                                </>
                                           
                                            </Content>
                                        </ScrollContainer>
                                    }
                                    <Dialog open={dialogOpenBox} onClose={handleCloseDialogBox}>
                                        <DialogTitle>File Name: {imageNameShowBox}</DialogTitle>
                                        <DialogContent>
                                            <CardMedia
                                                width="auto"
                                                component="img"
                                                height="40%"
                                                src={`${reqeuestMassageImageUrl}/${imageNameShowBox}`}
                                                                                        />
                                         </DialogContent>
                                    </Dialog>
                                </Grid>
                            </Grid>
                            <Dialog aria-label="button" open={dialogOpen} onClose={handleCloseDialog}>
                                                <DialogTitle>File Name: {imageNameShow}</DialogTitle>
                                                <DialogContent>
                                                    <CardMedia
                                                        width="auto"
                                                        component="img"
                                                        height="40%"
                                                        image={imageFilebase64[indexOpenImage]}
                                                                        />
                                                </DialogContent>
                                            </Dialog>
                            <br />  
                            {
                             reqestList.length > 0 && (
                            <>
                            <form onSubmit={handleadRequestMS}>
                            {
                            !disable_h && 
                            reqestList[0].status != 2 && 
                            reqestList[0].status != 8 && 
                            reqestList[0].status != 9
                            ? 
                            <>
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            marginBottom: '1rem',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "auto",
                                                border: 1,
                                                borderColor: 'grey.500',
                                                borderRadius: 1,
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            <Grid sx={{ paddingLeft: "10px" ,marginBottom: FileName.length > 0 || imageName.length > 0 ? "-10px": "-25px"}}> {/* เพิ่ม paddingRight เพื่อให้เท่ากัน */}
                                                <TextField
                                                    value={Reply}
                                                    onChange={(e) => {     
                                                        setReply(e.target.value);         
                                                    }}
                                                    placeholder="Write a reply..."
                                                    multiline
                                                    variant="outlined"
                                                    fullWidth
                                                    sx={{
                                                        '& .MuiInputBase-input::placeholder': {
                                                            color: '#303030 !important',  // เปลี่ยนสีของ placeholder
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            border: 'none', // ลบขอบออก
                                                            '& fieldset': {
                                                                border: 'none', // ลบขอบของ fieldset
                                                            },
                                                        },
                                                        padding: "8px 0", // เพิ่ม padding ภายใน TextField
                                                    }}
                                                />
                                            </Grid>

                                            {FileName.length > 0 || imageName.length > 0 ? (
                                                <Grid 
                                                    sx={{ 
                                                        padding: "0 20px 0 25px",
                                                        marginBottom: "-7px"
                                                    }}
                                                > 
                                                    <Typography 
                                                        gutterBottom 
                                                        sx={{
                                                            color:'#252525',
                                                            textAlign: "left",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        {t(`Attached file:`)}
                                                    </Typography>
                                                </Grid>
                                            ) : null}

                                            {
                                                FileName.length > 0 && (
                                                    <Grid sx={{ paddingLeft: "20px", paddingRight: "20px" }}> {/* เพิ่ม paddingRight เพื่อให้เท่ากัน */}
                                                        {React.Children.toArray(
                                                            FileName.map((item, index) => (
                                                                <>
                                                                <Grid sx={{marginBottom: "-12px"}}>
                                                                    <Button  
                                                                        size="small"
                                                                        href="#"
                                                                        target="_blank"
                                                                        onClick={() => {
                                                                            let pdf_newTab: any = window.open("", "_blank");
                                                                            pdf_newTab.document.write(
                                                                                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                                Filebase64[index] +
                                                                                "'></iframe></body></html>"
                                                                            );
                                                                            pdf_newTab.document.close();
                                                                        }} 
                                                                    >
                                                                        <Typography 
                                                                            sx={{
                                                                                textAlign: "left",
                                                                                fontSize: "14px",
                                                                                fontWeight: "500",
                                                                                textDecoration: "underline",
                                                                                color: "#CE9461",
                                                                                '&:hover': {color: '#CC704B'},
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Button>
                                                                    <HighlightOffIcon
                                                                        onClick={(e) => {     
                                                                            handleDeleteFile(index);        
                                                                        }}
                                                                        fontSize="small"
                                                                        sx={{
                                                                            color: "red",
                                                                            verticalAlign: "middle",
                                                                            marginTop: "-5px"
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                </>
                                                            ))
                                                        )}
                                                    </Grid>
                                                )}
                                            {
                                                imageName.length > 0 && 
                                                    <Grid sx={{ paddingLeft: "20px", paddingRight: "20px"}}> 
                                                        {
                                                        
                                                            React.Children.toArray(
                                                                imageName.map((item, index) => (

                                                                    // <CardMedia component='img' src={`${imageFilebase64[index]}`} />
                                                                    <>
                                                                        <Grid sx={{marginBottom: "-11px"}}>
                                                                            <Button  
                                                                                size="small"
                                                                                href="#"
                                                                                target="_blank"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    handleOpenDialog(index,item.name);
                                                                                }}
                                                                            >
                                                                                <Typography 
                                                                                    sx={{
                                                                                        textAlign: "left",
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "500",
                                                                                        textDecoration: "underline",
                                                                                        color: "#CE9461",
                                                                                        '&:hover': {color: '#CC704B'},
                                                                                    }}
                                                                                >
                                                                                    {item.name}
                                                                                </Typography>
                                                                            </Button>
                                                                            <HighlightOffIcon
                                                                                    onClick={(e) => {     
                                                                                        handleDeleteImage(index);        
                                                                                    }}
                                                                                    fontSize="small"
                                                                                    sx={{
                                                                                        color: "red",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                ))
                                                            )
                                                        }
                                                    </Grid> 
                                            }

                                            <Grid sx={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: "20px", paddingRight: "5px",marginTop: "-15px",marginBottom: "-10px"}}> {/* เพิ่ม paddingRight เพื่อให้เท่ากัน */}
                                                <>
                                                    <input 
                                                        disabled={disable_s9}
                                                        hidden 
                                                        accept="image/*" 
                                                        id="image-file" 
                                                        multiple={true} 
                                                        type="file"
                                                        onChange={handleFileUploadImage} 
                                                    />
                                                    <label htmlFor="image-file">
                                                        <IconButton 
                                                            disabled={disable_s9}
                                                            aria-label="upload"
                                                            component="span" 
                                                            onClick={e => e.stopPropagation()}
                                                        >
                                                            <AddPhotoAlternateIcon 
                                                                sx={{
                                                                    color: 'gray',  // เปลี่ยนสีไอคอนเป็นสีดำ
                                                                    '&:hover': {color: '#b2b2b2'},
                                                                    fontSize: 30
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </label>
                                                </>
                                                <>
                                                    <input 
                                                        disabled={disable_s9}
                                                        hidden 
                                                        accept=".pdf" 
                                                        id="file" 
                                                        multiple={true} 
                                                        type="file"
                                                        onChange={handleFileUpload} 
                                                    />
                                                    <label htmlFor="file">
                                                        <IconButton 
                                                            disabled={disable_s9}
                                                            aria-label="upload"
                                                            component="span" 
                                                            onClick={e => e.stopPropagation()}
                                                            sx={{
                                                                marginTop: "3px"
                                                            }}
                                                        >
                                                            <AttachFileIcon 
                                                                sx={{
                                                                    color: 'gray',  // เปลี่ยนสีไอคอนเป็นสีดำ
                                                                    '&:hover': {color: '#b2b2b2'},
                                                                    fontSize: 27
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </label>
                                                </>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                </Grid>

                            </>
                            : ""}

                            {
                            reqestList.length > 0 && !disable_h && reqestList[0].status != 9?
                                <>
                                {
                                reqestList.length>0 && (
                                    Number(reqestList[0].status) != 2 &&
                                    Number(reqestList[0].status) != 8
                                ) ?
                                <>
                                <Grid container>
                                    <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        sx={{
                                            textAlign: 'right',
                                            marginBottom: '1rem'
                                        }}
                                    >    
                                        
                                        
                                        <Button 
                                            disabled={disable_s9}
                                            type="submit"
                                            onClick={(e)=>{
                                                handleConfirmUpdate(e)      
                                            }}
                                            
                                            sx={{
                                                width: 90,
                                                backgroundColor: '#BF896B',
                                                '&:hover': {backgroundColor: '#db9c7a'}
                                            }}
                                            variant="contained"
                                        >
                                            {t(`Send`)}
                                        </Button>
                                    </Grid>
                                </Grid>
                                </>
                                :""}
                                </>
                            :""}
                                  
                            </form> 
                            </>
                            )}
                        </Grid>
                    </Grid>
                    {
                    log_list.length > 0 &&
                    <>
                    <Grid 
                        container   justifyContent="center"
                        xs={12}
                    >
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                borderBottom: '#ABA9A6 solid 2px',
                                marginBottom: "1rem"
                            }}
                        >
                            <Typography 
                                gutterBottom 
                                variant="h5" 
                                component="h2" 
                                sx={{
                                    color:'#4F4A41',
                                    textAlign: "left",
                                    marginTop: 2,
                                }}
                            >
                                {t(`Activity `)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        log_list.map((item, index) => (
                        <>
                            <Grid 
                                container   
                                justifyContent="center"
                                xs={12}
                                sx={{
                                    marginBottom: "10px"
                                }}
                            >
                                <Grid 
                                    xs={12} sm={12} md={12} lg={12} xl={12} 
                                    direction="column" 
                                    container item
                                >   
                                    <Grid 
                                        container  
                                        xs={12}
                                    >
                                        <Grid 
                                            xs={12} sm={1} md={1} lg={1} xl={1} 
                                            direction="column" 
                                            container item

                                        ></Grid>
                                        <Grid 
                                            xs={12} sm={2} md={2} lg={2} xl={2} 
                                            direction="column" 
                                            container item

                                        >
                                            <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                <b>{item.date_show}</b> 
                                            </Typography>
                                        </Grid>
                                        <Grid 
                                            xs={12} sm={3} md={3} lg={3} xl={3} 
                                            direction="column" 
                                            container item

                                        >
                                            <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                <b>{item.name_by}</b> 
                                            </Typography>
                                        </Grid>
                                        <Grid 
                                            xs={12} sm={6} md={6} lg={6} xl={6} 
                                            direction="column" 
                                            container item

                                        >
                                            {
                                                item.type == 'data' &&
                                                <>
                                                    <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                        {item.text} <b>{item.status}</b><b>{item.name_to}</b>
                                                    </Typography> 
                                                </>  
                                            }   
                                            {
                                                item.type == 'file_name' &&
                                                <>
                                                    <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                        {item.text} 
                                                        <Button  
                                                            size="small"
                                                            href="#"
                                                            target="_blank"
                                                            onClick={() => {
                                                                let pdf_newTab:any = window.open("","_blank");
                                                                pdf_newTab.document.write(
                                                                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                    `${reqeuestMassageFileUrl}/${item.file}` +
                                                                "'></iframe></body></html>"
                                                                );
                                                                pdf_newTab.document.close();
                                                            }} 
                                                        >
                                                            {item.file}
                                                        </Button>
                                                        {item.text2}
                                                    </Typography> 
                                                </>  
                                            }   
                                            {
                                                item.type == 'image_name' &&
                                                <>
                                                    <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                        {item.text} 
                                                        <Button  
                                                            size="small"
                                                            href="#"
                                                            target="_blank"
                                                            sx={{}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleOpenDialogBox(item.file);
                                                            }}
                                                        >
                                                            {item.file}
                                                        </Button>
                                                        {item.text2}
                                                    </Typography> 
                                                </>  
                                            }   
                                        </Grid>
                                        <Grid 
                                            xs={12} sm={1} md={1} lg={1} xl={1} 
                                            direction="column" 
                                            container item
                                        > </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        ))
                    }
                    </>
                    }
                    <Grid 
                        container   justifyContent="center"
                        xs={12}
                        sx={{
                            borderTop: '#ABA9A6 solid 2px',
                            marginTop: "1rem"

                        }}
                    >
{/* 
                        <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#CE9461',
                                '&:hover': {backgroundColor: '#CC704B'},
                                marginTop: "1rem"
                            }}
                            variant="contained"
                            onClick={() => {
                                if(req_mode == 'H'){
                                    navigate("/tracking-history")
                                }else{
                                    navigate("/my-request-tracking")
                                }
                            }}
                        >
                            {t(`Back`)}
                        </Button> */}
                        
                    </Grid>
                    <br />
                </CardContent>
            </Card>
        </Grid>
    </Container>                       
  );
}