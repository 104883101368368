// import { history } from "../index";
import {
    REQUEST_LOG_DASHBOARD_FETCHING,
    REQUEST_LOG_DASHBOARD_SUCCESS,
    REQUEST_LOG_DASHBOARD_FAILED,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";

const Swal = require('sweetalert2')

export const setRequestLogFetchingToState = () => ({
    type: REQUEST_LOG_DASHBOARD_FETCHING,
});
export const setRequestLogSuccessToState = (
  payload: any[],) => (
      {
          type: REQUEST_LOG_DASHBOARD_SUCCESS,
          payload,
      }
);

export const setRequestLogFailedToState = () => ({
  type: REQUEST_LOG_DASHBOARD_FAILED,
});

export const loadRequestLogDashboard = () => {
  return async(dispatch: any) => {
      dispatch(setRequestLogFetchingToState());
      getRequestLogDashboard(dispatch)
  };
};

const getRequestLogDashboard = async (dispatch: any) => {
  try {
      const result = await httpClient.get<any[]>(`${server.REQUEST_LOG}/get-request-log-dashboard-user`);
      dispatch(setRequestLogSuccessToState(result.data));
  }   catch (error) {
      dispatch(setRequestLogFailedToState());
  }
}




  