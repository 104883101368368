// import { history } from "../index";
import {
    REQUEST_FETCHING,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
    REQUEST_COUNT_DRAFT,
    REQUEST_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Request } from "../types/request.type";
const Swal = require('sweetalert2')

export const setRequestFetchingToState = () => ({
    type: REQUEST_FETCHING,
});
  
export const setRequestSuccessToState = (
    payload: Request[],
    res_skip: any[],
    res_count: number,
    oper_count: number,
    sucess_count: number) => (
        {
            type: REQUEST_SUCCESS,
            payload,
            res_skip,
            res_count,
            oper_count,
            sucess_count,
        }
);
  
export const setRequestFailedToState = () => ({
    type: REQUEST_FAILED,
});

export const setRequestClearToState = () => ({
    type: REQUEST_CLEAR,
});
  
export const loadRequestByKeyword = (keyword: string, filter: string) => {
    return async (dispatch: any) => {
        dispatch(setRequestFetchingToState());
        try {
            let result = await httpClient.get<any>(
                `
                ${server.REQUEST}/keyword/${keyword}/${filter}/${localStorage.getItem(MEMBER_ID)}
                `
            );
            // alert(JSON.stringify(result))
            let res_count = 0;
            let oper_count = 0;
            let sucess_count = 0;
            result.data.forEach((item:any)=>{
                res_count = res_count + 1;
                if(item.status == "7"){
                    sucess_count = sucess_count + 1;
                }
                if(item.status != "7" && item.status != "8"){
                    oper_count = oper_count + 1;
                }
            })
            dispatch(setRequestSuccessToState(
                result.data, 
                [],
                res_count,
                oper_count,
                sucess_count
            ));
        } catch (error) {
            dispatch(setRequestFailedToState());
        }
    };
};

export const loadRequest = () => {
    return async(dispatch: any) => {
        dispatch(setRequestFetchingToState());
        getDataRequest(dispatch)
    };
};

const getDataRequest = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Request[]>(`${server.REQUEST}/${localStorage.getItem(MEMBER_ID)}`);
        let res_count = 0;
        let oper_count = 0;
        let sucess_count = 0;
        result.data.forEach((item)=>{
            if(Number(item.status_show) == 4){
                sucess_count = sucess_count + 1;
            }

            if(Number(item.status_show) == 3){
                oper_count = oper_count + 1;
            } 
        })
        res_count = Number(result.data.length); 
        dispatch(setRequestSuccessToState(
            result.data, 
            [],
            res_count,
            oper_count,
            sucess_count
        ));
    }   catch (error) {
        dispatch(setRequestFailedToState());
    }
}
export const loadRequestTeam = () => {
    return async(dispatch: any) => {
        dispatch(setRequestFetchingToState());
        getDataRequestNameTeam(dispatch)
    };
};
const getDataRequestNameTeam = async (dispatch: any) => {
    try {
        let res_count = 0;
        let oper_count = 0;
        let sucess_count = 0;
        
        const result = await httpClient.get<any[]>(`${server.REQUEST}/tracking-dashboard-team`);

        dispatch(setRequestSuccessToState(
            result.data, 
            [],
            res_count,
            oper_count,
            sucess_count
        ));
        
    }catch (error) {
        dispatch(setRequestFailedToState());
    }
}
export const loadRequestName = () => {
    return async(dispatch: any) => {
        dispatch(setRequestFetchingToState());
        getDataRequestName(dispatch)
    };
};

const getDataRequestName = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Request[]>(`${server.REQUEST}/request-name/${localStorage.getItem(MEMBER_ID)}`);
        let res_count = 0;
        let oper_count = 0;
        let sucess_count = 0;
        result.data.forEach((item)=>{
            if(Number(item.status_show) == 9){
                sucess_count = sucess_count + 1;
            }

            if(Number(item.status_show) == 3){
                oper_count = oper_count + 1;
            } 
        })
        res_count = Number(result.data.length); 
        dispatch(setRequestSuccessToState(
            result.data, 
            [],
            res_count,
            oper_count,
            sucess_count
        ));
    }   catch (error) {
        dispatch(setRequestFailedToState());
    }
}
export const loadRequestById = (req_id:number) => {
    return async(dispatch: any) => {
        // dispatch(setRequestFetchingToState());
        getDataRequestByID(dispatch,req_id)
    };
};

const getDataRequestByID = async (dispatch: any,req_id: number) => {
    try {
        const result = await httpClient.get<Request[]>(`${server.REQUEST}/get-request-list/${req_id}`);
        let res_count = 0;
        let oper_count = 0;
        let sucess_count = 0;
        dispatch(setRequestSuccessToState(
            result.data, 
            [],
            res_count,
            oper_count,
            sucess_count
        ));
    }   catch (error) {
        dispatch(setRequestFailedToState());
    }
}
export const loadRequestByIdDetail = (req_id:number) => {
    return async(dispatch: any) => {
        // dispatch(setRequestFetchingToState());
        getDataRequestByIDDetail(dispatch,req_id)
    };
};
const getDataRequestByIDDetail = async (dispatch: any,req_id: number) => {
    try {
        const result = await httpClient.get<any[]>(`${server.REQUEST}/get-request-list-detail/${req_id}`);
        let res_count = 0;
        let oper_count = 0;
        let sucess_count = 0;
        let status_list:any = [2,8,3,7,6,5,4,9] 
        const result_log = await httpClient.get<any[]>(`${server.REQUEST_LOG}/load-skip-status/${req_id}`);
        // console.log(`result_log:` + JSON.stringify(result_log.data));

        let index_status = 0;
        
        index_status = status_list.indexOf(Number(result.data[0].status));
        
        let res_skip:any = []
        let inx_set = 0;



        for(let i= 1;i< index_status;i++){

            let ckecking = 0
            result_log.data.forEach((item) => {
                
                if(Number(status_list[i]) == Number(item.status)){
                    ckecking = 1;
                }
            })   
            if(ckecking == 0){
                res_skip[inx_set] = i;
                inx_set += 1;
            }
        }

        dispatch(setRequestSuccessToState(
            result.data, 
            res_skip,
            res_count,
            oper_count,
            sucess_count
        ));
    }   catch (error) {
        dispatch(setRequestFailedToState());
    }
}
  