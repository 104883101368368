import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { server, TOKEN, USER_TYPE,USER_ROLL } from "../../../services/Constants";
import "./Dashboard.css";
import Container from '@mui/material/Container';
import * as loginActions from "../../../actions/login.action";
import * as RequestLogDashboardActions from "../../../actions/request_log_dashboard.action";
import { 
  Grid, 
  Alert, 
  Box, 
  Button, 
  Card, 
  CardContent,
   Stack, 
   SxProps, 
   TextField, 
   Theme, 
   Typography,
   useMediaQuery, 
   useTheme,
   Divider,
   Select, MenuItem, FormControl, InputLabel,SelectChangeEvent ,
   IconButton,
} from "@mui/material";
import { Cookie, Search as SearchIcon } from "@mui/icons-material";
import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import * as requestActions from "../../../actions/request.action";
import * as RequestMassageActions from "../../../actions/request_massage.action";
import * as requestTrackingActions from "../../../actions/request.tracking.action";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

export const Dashboard = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
    const RequestMassageStateReducer = useSelector((state: RootReducers) => state.RequestMassageStateReducer);
    const RequestLogDashboardReducer = useSelector((state: RootReducers) => state.RequestLogDashboardStateReducer);
    const requesTrackingReducer = useSelector((state: RootReducers) => state.requesTrackingReducer);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [role_id, setRoleId] = React.useState(0);
    const [user_type, setUserType] = React.useState("");
    const datenow: string = new Intl.DateTimeFormat('en-US', {
      timeZone: 'Asia/Bangkok',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(new Date());
    const [selectedValue, setSelectedValue] = React.useState<string>('0');
    const [selectedOverDue, setSelectedOverDue] = React.useState<string>('1');
    const [count_request, setCountRequest] = React.useState<number>(0);
    const [count_operation, setCountOperation] = React.useState<number>(0);

    const [countInProgress, setCountInProgress] = React.useState<number>(0);
    const [countAllTicket, setCountAllTicket] = React.useState<number>(0);
    const [countPending, setCountPending] = React.useState<number>(0);
    const [countClosed, setCountClosed] = React.useState<number>(0);
    const [countTotalTicket, setCountTotalTicket] = React.useState<number>(0);

    const [dataChartValue, setDataChartValue] = React.useState<number[]>([])
    const [dataTableOverdue, setDataTableOverdue] = React.useState<any[]>([])
    const [dataTableOverdueShow, setDataTableOverdueShow] = React.useState<any[]>([])
    const [dataTableTeam, setDataTableTeam ] = React.useState<any[]>([])
    const [dataTableTeamShow, setDataTableTeamShow] = React.useState<any[]>([])
    const [comparedRequest, setComparedRequest] = React.useState<number>(0)

    const dataChart = {
      labels: ["in Progress", "Pending", "Overdue"],
      datasets: [
        {
          data: dataChartValue,
          backgroundColor: [
            "#b8b9bb",  // สีของแท่งที่ 2
            "#e8e8e8",  // สีของแท่งที่ 3
            "#f4cfb3",  // สีของแท่งที่ 4
          ],
          borderColor: [
            "#b8b9bb",
            "#e8e8e8",
            "#f4cfb3",
          ],
          borderWidth: 1,
          borderRadius: 8,
        },
      ],
    };
    const optionsChart = {
      responsive: true,
      plugins: {
        legend: {
          display: false, // ซ่อน legend (คำอธิบาย dataset)
        },
        title: {
          display: false, // ซ่อน title (หัวข้อกราฟ)
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            display: true, // ซ่อน labels ของแกน X
          },
          grid: {
            display: false, // ซ่อนเส้น grid ของแกน X (ถ้าต้องการ)
          },
        },
        y: {
          beginAtZero: true,  // เริ่มต้นที่ 0
          ticks: {
            display: true,  // แสดง labels ของแกน Y
            stepSize: 1,  // ใช้ stepSize เป็น 1 เพื่อให้มีค่าทีละ 1
            callback: function(tickValue: string | number) {
              // ตรวจสอบว่า tickValue เป็น number หรือไม่
              if (typeof tickValue === 'number') {
                return tickValue.toFixed(0);  // ปรับให้แสดงเป็นจำนวนเต็ม
              }
              return tickValue; 
            }
          },
          grid: {
            display: true,  // ซ่อนเส้น grid ของแกน Y
          },
        },
      },
    };

    const StyledCard = styled(Card)
    `
      transition: border 0.3s;
      &:hover {
          border: 2px solid blue;
      }
      cursor: pointer;
    
      ${(props: { isSelected: boolean }) =>
        props.isSelected &&
        `
        border: 2px solid blue;
      `}

    `;

    React.useEffect(() => {
      if (localStorage.getItem(TOKEN)) {
          const token = JSON.stringify(localStorage.getItem(TOKEN));
            let base64Url = token.split(".")[1];
            let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            let tokenpayload;
            try{
            tokenpayload = decodeURIComponent(atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(""));
          setRoleId(JSON.parse(tokenpayload).role_id)
          setUserType(JSON.parse(tokenpayload).user_type)
      }catch (e) {
        tokenpayload = unescape(atob(base64)
        .split("")
        .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          }).join("")
        
          );
          dispatch(loginActions.logouttologinpage(navigate));
        }
      }
    }, [localStorage.getItem(TOKEN)]);

    React.useEffect(() => {
      if (!localStorage.getItem(TOKEN)) {
        dispatch(loginActions.logouttologinpage(navigate));
      }
      // dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
    }, []);

    React.useEffect(() => {
      if(localStorage.getItem(USER_TYPE) == "Member"){
        dispatch(requestActions.loadRequestName());
        dispatch(RequestLogDashboardActions.loadRequestLogDashboard());
        dispatch(RequestMassageActions.loadRequestMassageDashboard())
      }else if(localStorage.getItem(USER_ROLL) == "Department Head"){
        dispatch(requestActions.loadRequestTeam());
        dispatch(requestTrackingActions.loadRequestTrackingDashBoard(localStorage.getItem(USER_ROLL)));
      }else{
        dispatch(requestActions.loadRequestTeam());
        dispatch(requestTrackingActions.loadRequestTrackingDashBoard(localStorage.getItem(USER_ROLL)));
      }
    }, []);

    React.useEffect(() => {
      if(requesReducer){
        if(localStorage.getItem(USER_TYPE) == "Member"){
          setCountRequest(requesReducer.res_count)
          setCountOperation(requesReducer.oper_count)
        }else if(localStorage.getItem(USER_ROLL) == "Department Head"){
          if(requesReducer.result && requesReducer.result.length > 0){

            let dataList: any[] = [];

            requesReducer.result.forEach((item: any) => {
              if(item.total != 0){
                dataList.push(item)
              }
            })

            console.log("dataList")
            console.log(dataList)
            setDataTableTeam(dataList)
            setDataTableTeamShow(dataList)
          }

        }
      }
    }, [requesReducer]);

    const [DataLog, setDataLog] = React.useState<any[]>([]);
    const [DataLogShow, setDataLogShow] = React.useState<any[]>([]);
    const [DataMasage, setDataMasage] = React.useState<any[]>([]);
    React.useEffect(() => {

      if(RequestLogDashboardReducer.result){
        let  dataList:any[] = [];
        RequestLogDashboardReducer.result.forEach((item) =>{
          dataList.push(item)
        })

        setDataLog(dataList)
        setDataLogShow(dataList)
      }
    }, [RequestLogDashboardReducer.result]);
    
    React.useEffect(() => {
      if (RequestMassageStateReducer.result) {
  
        let dataList: any[] = [];
  
        RequestMassageStateReducer.result.forEach((item: any) => {
          // คำนวณระยะห่างจาก startDate (item.created_at_date)
          const startDate = new Date(item.created_at_date);
          const currentDate = new Date();
          
          const timeDifference = currentDate.getTime() - startDate.getTime();
          
          const days = Math.floor(timeDifference / (1000 * 3600 * 24));
          const hours = Math.floor((timeDifference % (1000 * 3600 * 24)) / (1000 * 3600));
          const minutes = Math.floor((timeDifference % (1000 * 3600)) / (1000 * 60));
          
          // สร้างข้อความสำหรับเก็บไว้ใน item.date_show
          let dateShow = '';

          // เพิ่ม days, hours, minutes ถ้ามีค่ามากกว่า 0
          if (days > 0) dateShow += `${days} Days `;
          if (hours > 0) dateShow += `${hours} Hours `;
          if (minutes > 0) dateShow += `${minutes} Minutes `;
  
          // เพิ่มคำว่า "ago" ถ้ามีค่าครบ
          if (dateShow) {
            dateShow += 'Ago';
          }
  
          // เพิ่ม item และตั้งค่า item.date_show
          dataList.push({
            ...item,
            date_show: dateShow,
          });
        });
        setDataMasage(dataList);
      }
    }, [RequestMassageStateReducer.result]);

    React.useEffect(() => {
      setCountInProgress(0);
      setCountAllTicket(0);
      if(requesTrackingReducer){
        if(localStorage.getItem(USER_ROLL) == "Department Head" && localStorage.getItem(USER_TYPE) == "User"){

          setCountInProgress(requesTrackingReducer.res2)
          if(requesTrackingReducer.result && requesTrackingReducer.result.length > 0){
            setCountAllTicket(requesTrackingReducer.res9)
          }else{
            setCountAllTicket(0)
          }
        }else if(localStorage.getItem(USER_TYPE) == "User"){
          setCountInProgress(requesTrackingReducer.res3)
          if(requesTrackingReducer.result && requesTrackingReducer.result.length > 0){
            setCountAllTicket(requesTrackingReducer.result.length)
          }else{
            setCountAllTicket(0)
          }
        }
      }
    }, [requesTrackingReducer]);
    React.useEffect(() => {
      
      setCountPending(0);
      setCountClosed(0);
      setCountTotalTicket(0);
      setDataChartValue([0, 0, 0]);
      setDataTableOverdue([]);
      setDataTableOverdueShow([]);
      setComparedRequest(0);

      if(requesTrackingReducer.result && requesTrackingReducer.result.length > 0){
        if(localStorage.getItem(USER_TYPE) == "User"){
                  
          const dateNow = dayjs();  // หรือใช้ dayjs(Date.now()) ก็ได้
          const dateCheck = dateNow.format('DD/MM/YYYY');

          const firstDayBefor = dateNow.subtract(1, 'month').startOf('month'); // วันที่ 1 ของเดือนก่อนหน้า
          const lastDayBefor = dateNow.subtract(1, 'month').endOf('month');

          let countPending = 0;
          let countClosed = 0;
          let countTotalTicket = 0;
          let countTotalTicketBefor = 0;

          var dataItemChart = [ 0, 0, 0 ]
          var dataList:any = [];
          requesTrackingReducer.result.forEach((item: any, index:number) => {
            if(Number(item.status_show) == 7){
              countPending = countPending + 1;
              dataItemChart[1] = dataItemChart[1] + 1;
            }else if(Number(item.status_show) == 8){
            }else if(Number(item.status_show) == 3){
              dataItemChart[0] = dataItemChart[0] + 1;
            }else if(Number(item.status_show) == 6){
              dataItemChart[2] = dataItemChart[2] + 1;
            }else if(Number(item.status_show) == 9 && item.update_date == dateCheck){
              countClosed = countClosed + 1;
            }

            let dateCreate = dayjs(item.create_date)

            if(strDate <= dateCreate && dateCreate < endDate){
              countTotalTicket = countTotalTicket + 1;
            }
            if(firstDayBefor <= dateCreate && dateCreate < lastDayBefor){
              countTotalTicketBefor = countTotalTicketBefor + 1;
            }
            
            let requestDate = dayjs(item.request_date_check)
            if(item.request_date_check && requestDate < dateNow){
              var timeDifference = dateNow.diff(requestDate);
              var days = Math.floor(timeDifference / (1000 * 3600 * 24));
              if(days > 0){
                item.dateOver = `${days} days`
                item.dateOverSort = days;
                dataList.push(item)
              }   
            }
          })

          setCountPending(countPending);
          setCountClosed(countClosed);
          setCountTotalTicket(countTotalTicket)
          setDataChartValue(dataItemChart)

          setDataTableOverdue(dataList)
          setDataTableOverdueShow(dataList)
          // console.log("requesTrackingReducer.result")
          // console.log(requesTrackingReducer.result)
          // console.log(dataList)
          if(countTotalTicketBefor < countTotalTicket){
            setComparedRequest(1)
          }else if(countTotalTicketBefor > countTotalTicket){
            setComparedRequest(2)
          }else{
            setComparedRequest(3)
          }
        }
      }
    }, [requesTrackingReducer.result]);

    const handleChange = (event: SelectChangeEvent<string>) => {
      setSelectedValue(event.target.value)
      var statusSearch = 0;
      var dataSearch = [];
      if(Number(event.target.value) == 0){
        dataSearch = DataLog;
      // }else if(Number(event.target.value) == 3){
      //   dataSearch = DataLog.filter(item => item.status === 3 || item.status === 8);
      }else{
        dataSearch = DataLog.filter(item => item.status === Number(event.target.value));
      }
      setDataLogShow(dataSearch)
    };

    const handleChangeOverdue = (event: SelectChangeEvent<string>) => {
      setSelectedOverDue(event.target.value);
      if (Number(event.target.value) == 1) {
        const sortedData = dataTableOverdue.sort((a, b) => {
          const dateA = new Date(a.request_date_check).getTime();
          const dateB = new Date(b.request_date_check).getTime();
          return dateA - dateB; // Ascending: เก่า -> ใหม่
        });
        setDataTableOverdueShow(sortedData);
      } else {
        const sortedData = dataTableOverdue.sort((a, b) => {
          const dateA = new Date(a.request_date_check).getTime();
          const dateB = new Date(b.request_date_check).getTime();
          return dateB - dateA; // Descending: ใหม่ -> เก่า
        });
        setDataTableOverdueShow(sortedData);
      }
    };

    const handleClickLinkAll = () => {
      // เปลี่ยนเส้นทางเมื่อคลิกที่ CardContent
      navigate('/my-request'); // เปลี่ยน '/new-path' เป็นเส้นทางที่ต้องการ
    };
    const handleClickLinkAllTacking = () => {
      // เปลี่ยนเส้นทางเมื่อคลิกที่ CardContent
      navigate('/my-request-tracking'); // เปลี่ยน '/new-path' เป็นเส้นทางที่ต้องการ
    };

    const handleClickLinkProgress = () => {
      // เปลี่ยนเส้นทางเมื่อคลิกที่ CardContent
      navigate('/my-request/1'); // เปลี่ยน '/new-path' เป็นเส้นทางที่ต้องการ
    };
    const handleClickLinkProgressTacking  = () => {
      // เปลี่ยนเส้นทางเมื่อคลิกที่ CardContent
      if(localStorage.getItem(USER_ROLL) == "Department Head"){
        navigate('/my-request-tracking');
      }else{
        navigate('/my-request-tracking/1'); // เปลี่ยน '/new-path' เป็นเส้นทางที่ต้องการ
      }
    };

    const handleClickLinkRequestLog = (requestId: string | number) => {
      if(localStorage.getItem(USER_TYPE) == "User"){
        navigate(`/my-request-tracking-select/S/${requestId}`);
      }else{
        navigate(`/my-request-select/${requestId}`);
      }
    };

    const [strDate,setStrDate] = React.useState<dayjs.Dayjs>(dayjs().startOf('month'));
    const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(dayjs().endOf('month'));

    const handleDateChange = (newValue: any, type: any) => {
      let strLoopDate:any = null;
      let endLoopDate:any = null;
      if (newValue) {
        if (type === 'from') {
          strLoopDate = newValue;
          endLoopDate = endDate;
          setStrDate(newValue);
        } else if (type === 'to') {
          strLoopDate = strDate;
          endLoopDate = newValue;
          setEndDate(newValue);
        }
      } else {
        if (type === 'from') {
          setStrDate(dayjs());
        } else if (type === 'to') {
          setEndDate(dayjs());
        }
      }

      var countTotalTicket = 0;

      requesTrackingReducer.result.forEach((item: any, index:number) => {

        let dateCreate = dayjs(item.create_date)
        if(strLoopDate <= dateCreate && dateCreate < endLoopDate){
          countTotalTicket = countTotalTicket + 1;
        }
        
      })
      setCountTotalTicket(countTotalTicket)
    };


    const [searchText, setSearchText] = React.useState("");
    const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = dataTableTeam.filter((o: any) => {
          return o && o.name != null && searchRegex.test(o.name.toString());
        });

        setDataTableTeamShow(filteredRows)
    };

    return (
        <Container fixed sx={{ mt: 3}} >
          <Box>
            {
              localStorage.getItem(USER_TYPE) == "Member" ?(
                <>
                <Grid 
                    container item
                    justifyContent="start"
                    sx={{
                        margin: "30px auto auto auto",
                        width: '100%',
                        maxWidth: '60rem',
                    }}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{paddingRight: "15px"}}>
                    <Grid>
                      <Typography 
                        sx={{
                          color:'#9b9b9b',
                          textAlign: "right",
                          fontSize: "14px",
                          fontWeight: "500",
                          paddingTop: "5px"
                        }}
                      >
                        {t(`Last Updated: `)} {datenow}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Grid>
                      <Typography 
                        sx={{
                          color:'#000',
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: "600"
                        }}
                      >
                        {t(`Dashboard`)}
                      </Typography>
                    </Grid>
                    <Grid className="sub-content-dashboard" sx={{marginTop: "20px"}}>
                      <Card 
                          sx={{   
                            border: "1px solid #e9e9e9", 
                            boxShadow: "none",
                            borderRadius: "8px",
                            padding: "10px 10px 10px 10px"
                          }}
                      > 
                        <Grid container  item alignItems="center">
                          <Grid 
                            className="sub-ticket-content-left"
                            xs={12} sm={12} md={6} lg={6} xl={6} >
                            <CardContent 
                              onClick={handleClickLinkAll} 
                              className="box-tickets"
                              sx={{   
                                border: "1px solid #e9e9e9", 
                                boxShadow: "none",
                                borderRadius: "8px",
                                backgroundColor: "#BF896B",
                                cursor: "pointer",
                                '&:hover': { // ใช้ &:hover แทน &hover
                                  border: "2px solid black", 
                                },
                              }}>
                                  <Typography 
                                      sx={{
                                        color:'#000',
                                        textAlign: "left",
                                        fontSize: "16px",
                                        fontWeight: "600"
                                      }}
                                    >
                                      {t(`All my tickets`)}
                                  </Typography>
                                  <Typography 
                                      sx={{
                                        color:'#000',
                                        textAlign: "left",
                                        fontSize: "25px",
                                        fontWeight: "600",
                                        marginBottom: "-20px"
                                      }}
                                    >
                                      {t(`${count_request}`)}
                                  </Typography>
                            </CardContent>
                          </Grid>
                          <Grid  xs={12} sm={12} md={6} lg={6} xl={6} className="sub-ticket-content-rigth">
                          <CardContent 
                            onClick={handleClickLinkProgress} 
                              sx={{   
                                border: "1px solid #e9e9e9", 
                                boxShadow: "none",
                                borderRadius: "8px",
                                cursor: "pointer",
                                '&:hover': { // ใช้ &:hover แทน &hover
                                  border: "2px solid black", 
                                },
                              }}>
                                  <Typography 
                                      sx={{
                                        color: "#9b9b9b",
                                        textAlign: "left",
                                        fontSize: "16px",
                                        fontWeight: "600"
                                      }}
                                    >
                                      {t(`In Progress Tickets`)}
                                  </Typography>
                                  <Typography 
                                      sx={{
                                        color:'#000',
                                        textAlign: "left",
                                        fontSize: "25px",
                                        fontWeight: "600",
                                        marginBottom: "-20px"
                                      }}
                                    >
                                      {t(`${count_operation}`)}
                                  </Typography>
                            </CardContent>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid sx={{marginTop: "20px"}}>
                      <Typography 
                        sx={{
                          color:'#000',
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: "600"
                        }}
                      >
                        {t(`Comment & File Update`)}
                      </Typography>
                    </Grid>
                    <Grid 
                      className="sub-content-dashboard" 
                      sx={{
                        marginTop: "10px",
                        maxHeight: "40vh", // กำหนดความสูงสูงสุด
                          overflowY: "hidden", // เริ่มต้นซ่อนไม่ให้มี scroll
                          "&:hover": {
                            overflowY: "auto", // แสดง scrollbar เมื่อ hover
                          },
                          // ตกแต่ง scrollbar
                          '&::-webkit-scrollbar': {
                            width: '8px', // ความกว้างของ scrollbar
                          },
                          '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                            borderRadius: '10px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // สีของ scrollbar thumb
                            borderRadius: '10px',
                            border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                          },
                      }}>
                      {
                        (DataMasage && DataMasage.length > 0) ? (
                          <>
                          {
                            DataMasage.map((item: any, index: number) => (
                              <>
                                <Card 
                                  sx={{   
                                    border: "1px solid #e9e9e9", 
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    padding: "13px 10px 10px 10px",
                                    marginBottom: "10px",
                                    cursor: "pointer",
                                    '&:hover': { // ใช้ &:hover แทน &hover
                                      border: "1px solid black", 
                                    },
                                  }}
                                  onClick={() => handleClickLinkRequestLog(item.request_id)}
                                > 
                                  <Grid container sx={{ padding: "5px 20px 0 20px"}} alignItems="center" >
                                    <Grid  xs={12} sm={5} md={5} lg={5} xl={5} >
                                      <Typography 
                                        sx={{
                                          color:'#000',
                                          textAlign: "left",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          lineHeight: "1.1", 
                                        }}
                                      >
                                        {t(`${item.name}`)}
                                      </Typography>
                                      <Typography 
                                        sx={{
                                          color:'#9b9b9b',
                                          textAlign: "left",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {t(`Tickets ${item.request_no}`)}
                                      </Typography>
                                    </Grid>
                                    <Grid  xs={12} sm={7} md={7} lg={7} xl={7} >
                                      <Typography 
                                        sx={{
                                          color:'#9b9b9b',
                                          textAlign: "left",
                                          fontSize: "14px",
                                          fontWeight: "500"
                                        }}
                                      >
                                        {t(`${item.description_show}`)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid 
                                    container 
                                    sx={{ 
                                      padding: "0 10px 0 10px",
                                      margin: "-5px 0 -7px 0"
                                    }}
                                  >
                                    <Grid  xs={12} sm={12} md={12} lg={12} xl={12} >
                                      <Typography 
                                        sx={{
                                          color:'#9b9b9b',
                                          textAlign: "right",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          marginTop: "3px"
                                        }}
                                      >
                                        {t(`${item.date_show}`)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </>
                            ))
                          }
                      
                          </>
                        ): (
                          <>
                            <Card 
                              sx={{   
                                border: "1px solid #e9e9e9", 
                                boxShadow: "none",
                                borderRadius: "8px",
                                padding: "10px 10px 10px 10px",
                                marginBottom: "10px",
                                cursor: "pointer",
                              }}
                            > 
                                  <Typography 
                                    sx={{
                                      color:'#000',
                                      textAlign: "center",
                                      fontSize: "16px",
                                      fontWeight: "600"
                                    }}
                                  >
                                    {t(`No updates`)}
                                  </Typography>
                            </Card>
                          </>
                        )
                      }
  
                    </Grid>
                  </Grid>
                  <Grid  item xs={12} sm={12} md={5} lg={5} xl={5} className="content-log">
                    <Grid>
                      <Typography
                        sx={{
                          color: '#000',
                          textAlign: 'left',
                          fontSize: '20px',
                          fontWeight: '600',
                          flexGrow: 1, // ทำให้ Typography ขยายเต็มที่
                        }}
                      >
                        {t(`Tickets Status Update`)}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="end" className="content-select">
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          autoWidth 
                          value={selectedValue}
                          onChange={handleChange}
                          sx={{
                            fontSize: '12px',
                            color: "#9b9b9b",
                            border: 'none', // ลบกรอบ
                            boxShadow: 'none', // ลบเงา
                            '.MuiOutlinedInput-notchedOutline': {
                              border: 'none', // ลบกรอบรอบๆ
                            },
                          }}
                        >
                          <MenuItem value="0">All</MenuItem>
                          <MenuItem value="3">In Progress</MenuItem>
                          <MenuItem value="8">Assign</MenuItem>
                          <MenuItem value="7">Needs Action</MenuItem>
                          <MenuItem value="6">Overdue</MenuItem>
                          <MenuItem value="4">Done</MenuItem>
                          <MenuItem value="5">Cancel</MenuItem>
                          <MenuItem value="9">Closed</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    
                      <Card 
                        sx={{   
                          border: "1px solid #e9e9e9", 
                          boxShadow: "none",
                          borderRadius: "8px",
                          padding:  DataLogShow.length == 1 ? "15px 20px 15px 20px" : "15px 20px 15px 20px",
                          
                        }}
                      >
                        <Grid 
                          sx={{
                            padding: "0 10px 0 10px",
                            maxHeight: "56vh", // กำหนดความสูงสูงสุด
                              overflowY: "hidden", // เริ่มต้นซ่อนไม่ให้มี scroll
                              "&:hover": {
                                overflowY: "auto", // แสดง scrollbar เมื่อ hover
                              },
                              // ตกแต่ง scrollbar
                              '&::-webkit-scrollbar': {
                                width: '8px', // ความกว้างของ scrollbar
                              },
                              '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                borderRadius: '10px',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888', // สีของ scrollbar thumb
                                borderRadius: '10px',
                                border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                              },

                          }}
                        >
                        {
                          (DataLogShow && DataLogShow.length > 0) ? (
                            <>
                              {
                                DataLogShow.map((item: any, index: number) => (
                                  <>
                                    <Grid
                                      key={item.id}
                                      sx={{
                                        padding: "0 10px 0 10px",
                                        cursor: "pointer",
                                        '&:hover': {
                                          // เปลี่ยนสีข้อความเมื่อ hover บน Grid
                                          '& .text-primary': {
                                            color: "#BF896B", // เปลี่ยนสีข้อความเป็นสีดำเมื่อ hover
                                          },
                                          '& .text-secondary': {
                                            color: "#BF896B", // เปลี่ยนสีข้อความที่ 2
                                          },
                                        },
                                      }}
                                      onClick={() => handleClickLinkRequestLog(item.request_id)}
                                    >
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${item.name}`)}
                                      </Typography>
                                      <Typography
                                        className="text-secondary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          marginTop: "-5px",
                                          color: '#9b9b9b',
                                          textAlign: 'left',
                                          fontSize: '12px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`has update tickets ${item.request_no} To ${item.status_name}`)}
                                      </Typography>
                                    </Grid>
                                    {
                                      index != (DataLogShow.length-1) && (
                                        <Divider sx={{ borderColor: '#9b9b9b', marginBottom: '10px',marginTop: '10px',border: "1px solid #e9e9e9", }} />
                                      )
                                    }
                                  </>
                                ))
                              }
                            </>
                          ): (
                            <>
                            <Grid
                                sx={{
                                  padding: "0 10px 0 10px",
                                }}
                              >
                                <Typography
                                  className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                  sx={{
                                    color: '#000',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    flexGrow: 1,
                                  }}
                                >
                                  {t(`No updates`)}
                                </Typography>
                              </Grid>
                            </>
                          )
                        }
                        </Grid>
                       
                      </Card>
                    </Grid>
                 


                </Grid>
                </>
              ): (
                <>
                  {
                    localStorage.getItem(USER_ROLL) == "Department Head" ? (
                      <>
                        <Grid 
                          container item
                          justifyContent="start"
                          sx={{
                              margin: "30px auto auto auto",
                              width: '100%',
                              maxWidth: '60rem',
                          }}
                        >
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{}}>
                              <Typography 
                                sx={{
                                  color:'#9b9b9b',
                                  textAlign: "right",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  paddingTop: "5px"
                                }}
                              >
                                {t(`Last Updated: `)} {datenow}
                              </Typography>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{}}>
                            <Grid container alignItems="center">
                              <Grid xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Typography 
                                  sx={{
                                    color:'#000',
                                    textAlign: "left",
                                    fontSize: "20px",
                                    fontWeight: "600"
                                  }}
                                >
                                  {t(`Dashboard`)}
                                </Typography>
                              </Grid>
                              <Grid xs={12} sm={12} md={10} lg={10} xl={10} className="date-select">
                              <Grid container alignItems="center">
                                  <Typography
                                    sx={{
                                      color: '#000',
                                      textAlign: 'left',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      paddingRight: '15px',
                                    }}
                                  >
                                    {t(`Select period: `)}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex', // ใช้ Flexbox
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        border: '1px solid #838383',
                                        boxShadow: 'none',
                                        borderRadius: '3px',
                                        padding: '0 0 0 10px',
                                        width: '140px',
                                        display: 'flex', // ใช้ Flexbox
                                        alignItems: 'center',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          marginTop: '-1px',
                                        }}
                                      >
                                        {t(`from `)}
                                      </Typography>
                                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <MobileDatePicker
                                          value={strDate}
                                          onChange={(newValue) => handleDateChange(newValue, 'from')} // ใช้ฟังก์ชันเดียวกัน
                                          slotProps={{
                                            textField: {
                                              size: 'small',
                                              sx: {
                                                width: '100%',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none !important',
                                                },
                                                '& .MuiInputBase-input': {
                                                  color: '#838383',
                                                  fontSize: '14px',
                                                  textAlign: 'left',
                                                  margin: '-7px -10px -7px -10px',
                                                },
                                              },
                                              inputProps: {
                                                value: dayjs(strDate).format('MMM D, YYYY'),
                                              },
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Card>
                                    <Card
                                      sx={{
                                        border: '1px solid #838383',
                                        boxShadow: 'none',
                                        borderRadius: '3px',
                                        padding: '0 0px 0 10px',
                                        width: '130px',
                                        display: 'flex', // ใช้ Flexbox
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          marginTop: '-1px',
                                        }}
                                      >
                                        {t(`to `)}
                                      </Typography>
                                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <MobileDatePicker
                                          value={endDate}
                                          onChange={(newValue) => handleDateChange(newValue, 'to')} // ใช้ฟังก์ชันเดียวกัน
                                          slotProps={{
                                            textField: {
                                              size: 'small',
                                              sx: {
                                                width: '100%',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none !important',
                                                },
                                                '& .MuiInputBase-input': {
                                                  color: '#838383',
                                                  fontSize: '14px',
                                                  textAlign: 'left',
                                                  margin: '-7px -10px -7px -10px',
                                                },
                                              },
                                              inputProps: {
                                                value: dayjs(endDate).format('MMM D, YYYY'),
                                              },
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Card>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{marginTop: "50px"}}>
                            <Card 
                              className="box-sum"
                              sx={{   
                                border: "1px solid #e3e3e3", 
                                boxShadow: "none",
                                borderRadius: "8px",
                                display: "flex", // ใช้ Flexbox
                                alignItems: "center", 
                              }}
                            > 
                              <Grid container  item alignItems="center">
                                <Grid 
                                  xs={12} sm={12} md={5} lg={6} xl={6} 
                                >
                                    <Card 
                                      sx={{   
                                        border: "1px solid #e3e3e3", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        display: "flex", 
                                        alignItems: "center", 
                                        padding: "5px 20px 5px 20px",
                                        marginRight: isSmallScreen ?  null : '30px',
                                        marginBottom: isSmallScreen ?  "10px" : null
                                      }}
                                    > 
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Total Tickets`)} 
                                      </Typography>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${countTotalTicket}`)} 
                                      </Typography>
                                      <Box 
                                        sx={{ 
                                          display: "flex", 
                                          alignItems: "center", 
                                          justifyContent: "center",
                                          flexGrow: 1 
                                        }}
                                      >
                                        {
                                          comparedRequest == 1 ? (
                                            <ArrowUpwardIcon 
                                              sx={{ 
                                                fontSize: "14px",
                                                color: "red",
                                                marginRight: "4px",
                                              }} 
                                            />
                                          ):(
                                            <>
                                            {
                                              comparedRequest == 2 ? (
                                                <ArrowDownwardIcon 
                                                  sx={{ 
                                                    fontSize: "14px",
                                                    color: "green",
                                                    marginRight: "4px",
                                                  }} 
                                                />
                                              ):(
                                                <>
                                                </>
                                              )
                                            }
                                            </>
                                          )
                                        }

                                        <Typography
                                          className="text-primary"
                                          sx={{
                                            color: '#838383',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                          }}
                                        >
                                          {t(`compared to last month`)} 
                                        </Typography>
                                      </Box>
                                  </Card>
                                </Grid>
                                <Grid 
                                  xs={12} sm={6} md={4} lg={3} xl={3} 
                                >
                                  <Card 
                                      className="box-right"
                                      sx={{   
                                        border: "1px solid #e3e3e3", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        display: "flex", 
                                        alignItems: "center", 
                                        padding: "5px 20px 5px 20px",
                                      }}
                                    > 
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Tickets Closed Today`)} 
                                      </Typography>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${countClosed}`)} 
                                      </Typography>
                                  </Card>
                                </Grid>
                                <Grid 
                                  xs={12} sm={6} md={3} lg={3} xl={3} 
                                >
                                  <Card 
                                      className="box-left"
                                      sx={{   
                                        border: "1px solid #e3e3e3", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        display: "flex", 
                                        alignItems: "center", 
                                        padding: "5px 20px 5px 20px",

                                      }}
                                    > 
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Pending Tickets`)} 
                                      </Typography>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${countPending}`)} 
                                      </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid xs={12} sm={12} md={5} lg={5} xl={5} sx={{marginTop: "60px"}}> 
                            <Grid>
                              <Card 
                                  sx={{   
                                    border: "1px solid #e9e9e9", 
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    padding: "10px 10px 10px 10px"
                                  }}
                              > 
                                <Grid container  item alignItems="center" >
                                  <Grid 
                                    xs={12} sm={12} md={12} lg={6} xl={6} 
                                    sx={{
                                      paddingRight: {
                                        xs: "0",
                                        lg: "5px"
                                      },
                                      paddingBottom: {
                                        xs: "10px",
                                        lg: "0"
                                      }
                                    }}
                                  >
                                    <CardContent 
                                      onClick={handleClickLinkAllTacking } 
                                      className="box-tickets"
                                      sx={{   
                                        border: "1px solid #e9e9e9", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        backgroundColor: "#BF896B",
                                        cursor: "pointer",
                                        '&:hover': { // ใช้ &:hover แทน &hover
                                          border: "2px solid black", 
                                        },
                                      }}>
                                          <Typography 
                                              sx={{
                                                color:'#000',
                                                textAlign: "left",
                                                fontSize: "16px",
                                                fontWeight: "600"
                                              }}
                                            >
                                              {t(`All my tickets`)}
                                          </Typography>
                                          <Typography 
                                              sx={{
                                                color:'#000',
                                                textAlign: "left",
                                                fontSize: "25px",
                                                fontWeight: "600",
                                                marginBottom: "-20px"
                                              }}
                                            >
                                              {t(`${countAllTicket}`)}
                                          </Typography>
                                    </CardContent>
                                  </Grid>
                                  <Grid 
                                    xs={12} sm={12} md={12} lg={6} xl={6} 
                                    sx={{
                                      paddingLeft: {
                                        xs: "0",
                                        lg: "5px"
                                      }
                                    }}
                                  >
                                  <CardContent 
                                    onClick={handleClickLinkProgressTacking} 
                                      sx={{   
                                        border: "1px solid #e9e9e9", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        '&:hover': { // ใช้ &:hover แทน &hover
                                          border: "2px solid black", 
                                        },
                                      }}>
                                          <Typography 
                                              sx={{
                                                color: "#9b9b9b",
                                                textAlign: "left",
                                                fontSize: "16px",
                                                fontWeight: "600"
                                              }}
                                            >
                                              {t(`Assign Tickets`)}
                                          </Typography>
                                          <Typography 
                                              sx={{
                                                color:'#000',
                                                textAlign: "left",
                                                fontSize: "25px",
                                                fontWeight: "600",
                                                marginBottom: "-20px"
                                              }}
                                            >
                                              {t(`${countInProgress}`)}
                                          </Typography>
                                    </CardContent>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                            <Grid sx={{marginTop: "20px"}}>
                              <Card 
                                  sx={{   
                                    border: "1px solid #e9e9e9", 
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    padding: "20px 20px 20px 20px"
                                  }}
                              > 
                                <Grid container  item alignItems="center" >
                                  <Typography 
                                    sx={{
                                      color:'#000',
                                      textAlign: "left",
                                      fontSize: "14px",
                                      fontWeight: "500"
                                    }}
                                  >
                                    {t(`Ticket by Status`)}
                                  </Typography>
                                </Grid>
                                <Grid container  item alignItems="center" sx={{marginTop: "10px"}}>
                                  <Bar data={dataChart} options={optionsChart} />
                                </Grid>
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid xs={12} sm={12} md={7} lg={7} xl={7} sx={{marginTop: "60px"}} className="table-content"> 
                            <Card 
                                sx={{   
                                  border: "1px solid #e9e9e9", 
                                  boxShadow: "none",
                                  borderRadius: "8px",
                                  padding: "10px 10px 20px 20px"
                                }}
                            > 
                              <Grid container  item alignItems="center" >
                                <Grid 
                                  xs={12} sm={12} md={12} lg={6} xl={6} 
                                  sx={{
                                    paddingRight: {
                                      xs: "0",
                                      lg: "5px"
                                    },
                                    paddingBottom: {
                                      xs: "10px",
                                      lg: "0"
                                    }
                                  }}
                                >
                                </Grid>
                                <Grid 
                                  container
                                  alignItems="center"
                                  xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                  <Typography
                                    className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                    sx={{
                                      color: '#000',
                                      textAlign: 'left',
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      flexGrow: 1,
                                    }}
                                  >
                                    {t(`Team Workload Summary`)}
                                  </Typography>
                                </Grid>
                                <Grid 
                                  className="search-text"
                                  container
                                  justifyContent="end"
                                  xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                  <TextField 
                                      placeholder="Search"
                                      variant="outlined"
                                      size="small"
                                      InputProps={{
                                          endAdornment: (<IconButton ><SearchIcon  sx={{fontSize: "16px"}} /></IconButton>)
                                      }}
                                      onChange={(e)=>{
                                          requestSearch(e.target.value)
                                          setSearchText(e.target.value)
                                      }} 
                                      sx={{
                                          "& .MuiInput-underline:before": {
                                              borderBottom: 1,
                                              borderColor: "divider",
                                          },

                                          "& .MuiOutlinedInput-root": {
                                            borderRadius: "20px", // กำหนด borderRadius ที่นี่
                                            height: "25px",
                                            "& input": {
                                              fontSize: "13px", // กำหนดขนาดฟอนต์ที่นี่
                                              marginBottom: "-10px", 
                                              marginTop: "-10px", 
                                            },
                                          },
                                      }}
                                    />
                                </Grid>
                              </Grid>
                              <Grid 
                                container  
                                item 
                                alignItems="start" 
                                sx={{
                                  marginTop: "10px",
                                  overflowX: "auto", // เปิดใช้งาน scroll แนวนอน
                                  whiteSpace: "nowrap" ,
                                    // ตกแต่ง scrollbar
                                    '&::-webkit-scrollbar': {
                                      height: '8px', // ความกว้างของ scrollbar
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                      borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888', // สีของ scrollbar thumb
                                      borderRadius: '10px',
                                      border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                                    },
                                }}
                              >                     
                                <Grid container  item alignItems="start" sx={{marginBottom: "-5px", minWidth: "450px"}}>
                                  <Grid  xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Team Member`)}
                                      </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Total Tickets`)}
                                    </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Closed`)}
                                    </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Pending`)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container  
                                  sx={{
                                    marginBottom: "10px",
                                    minWidth: "450px",
                                    maxHeight: "13vh", // กำหนดความสูงสูงสุด
                                    overflowY: "hidden", // เริ่มต้นซ่อนไม่ให้มี scroll
                                    "&:hover": {
                                      overflowY: "auto", // แสดง scrollbar เมื่อ hover
                                    },
                                    // ตกแต่ง scrollbar
                                    '&::-webkit-scrollbar': {
                                      width: '8px', // ความกว้างของ scrollbar
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                      borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888', // สีของ scrollbar thumb
                                      borderRadius: '10px',
                                      border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                                    },
                                  }}
                                >
                                  {
                                    ( dataTableTeamShow && dataTableTeamShow.length > 0 ) ? (
                                      <>
                                        {
                                          dataTableTeamShow.map((item: any, index: number) => (
                                            <>
                                              <Grid container sx={{marginTop: "10px"}}>
                                                <Grid  
                                                  xs={6} sm={6} md={6} lg={6} xl={6}
                                                >
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.name}`)}
                                                    </Typography>
                                                </Grid>
                                                <Grid  
                                                  xs={2} sm={2} md={2} lg={2} xl={2}
                                                >
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.total}`)}
                                                  </Typography>
                                                </Grid>
                                                <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.closed}`)}
                                                  </Typography>
                                                </Grid>
                                                <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.closed}`)}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </>
                                          ))
                                        }
                                      </>
                                    ): (
                                      <>
                                      <Grid container  item alignItems="start" sx={{marginTop: "10px"}}>
                                            <Typography
                                                className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                sx={{
                                                  color: '#000',
                                                  textAlign: 'center',
                                                  fontSize: '14px',
                                                  fontWeight: '600',
                                                  flexGrow: 1,
                                                }}
                                              >
                                                {t(`No tickets`)}
                                            </Typography>
                                      </Grid>
                                      </>
                                    )
                                  }
                                </Grid>
                              </Grid>
                            </Card>
                            <Card 
                                sx={{   
                                  border: "1px solid #e9e9e9", 
                                  boxShadow: "none",
                                  borderRadius: "8px",
                                  padding: "10px 10px 20px 20px",
                                  marginTop: "10px"
                                }}
                            > 
                              <Grid container  item alignItems="center" >
                                <Grid 
                                  xs={12} sm={12} md={12} lg={6} xl={6} 
                                  sx={{
                                    paddingRight: {
                                      xs: "0",
                                      lg: "5px"
                                    },
                                    paddingBottom: {
                                      xs: "10px",
                                      lg: "0"
                                    }
                                  }}
                                >
                                </Grid>
                                <Grid 
                                  container
                                  alignItems="center"
                                  xs={12} sm={12} md={12} lg={12} xl={12} 
                                 
                                >
                                  <Typography
                                    className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                    sx={{
                                      color: '#000',
                                      textAlign: 'left',
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      flexGrow: 1,
                                    }}
                                  >
                                    {t(`Overdue Tickets`)}
                                  </Typography>
                                  <FormControl>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      autoWidth 
                                      value={selectedOverDue}
                                      onChange={handleChangeOverdue}
                                      sx={{
                                        fontSize: '12px',
                                        color: "#9b9b9b",
                                        border: 'none', // ลบกรอบ
                                        boxShadow: 'none', // ลบเงา
                                        '.MuiOutlinedInput-notchedOutline': {
                                          border: 'none', // ลบกรอบรอบๆ
                                        },
                                      }}
                                    >
                                      <MenuItem value="1">Ascending</MenuItem>
                                      <MenuItem value="2">Descending</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid 
                                container  
                                item 
                                alignItems="start" 
                                sx={{
                                  marginTop: "10px",
                                  overflowX: "auto", // เปิดใช้งาน scroll แนวนอน
                                  whiteSpace: "nowrap" ,
                                    // ตกแต่ง scrollbar
                                    '&::-webkit-scrollbar': {
                                      height: '8px', // ความกว้างของ scrollbar
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                      borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888', // สีของ scrollbar thumb
                                      borderRadius: '10px',
                                      border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                                    },
                                }}
                              >                     
                                <Grid container  item alignItems="start" sx={{marginBottom: "-5px", minWidth: "450px"}}>
                                  <Grid  xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Ticket No.`)}
                                      </Typography>
                                  </Grid>
                                  <Grid  xs={5} sm={5} md={5} lg={5} xl={5}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Ticket Owner`)}
                                    </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Due Date`)}
                                    </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Overdue`)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container  
                                  sx={{
                                    marginBottom: "10px",
                                    minWidth: "450px",
                                    maxHeight: "13vh", // กำหนดความสูงสูงสุด
                                    overflowY: "hidden", // เริ่มต้นซ่อนไม่ให้มี scroll
                                    "&:hover": {
                                      overflowY: "auto", // แสดง scrollbar เมื่อ hover
                                    },
                                    // ตกแต่ง scrollbar
                                    '&::-webkit-scrollbar': {
                                      width: '8px', // ความกว้างของ scrollbar
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                      borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888', // สีของ scrollbar thumb
                                      borderRadius: '10px',
                                      border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                                    },
                                  }}
                                >
                                  {
                                    ( dataTableOverdueShow && dataTableOverdueShow.length > 0 ) ? (
                                      <>
                                        {
                                          dataTableOverdueShow.map((item: any, index: number) => (
                                            <>
                                              <Grid container 
                                                sx={{
                                                  marginTop: "10px",
                                                  cursor: "pointer",
                                                  '&:hover': {
                                                    // เปลี่ยนสีข้อความเมื่อ hover บน Grid
                                                    '& .text-primary': {
                                                      color: "#BF896B", // เปลี่ยนสีข้อความเป็นสีดำเมื่อ hover
                                                    },
                                                  },
                                                }}
                                                onClick={() => handleClickLinkRequestLog(item.id)}
                                              >
                                                <Grid  
                                                  xs={3} sm={3} md={3} lg={3} xl={3}
                                                >
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.request_no}`)}
                                                    </Typography>
                                                </Grid>
                                                <Grid  
                                                  xs={5} sm={5} md={5} lg={5} xl={5}
                                                >
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.name}`)}
                                                  </Typography>
                                                </Grid>
                                                <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.request_date}`)}
                                                  </Typography>
                                                </Grid>
                                                <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: 'red',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.dateOver}`)}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </>
                                          ))
                                        }
                                      </>
                                    ): (
                                      <>
                                      <Grid container  item alignItems="start" sx={{marginTop: "10px"}}>
                                            <Typography
                                                className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                sx={{
                                                  color: '#000',
                                                  textAlign: 'center',
                                                  fontSize: '14px',
                                                  fontWeight: '600',
                                                  flexGrow: 1,
                                                }}
                                              >
                                                {t(`No overdue tickets`)}
                                            </Typography>
                                      </Grid>
                                      </>
                                    )
                                  }
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </>
                    ):(
                      <>
                       <Grid 
                          container item
                          justifyContent="start"
                          sx={{
                              margin: "30px auto auto auto",
                              width: '100%',
                              maxWidth: '60rem',
                          }}
                        >
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{}}>
                              <Typography 
                                sx={{
                                  color:'#9b9b9b',
                                  textAlign: "right",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  paddingTop: "5px"
                                }}
                              >
                                {t(`Last Updated: `)} {datenow}
                              </Typography>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{}}>
                            <Grid container alignItems="center">
                              <Grid xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Typography 
                                  sx={{
                                    color:'#000',
                                    textAlign: "left",
                                    fontSize: "20px",
                                    fontWeight: "600"
                                  }}
                                >
                                  {t(`Dashboard`)}
                                </Typography>
                              </Grid>
                              <Grid xs={12} sm={12} md={10} lg={10} xl={10} className="date-select">
                              <Grid container alignItems="center">
                                  <Typography
                                    sx={{
                                      color: '#000',
                                      textAlign: 'left',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      paddingRight: '15px',
                                    }}
                                  >
                                    {t(`Select period: `)}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex', // ใช้ Flexbox
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        border: '1px solid #838383',
                                        boxShadow: 'none',
                                        borderRadius: '3px',
                                        padding: '0 0 0 10px',
                                        width: '140px',
                                        display: 'flex', // ใช้ Flexbox
                                        alignItems: 'center',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          marginTop: '-1px',
                                        }}
                                      >
                                        {t(`from `)}
                                      </Typography>
                                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <MobileDatePicker
                                          value={strDate}
                                          onChange={(newValue) => handleDateChange(newValue, 'from')} // ใช้ฟังก์ชันเดียวกัน
                                          slotProps={{
                                            textField: {
                                              size: 'small',
                                              sx: {
                                                width: '100%',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none !important',
                                                },
                                                '& .MuiInputBase-input': {
                                                  color: '#838383',
                                                  fontSize: '14px',
                                                  textAlign: 'left',
                                                  margin: '-7px -10px -7px -10px',
                                                },
                                              },
                                              inputProps: {
                                                value: dayjs(strDate).format('MMM D, YYYY'),
                                              },
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Card>
                                    <Card
                                      sx={{
                                        border: '1px solid #838383',
                                        boxShadow: 'none',
                                        borderRadius: '3px',
                                        padding: '0 0px 0 10px',
                                        width: '130px',
                                        display: 'flex', // ใช้ Flexbox
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          marginTop: '-1px',
                                        }}
                                      >
                                        {t(`to `)}
                                      </Typography>
                                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <MobileDatePicker
                                          value={endDate}
                                          onChange={(newValue) => handleDateChange(newValue, 'to')} // ใช้ฟังก์ชันเดียวกัน
                                          slotProps={{
                                            textField: {
                                              size: 'small',
                                              sx: {
                                                width: '100%',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none !important',
                                                },
                                                '& .MuiInputBase-input': {
                                                  color: '#838383',
                                                  fontSize: '14px',
                                                  textAlign: 'left',
                                                  margin: '-7px -10px -7px -10px',
                                                },
                                              },
                                              inputProps: {
                                                value: dayjs(endDate).format('MMM D, YYYY'),
                                              },
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Card>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="date-show" sx={{marginTop: "50px"}}>
                            <Card 
                              className="box-sum"
                              sx={{   
                                border: "1px solid #e3e3e3", 
                                boxShadow: "none",
                                borderRadius: "8px",
                                display: "flex", // ใช้ Flexbox
                                alignItems: "center", 
                              }}
                            > 
                              <Grid container  item alignItems="center">
                                <Grid 
                                  xs={12} sm={12} md={5} lg={6} xl={6} 
                                >
                                    <Card 
                                      sx={{   
                                        border: "1px solid #e3e3e3", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        display: "flex", 
                                        alignItems: "center", 
                                        padding: "5px 20px 5px 20px",
                                        marginRight: isSmallScreen ?  null : '30px',
                                        marginBottom: isSmallScreen ?  "10px" : null
                                      }}
                                    > 
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Total Tickets`)} 
                                      </Typography>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${countTotalTicket}`)} 
                                      </Typography>
                                      
                                      <Box 
                                        sx={{ 
                                          display: "flex", 
                                          alignItems: "center", 
                                          justifyContent: "center",
                                          flexGrow: 1 
                                        }}
                                      >
                                        {
                                          comparedRequest == 1 ? (
                                            <ArrowUpwardIcon 
                                              sx={{ 
                                                fontSize: "14px",
                                                color: "red",
                                                marginRight: "4px",
                                              }} 
                                            />
                                          ):(
                                            <>
                                            {
                                              comparedRequest == 2 ? (
                                                <ArrowDownwardIcon 
                                                  sx={{ 
                                                    fontSize: "14px",
                                                    color: "green",
                                                    marginRight: "4px",
                                                  }} 
                                                />
                                              ):(
                                                <>
                                                </>
                                              )
                                            }
                                            </>
                                          )
                                        }

                                        <Typography
                                          className="text-primary"
                                          sx={{
                                            color: '#838383',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                          }}
                                        >
                                          {t(`compared to last month`)} 
                                        </Typography>
                                      </Box>
                                  </Card>
                                </Grid>
                                <Grid 
                                  xs={12} sm={6} md={4} lg={3} xl={3} 
                                >
                                  <Card 
                                      className="box-right"
                                      sx={{   
                                        border: "1px solid #e3e3e3", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        display: "flex", 
                                        alignItems: "center", 
                                        padding: "5px 20px 5px 20px",
                                      }}
                                    > 
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Tickets Closed Today`)} 
                                      </Typography>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${countClosed}`)} 
                                      </Typography>
                                  </Card>
                                </Grid>
                                <Grid 
                                  xs={12} sm={6} md={3} lg={3} xl={3} 
                                >
                                  <Card 
                                      className="box-left"
                                      sx={{   
                                        border: "1px solid #e3e3e3", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        display: "flex", 
                                        alignItems: "center", 
                                        padding: "5px 20px 5px 20px",

                                      }}
                                    > 
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#838383',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Pending Tickets`)} 
                                      </Typography>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`${countPending}`)} 
                                      </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid xs={12} sm={12} md={5} lg={5} xl={5} sx={{marginTop: "60px"}}> 
                            <Grid>
                              <Card 
                                  sx={{   
                                    border: "1px solid #e9e9e9", 
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    padding: "10px 10px 10px 10px"
                                  }}
                              > 
                                <Grid container  item alignItems="center" >
                                  <Grid 
                                    xs={12} sm={12} md={12} lg={6} xl={6} 
                                    sx={{
                                      paddingRight: {
                                        xs: "0",
                                        lg: "5px"
                                      },
                                      paddingBottom: {
                                        xs: "10px",
                                        lg: "0"
                                      }
                                    }}
                                  >
                                    <CardContent 
                                      onClick={handleClickLinkAllTacking } 
                                      className="box-tickets"
                                      sx={{   
                                        border: "1px solid #e9e9e9", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        backgroundColor: "#BF896B",
                                        cursor: "pointer",
                                        '&:hover': { // ใช้ &:hover แทน &hover
                                          border: "2px solid black", 
                                        },
                                      }}>
                                          <Typography 
                                              sx={{
                                                color:'#000',
                                                textAlign: "left",
                                                fontSize: "16px",
                                                fontWeight: "600"
                                              }}
                                            >
                                              {t(`All my tickets`)}
                                          </Typography>
                                          <Typography 
                                              sx={{
                                                color:'#000',
                                                textAlign: "left",
                                                fontSize: "25px",
                                                fontWeight: "600",
                                                marginBottom: "-20px"
                                              }}
                                            >
                                              {t(`${countAllTicket}`)}
                                          </Typography>
                                    </CardContent>
                                  </Grid>
                                  <Grid 
                                    xs={12} sm={12} md={12} lg={6} xl={6} 
                                    sx={{
                                      paddingLeft: {
                                        xs: "0",
                                        lg: "5px"
                                      }
                                    }}
                                  >
                                  <CardContent 
                                    onClick={handleClickLinkProgressTacking} 
                                      sx={{   
                                        border: "1px solid #e9e9e9", 
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        '&:hover': { // ใช้ &:hover แทน &hover
                                          border: "2px solid black", 
                                        },
                                      }}>
                                          <Typography 
                                              sx={{
                                                color: "#9b9b9b",
                                                textAlign: "left",
                                                fontSize: "16px",
                                                fontWeight: "600"
                                              }}
                                            >
                                              {t(`In Progress Tickets`)}
                                          </Typography>
                                          <Typography 
                                              sx={{
                                                color:'#000',
                                                textAlign: "left",
                                                fontSize: "25px",
                                                fontWeight: "600",
                                                marginBottom: "-20px"
                                              }}
                                            >
                                              {t(`${countInProgress}`)}
                                          </Typography>
                                    </CardContent>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                            <Grid sx={{marginTop: "20px"}}>
                              <Card 
                                  sx={{   
                                    border: "1px solid #e9e9e9", 
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    padding: "20px 20px 20px 20px"
                                  }}
                              > 
                                <Grid container  item alignItems="center" >
                                  <Typography 
                                    sx={{
                                      color:'#000',
                                      textAlign: "left",
                                      fontSize: "14px",
                                      fontWeight: "500"
                                    }}
                                  >
                                    {t(`Ticket by Status`)}
                                  </Typography>
                                </Grid>
                                <Grid container  item alignItems="center" sx={{marginTop: "10px"}}>
                                  <Bar data={dataChart} options={optionsChart} />
                                </Grid>
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid xs={12} sm={12} md={7} lg={7} xl={7} sx={{marginTop: "60px"}} className="table-content"> 
                            <Card 
                                sx={{   
                                  border: "1px solid #e9e9e9", 
                                  boxShadow: "none",
                                  borderRadius: "8px",
                                  padding: "10px 10px 20px 20px"
                                }}
                            > 
                              <Grid container  item alignItems="center" >
                                <Grid 
                                  xs={12} sm={12} md={12} lg={6} xl={6} 
                                  sx={{
                                    paddingRight: {
                                      xs: "0",
                                      lg: "5px"
                                    },
                                    paddingBottom: {
                                      xs: "10px",
                                      lg: "0"
                                    }
                                  }}
                                >
                                </Grid>
                                <Grid 
                                  container
                                  alignItems="center"
                                  xs={12} sm={12} md={12} lg={12} xl={12} 
                                >
                                  <Typography
                                    className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                    sx={{
                                      color: '#000',
                                      textAlign: 'left',
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      flexGrow: 1,
                                    }}
                                  >
                                    {t(`Overdue Tickets`)}
                                  </Typography>
                                  <FormControl>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      autoWidth 
                                      value={selectedOverDue}
                                      onChange={handleChangeOverdue}
                                      sx={{
                                        fontSize: '12px',
                                        color: "#9b9b9b",
                                        border: 'none', // ลบกรอบ
                                        boxShadow: 'none', // ลบเงา
                                        '.MuiOutlinedInput-notchedOutline': {
                                          border: 'none', // ลบกรอบรอบๆ
                                        },
                                      }}
                                    >
                                      <MenuItem value="1">Ascending</MenuItem>
                                      <MenuItem value="2">Descending</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid 
                                container  
                                item 
                                alignItems="start" 
                                sx={{
                                  marginTop: "10px",
                                  overflowX: "auto", // เปิดใช้งาน scroll แนวนอน
                                  whiteSpace: "nowrap" ,
                                    // ตกแต่ง scrollbar
                                    '&::-webkit-scrollbar': {
                                      height: '8px', // ความกว้างของ scrollbar
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                      borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888', // สีของ scrollbar thumb
                                      borderRadius: '10px',
                                      border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                                    },
                                }}
                              >                     
                                <Grid container  item alignItems="start" sx={{marginBottom: "-5px", minWidth: "450px"}}>
                                  <Grid  xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Ticket No.`)}
                                      </Typography>
                                  </Grid>
                                  <Grid  xs={5} sm={5} md={5} lg={5} xl={5}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Ticket Owner`)}
                                    </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                      <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'left',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Due Date`)}
                                    </Typography>
                                  </Grid>
                                  <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Typography
                                        className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                        sx={{
                                          color: '#000',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {t(`Overdue`)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container  
                                  sx={{
                                    marginBottom: "10px",
                                    minWidth: "450px",
                                    maxHeight: "41vh", // กำหนดความสูงสูงสุด
                                    overflowY: "hidden", // เริ่มต้นซ่อนไม่ให้มี scroll
                                    "&:hover": {
                                      overflowY: "auto", // แสดง scrollbar เมื่อ hover
                                    },
                                    // ตกแต่ง scrollbar
                                    '&::-webkit-scrollbar': {
                                      width: '8px', // ความกว้างของ scrollbar
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      backgroundColor: '#f1f1f1', // สีพื้นหลังของ scrollbar track
                                      borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888', // สีของ scrollbar thumb
                                      borderRadius: '10px',
                                      border: '2px solid #f1f1f1', // ขอบของ scrollbar thumb
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      backgroundColor: '#555', // สีของ scrollbar thumb เมื่อ hover
                                    },
                                  }}
                                >
                                  {
                                    ( dataTableOverdueShow && dataTableOverdueShow.length > 0 ) ? (
                                      <>
                                        {
                                          dataTableOverdueShow.map((item: any, index: number) => (
                                            <>
                                              <Grid container 
                                                sx={{
                                                  marginTop: "10px",
                                                  cursor: "pointer",
                                                  '&:hover': {
                                                    // เปลี่ยนสีข้อความเมื่อ hover บน Grid
                                                    '& .text-primary': {
                                                      color: "#BF896B", // เปลี่ยนสีข้อความเป็นสีดำเมื่อ hover
                                                    },
                                                  },
                                                }}
                                                onClick={() => handleClickLinkRequestLog(item.id)}
                                              >
                                                <Grid  
                                                  xs={3} sm={3} md={3} lg={3} xl={3}
                                                >
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.request_no}`)}
                                                    </Typography>
                                                </Grid>
                                                <Grid  
                                                  xs={5} sm={5} md={5} lg={5} xl={5}
                                                >
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.name}`)}
                                                  </Typography>
                                                </Grid>
                                                <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.request_date}`)}
                                                  </Typography>
                                                </Grid>
                                                <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                                                  <Typography
                                                      className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                      sx={{
                                                        color: 'red',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        flexGrow: 1,
                                                      }}
                                                    >
                                                      {t(`${item.dateOver}`)}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </>
                                          ))
                                        }
                                      </>
                                    ): (
                                      <>
                                      <Grid container  item alignItems="start" sx={{marginTop: "10px"}}>
                                            <Typography
                                                className="text-primary"  // เพิ่ม class สำหรับการควบคุมสีเมื่อ hover
                                                sx={{
                                                  color: '#000',
                                                  textAlign: 'center',
                                                  fontSize: '14px',
                                                  fontWeight: '600',
                                                  flexGrow: 1,
                                                }}
                                              >
                                                {t(`No overdue tickets`)}
                                            </Typography>
                                      </Grid>
                                      </>
                                    )
                                  }
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </>
                    )
                  }
                </>
              )
            }
          </Box>
        </Container>
  );
}
export default Dashboard;
