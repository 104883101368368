import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../services/Constants";
import * as requestActions from "../../../actions/request.action";
import * as requestDeleteActions from "../../../actions/request.delete.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import styled from '@emotion/styled';
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate,useMatch } from "react-router-dom";
import { useAppDispatch } from "../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RequestPage.css";
import { Search as SearchIcon, Tune } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import * as loginActions from "../../../actions/login.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Swal from "sweetalert2";

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

export default function RequestPage(): JSX.Element {
    const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const [selectedRequest, setSelectedRequest] = React.useState<any>({});
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [count_request, setCountRequest] = React.useState<number>(0);
    const [count_operation, setCountOperation] = React.useState<number>(0);
    const [count_succes, setCountSucces] = React.useState<number>(0);
    const navigate = useNavigate();
    const match = useMatch("/my-request/:mode");
    const mode_use = match?.params.mode;
    
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
        //   navigate("/login");
          dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    }, []);

    //load data table
    React.useEffect(() => {
        dispatch(requestActions.loadRequestName());

    }, []);
    React.useEffect(() => {
        setCountRequest(requesReducer.res_count)
        setCountOperation(requesReducer.oper_count)
        setCountSucces(requesReducer.sucess_count)

    }, [requesReducer]);

    const requestColumns: GridColDef[] = [
        {
            headerName: `${t('Request No.')}`,
            field: "request_no",
            flex: 1 ,
            minWidth: 170
            
           
        },
        {
            headerName: `${t('Date')}`,
            field: "create_date",
            flex: 1,
            minWidth: 155
        },
        {
            headerName: `${t('Due Date')}`,
            field: "request_date",
            flex: 1,
            minWidth: 156
        },
        {
            headerName: `${t('Request Type')}`,
            field: "request_name_show",
            flex: 2,
            minWidth: 200
        },
        {
            headerName: `${t('Status')}`,
            field: "status_name",
            flex: 1,
            minWidth: 170,
            align: "right",
            headerAlign: "right",
            renderCell: ({ value }) => {
                return (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        {value === "Closed" && (
                            <CheckCircleIcon 
                                fontSize="inherit" 
                                sx={{ 
                                    color: "#BF896B",
                                    marginRight: "3px"
                                }}  
                            />
                            // <CloseIcon 
                            //     style={{ color: "#BF896B", marginRight: 8 }} 
                            // />
                        )}
                      
                        <span
                            style={{
                                color: value === "Closed" ? "#BF896B" : "#969696",
                            }}
                        >
                            {value}
                        </span>
                    </div>
                );
            }
        },
        // }}
        //                     >
        //                         <EditIcon 
        //                         {
        //     headerName: "",
        //     field: ".",
        //     maxWidth: 150,
        //     align: "center",
        //     renderCell: ({ row }: GridRenderCellParams<string>) => (
        //         <Stack direction="row">
        //             {
        //                 row.status_name == "Draft" ||
        //                 row.status_name == "ร่าง" ? 
        //                 <>
        //                     <IconButton
        //                         aria-label="edit"
        //                         size="large"
        //                         onClick={() => {
        //                             navigate("/edit-request/" + row.id);

        //                             fontSize="inherit" 
        //                             sx={{ color: blue[700] }}  
        //                         />
        //                     </IconButton>
        //                     <IconButton
        //                         disabled={row.status == "Close" || row.status == "Cancel" }
        //                         aria-label="delete"
        //                         size="large"
        //                         onClick={() => {
        //                             // console.log(row)
        //                             setSelectedRequest(row);
        //                             Swal.fire({
        //                                 title: `${t('Delete Data')}`,
        //                                 text: `${t('Confirm Delete Data')} ${t('Request No.')} ${row.request_no} ?`,
        //                                 icon: "warning",
        //                                 // showDenyButton: true,
        //                                 showCancelButton: true,
        //                                 confirmButtonText: `${t('Delete')}`,
        //                                 confirmButtonColor: "#3085d6",
        //                                 cancelButtonColor: "#d33",
        //                                 cancelButtonText: `${t('Cancel')}`,
        //                                 // denyButtonText: `ไม่`,
        //                               }).then((result) => {
        //                                 /* Read more about isConfirmed, isDenied below */
        //                                 if (result.isConfirmed) {
        //                                     handleDeleteConfirm(row.id)
        //                                   // Swal.fire("Saved!", "", "info");
        //                                 }
        //                                 // else if (result.isDenied) {
        //                                 //   Swal.fire("Changes are not saved", "", "info");
        //                                 // }
        //                               });
        //                             // setOpenDialog(true);
        //                         }}
        //                     >
        //                         <DeleteIcon 
        //                             fontSize="inherit" 
        //                             sx={{ color: red[500] }}
        //                         />
        //                     </IconButton>
        //                 </>    
        //             :   
        //                 <>
        //                     <Button 
        //                         variant="contained"
        //                         onClick={ () =>{
        //                             navigate("/my-request-select/" + row.id)
        //                         }}
        //                         size="small"
        //                         sx={{
        //                             width: 80,
        //                             backgroundColor: "#73777B",
        //                             '&:hover': {backgroundColor: '#2C394B'},
        //                         }}
        //                     >
        //                             {t(`Select`)}
        //                     </Button>
        //                 </>
        //             }
        //         </Stack>
        //     ),
        // },
    ];
    
    //search
    const [isSelected1, setIsSelected1] = React.useState(false);
    const [isSelected2, setIsSelected2] = React.useState(false);
    const [isSelected3, setIsSelected3] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [searchText1, setSearchText1] = React.useState("");
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [tableDataS, setTableDataS] = React.useState<any[]>([]);

    React.useEffect(() => {
        // alert(i18next.language)
        let  dataList:any[] = [];
        let index = 0;
        requesReducer.result.forEach((item) =>{
            if(Number(item.status) == 1){
                item.status_name = `${t('Draft')}`
            }else if(Number(item.status) == 2){
                item.status_name = `${t(`Submitted`)}`
            }else if(Number(item.status) == 3){
                item.status_name = `${t('In Progress')}`
            }else if(Number(item.status) == 4){
                item.status_name = `${t(`Done`)}`
            }else if(Number(item.status) == 5){
                item.status_name = `${t('Cancel')}`
            }else if(Number(item.status) == 6){
                item.status_name = `${t('Overdue')}`
            }else if(Number(item.status) == 7){
                item.status_name = `${t('Needs Action')}`

            }else if(Number(item.status) == 8){
                item.status_name = `${t('Submitted')}`
            }else if(Number(item.status) == 9){
                item.status_name = `${t('Closed')}`
            }
            if(i18next.language == 'en'){
                item.request_name_show = item.request_type_name_en;
            }else if(i18next.language == 'th'){
                item.request_name_show = item.request_type_name;
            }else{
                return;
            }
            dataList[index] = item;
            index += 1;
        })
        console.log("setTableData")
        console.log(dataList)
        setTableData(dataList)
        setTableDataS(dataList)
    }, [requesReducer.result,i18next.language]);

    React.useEffect(() => {
        if(Number(mode_use) == 1 && tableDataS.length > 0){
            handleCardClick(3)
            let dataSearchList:any = [] ;
            tableDataS.forEach((item)=>{
                if(Number(item.status_show) == Number(3)){
                    dataSearchList.push(item)
                }
            })
            console.log("dataSearchList")
            console.log(dataSearchList)
            setTableData(dataSearchList)
        }
    }, [mode_use,tableDataS]);

    const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = requesReducer.result.filter((o: any) => {
            return o && Object.keys(o).some((k: any) => {
                // Ensure o[k] is not null or undefined before calling toString()
                return o[k] != null && searchRegex.test(o[k].toString());
            });
        });
        setTableData(filteredRows);
    };

    // const cancelSearch = () => {
    //     setSearchText("");
    //     requestSearch(searchText);
    // };
    const handleDeleteConfirm = (request_id:number) => {
        dispatch(requestDeleteActions.deleteRequestList(request_id,navigate,String("N")));
        setOpenDialog(false);
        window.location.reload();
    };
    const handleCardClick = (status:number) => {
        let dataSearchList:any = [] ;
        let check_click = false;
            if(status == 3 || status == 9){
                if(status == 3 ){
                    check_click = isSelected2;
                    if(isSelected2){
                        setIsSelected1(false);
                        setIsSelected2(false);
                        setIsSelected3(false);
                    }else{
                        setIsSelected1(false);
                        setIsSelected2(true);
                        setIsSelected3(false);
                    }
                }
                if(status == 9){
                    check_click = isSelected3;
                    if(isSelected3){
                        setIsSelected1(false);
                        setIsSelected2(false);
                        setIsSelected3(false);
                    }else{
                        setIsSelected1(false);
                        setIsSelected2(false);
                        setIsSelected3(true);
                    }
                }
                if(check_click){
                    dataSearchList = tableDataS;
                }else{
                    tableDataS.forEach((item)=>{
                        if(Number(item.status_show) == Number(status)){
                            dataSearchList.push(item)
                        }
                    })
                }
            }else if(status == 0){
                if(isSelected1){
                    setIsSelected1(false);
                    setIsSelected2(false);
                    setIsSelected3(false);
                }else{
                    setIsSelected1(true);
                    setIsSelected2(false);
                    setIsSelected3(false);
                }
                dataSearchList = tableDataS;
            }


        
        setTableData(dataSearchList)
    };
    const StyledCard = styled(Card)`
        transition: border 0.3s;
        &:hover {
            border: 2px solid black;
        }
        cursor: pointer;
      
        ${(props: { isSelected: boolean }) =>
          props.isSelected &&
          `
          border: 2px solid black;
        `}

        `;
    const showDialog = () => {
        if (selectedRequest === null) {
            return "";
        }

    return (
        <Dialog
            open={openDialog}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">

            <br />
                ยืนยันการลบรายการคำขอ : {selectedRequest.request_no}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">คุณไม่สามารถกู้คืนรายการคำขอที่ถูกลบได้</DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="info">
                ยกเลิก
            </Button>
            <Button onClick={() => handleDeleteConfirm(selectedRequest.id)} color="primary">
                ยืนยัน
            </Button>
            </DialogActions>
        </Dialog>
        );
    };

    return (
        <Container fixed sx={{ mt: 3}} >
        <Box>
            {/* Summary Icons */}
            <Grid 
                container item
                justifyContent="center"
                alignItems="center"
                sx={{
                    margin: "40px auto auto auto",
                    width: '100%',
                    maxWidth: '60rem',
                }}
            >
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    xs={12} sm={12} md={4} lg={3} xl={3}
                    sx={{
                        marginBottom: "20px"
                    }}
                   
                >
                    <StyledCard 
                        id="cardAll"
                        isSelected={isSelected1}
                        onClick={() => handleCardClick(0)}
                        variant="outlined" 
                        sx={{
                            width: { xs: "90%", sm: '100%',md: '230px' },
                            border: '#D8986F solid 2px',
                            borderRadius: 3,
                            color: '#D8986F',
                        }}
                    >
                        <CardContent 
                            sx={{ 
                                backgroundColor: '#D8986F',
                                display: 'flex',
                                justifyContent: 'space-between', 
                                alignItems: 'center', // จัดกลางในแนวตั้ง
                                padding: "5px 30px 5px 20px !important",
                            }}
                        >
                            <Typography 
                                sx={{ 
                                    fontSize: '18px', 
                                    fontWeight: "600",
                                    color: "#000",
                                }}
                            >
                                {t('All Requests')}
                            </Typography>
                            <Typography 

                                sx={{ 
                                    fontSize: '40px', 
                                    fontWeight: 'bold', 
                                    color: '#000', 
                                }}
                            >
                                {count_request}
                            </Typography>
                            
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    xs={12} sm={12} md={4} lg={3} xl={3}
                    sx={{
                        margin: { 
                            xs: "0 0 20px 0 !important",
                            md: "0 0 20px 0 !important",
                            lg: "0 30px 20px 30px !important",
                            xl: "0 40px 20px 40px !important",
                        },

                    }}
                >
                    <StyledCard 
                        variant="outlined"
                        isSelected={isSelected2}
                        id = "cardoper"
                        onClick={
                            () => handleCardClick(3)
                        }
                        sx={{
                            width: { xs: "90%", sm: '100%',md: '230px' },
                            border: '#F4CFB3 solid 2px',
                            borderRadius: 3,
                            color: '#F4CFB3',
                        }}
                    >
                       <CardContent 
                            sx={{ 
                                backgroundColor: '#F4CFB3',
                                display: 'flex',
                                justifyContent: 'space-between', 
                                alignItems: 'center', // จัดกลางในแนวตั้ง
                                padding: "5px 30px 5px 20px !important",
                            }}
                        >
                            <Typography 
                                sx={{ 
                                    fontSize: '18px', 
                                    fontWeight: "600",
                                    color: "#000",
                                }}
                            >
                                {t(`In Progress`)}
                            </Typography>
                            <Typography 

                                sx={{ 
                                    fontSize: '40px', 
                                    fontWeight: 'bold', 
                                    color: '#000', 
                                }}
                            >
                                {count_operation}
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    xs={12} sm={12} md={4} lg={3} xl={3}
                    sx={{
                        marginBottom: "20px"
                    }}
                >
                    <StyledCard 
                        id = "cardDone"
                        variant="outlined"
                        isSelected={isSelected3}
                        onClick={        
                            () => {
                                handleCardClick(9);
                            }
                        }
                        // onMouseOver={
                        //     () => alert("testF")
                        // }
                        // onMouseOut={ 
                        //     () => alert("testF")
                        // }
                        sx={{
                            width: { xs: "90%", sm: '100%',md: '230px' },
                            border: '#E8E8E8 solid 2px',
                            borderRadius: 3,
                            color: '#E8E8E8',
                        }}
                    >
                        <CardContent 

                            sx={{ 
                                backgroundColor: '#E8E8E8',
                                display: 'flex',
                                justifyContent: 'space-between', 
                                alignItems: 'center', // จัดกลางในแนวตั้ง
                                padding: "5px 30px 5px 20px !important",
                            }}
                        >
                            <Typography 
                                sx={{ 
                                    fontSize: '18px', 
                                    fontWeight: "600",
                                    color: "#000",
                                }}
                            >
                                {t(`Closed`)}
                            </Typography>
                            <Typography 

                                sx={{ 
                                    fontSize: '40px', 
                                    fontWeight: 'bold', 
                                    color: '#000', 
                                }}
                            >
                                {count_succes}
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            </Grid>

            {/* <Grid container justifyContent="flex-end">
                <Button 
                    size="large"
                    variant="contained" 
                    sx={{
                        marginTop: 2,
                        marginLeft:2,
                        marginRight: 2,
                        width: 200,
                        backgroundColor: "#254E58"
                    }}
                    className="requestButton"
                    onClick={() => {
                        navigate("/new-request")
                    }}
                >
                    {t(`New Request`)} 
                </Button>
            </Grid> */}


            <Grid 
                container 
                alignItems="center" 
                sx={{
                    margin: "50px 0 30px 0",
                    padding: { xs: "0 5px 0 5px", sm: "0 0 0 0" }
                }}
            >
                {/* TextField */}
                <Grid item xs={0} sm={0} md={0} lg={1} xl={1} />
                <Grid 
                    item 
                    xs={12} 
                    sm={6} 
                    md={6} 
                    lg={5} 
                    xl={5}
                >
                    <TextField 
                        value={searchText}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <IconButton
                                    sx={{
                                        marginLeft: -1.8, 
                                        color: searchText ? "#000" : "inherit",
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            ),
                            endAdornment: (
                                <IconButton 
                                    onClick={() => {
                                        requestSearch("");
                                        setSearchText("");
                                    }}
                                    sx={{
                                        marginRight: -1.8, // ชิดขวามากขึ้น
                                        "&:hover": {
                                            color: "#000", // เปลี่ยนสีเป็นสีดำเข้มเมื่อ hover
                                        },
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ),
                        }}
                        onChange={(e) => {
                            requestSearch(e.target.value);
                            setSearchText(e.target.value);
                        }}
                        fullWidth
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: 3, // ทำขอบมน
                            },
                            "& .MuiInput-underline:before": {
                                borderBottom: 1,
                                borderColor: "divider",
                            },
                        }}
                    />
                </Grid>

                {/* Button */}
                <Grid 
                    item 
                    container 
                    xs={12} 
                    sm={6} 
                    md={6} 
                    lg={5} 
                    xl={5}
                    justifyContent="flex-end"
                    sx={{
                        marginBottom: { xs: 0, sm: 0  },
                    }}
                >
                    <Button 
                        size="large"
                        variant="contained"
                        sx={{
                            marginTop: { xs: 4, sm: 0 }, // เว้นระยะเฉพาะหน้าจอเล็ก
                            width: 150,
                            backgroundColor: "#BF896B",
                            textTransform: "none", 
                            fontWeight: "bold",
                            fontSize: "16px",
                            borderRadius: 3,
                            "&:hover": {
                                backgroundColor: "#9e6a52", // สีพื้นหลังเมื่อ hover (เข้มกว่าเดิม)
                            },
                        }}
                        className=""
                        onClick={() => navigate("/new-request")}
                    >
                        {t("New Request")}
                    </Button>
                </Grid>
                <Grid item xs={0} sm={0} md={0} lg={1} xl={1} />
            </Grid>
            
            <Grid 
                container      
                sx={{
                    padding: { xs: "0 5px 0 5px", sm: "0 0 0 0" }
                }}
            >
                <Grid item xs={0} sm={0} md={0} lg={1} xl={1} />
                <Grid item xs={12}
                    sm={12}
                    md={12}
                    lg={10}
                    xl={10}
                >
                <DataGrid
                    // components={{ Toolbar: CustomToolbar }}
                    sx={{ 
                        backgroundColor: "white",
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#BF896B", // สีพื้นหลังของ header
                            color: "white", // สีของตัวอักษรใน header
                            fontWeight: "600", // ทำให้ตัวอักษรใน header หนา
                            fontSize: "18px", // ขนาดฟอนต์
                            borderBottom: "2px solid #ddd", // กำหนดเส้นขอบใต้ header
                            padding: "0 0px", // กำหนด padding ขอบทั้งสองข้าง
                            borderRadius: "10px 10px 10px 10px  ",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            padding: "0 25px", // เพิ่ม padding ให้กับชื่อคอลัมน์
                            whiteSpace: "nowrap", // ข้อความจะอยู่ในบรรทัดเดียว
                            overflow: "hidden", // ข้อความเกินจะถูกซ่อนไป
                            textOverflow: "ellipsis", // ใช้ "..." แทนข้อความที่เกิน
                        },
                        "& .css-i4bv87-MuiSvgIcon-root": {
                            display: "none", // ซ่อนไอคอนที่แสดงใน header เช่น ไอคอนลูกศร
                        },
                        "& .MuiDataGrid-columnHeader": {
                            display: "flex", // ใช้ flexbox เพื่อให้ระยะห่างเท่ากัน
                            justifyContent: "space-between", // ให้คอลัมน์ใน header จัดระยะห่างเท่ากัน
                        },
                        "& .MuiDataGrid-cell": {
                            padding: "0 35px", // เพิ่ม padding ให้กับข้อมูลในเซลล์
                            color: "#000",
                            fontWeight: "500",
                            fontSize: "17px",
                        },
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#E8E8E8", // สีพื้นหลังเมื่อ hover ที่แถว
                            cursor: "pointer",
                            "& .MuiDataGrid-cell": {
                                color: "#BF896B !important", // เปลี่ยนสีข้อความของเซลล์เมื่อ hover
                            },
                        },
                    }}  
                    rows={tableData}
                    columns={requestColumns.map(column => ({
                        ...column,
                        flex: 1, // ตั้งค่าให้คอลัมน์ใช้พื้นที่เท่ากัน
                    }))}
                    autoHeight
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onRowClick={(params) => {
                        const row = params.row; // ดึงข้อมูลของแถวที่คลิก
                        // ตรวจสอบสถานะของแถว และทำการแสดงปุ่มที่เหมาะสม
                        if (row.status_name === "Draft" || row.status_name === "ร่าง") {
                            // ถ้าสถานะคือ Draft หรือ ร่าง ให้แสดงปุ่ม Edit และ Delete
                            // คุณสามารถนำทางไปยังหน้าที่เกี่ยวข้อง
                            navigate("/edit-request/" + row.id);
                        } else {
                            // ถ้าสถานะอื่นๆ ให้ทำงานตามที่ต้องการ
                            navigate("/my-request-select/" + row.id);
                        }
                    }}
                />

                <br />
                <br />
                {showDialog()}
                </Grid>
                <Grid item xs={0} sm={0} md={0} lg={1} xl={1} />
            </Grid>
        </Box>
    </Container>
  );
}
