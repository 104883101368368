import { Menuconfig } from './../types/menuconfig.type';
import { combineReducers } from "redux";
import registerReducer, { RegisterState } from "./register.reducer";
import loginReducer, { LoginState } from "./login.reducer";
import stockReducer, { StockState } from "./stock.reducer";
import resetReducer, { ResetState } from "./reset.reducer";
import companyListReducer, { CompanyListState } from "./company.list.reducer";
import requesReducer, { RequestState } from "./request.reducer"; 
import requesTrackingReducer, { RequestTrackingState } from "./request.tracking.reducer"; 
import requesListReducer, { RequesListState } from "./request.lis.reducer"; 
import requesTypeListReducer, { RequesTypeListState } from "./requesttype.list.reducer"; 
import requesEditTypeListReducer, { RequesEditTypeListState } from "./requesttypeedit.list.reducer"; 
import requesEditTypeSubListReducer, { RequesEditSubTypeListState } from "./requestsubtypeedit.list.reducer"; 
import requestSubTypeListReducer, { RequestSubTypeListState } from "./requestsubtype.list.reducer"; 
import stockEditReducer, { StockEditState } from "./stock.edit.reducer";
import forgotPassReducer, { ForgotPassState } from "./forgot.pass.reducer";
import provincesReducer, { ProvincesState } from "./provinces.reducer";
import callapiReducer, { CallapiState } from "./callapi.reducer";
import verifyemailReducer, { VerifyEmailState } from "./verifyemail.reducer";
import dataSheetCreateReducer, { DataSheetCreateState } from "./datasheet.create.reducer";
import directorStateReducer, { DirectorState } from "./director.reducer";
import shareholderStateReducer, { ShareHolderState } from "./shareholder.reducer";
import agreementStateReducer, { AgreementState } from "./agreement.reducer"; 
import agreementGroupStateReducer, { AgreementGroupState } from "./agreement_group.reducer"; 
import rolesStateReducer, { RolesState } from "./roles.reducer"; 
import userStateReducer, { UserState } from "./user.reducer"; 
import RequestMassageStateReducer, {MassageState } from "./request_massage.reducer"; 
import RequestAssignStateReducer, {RequestAssignState } from "./request_assign.reducer"; 
import RequestLogStateReducer, {RequestLogState } from "./request_log.reducer"; 
import RequestLogDashboardStateReducer, {RequestLogDashboardState } from "./request_log_dashboard.reducer"; 
import NoticStateReducer, { NoticState } from "./notic.reducer"; 
import generalMenuStateReducer, { GeneralMenuState } from "./generalmenu.reducer"; 
import menuconfigStateReducer, { MenuconfigState } from "./menuconfig.reducer"; 
import DepartmentManagementStateReducer, { DepartmentManagementState } from "./departmentmanagement.reducer";  
import role_config_request_typeStateReducer, { role_config_request_typeState } from "./role_config_request_type.reducer";  
import personal_informationStateReducer, { Personal_informationStat } from "./personal_information.reducer";  
import serviceTypeStateReducer, { ServiceTypeState } from "./service_type.reducer";  
import stripePaymentStateReducer, { StripePaymentState } from "./stripe_payment.reducer";  
import loginlogsStateReducer, { LoginlogsState } from "./loginlogs.reducer";  

export default combineReducers({
  registerReducer,
  loginReducer,
  stockReducer,
  stockEditReducer,
  forgotPassReducer,
  provincesReducer,
  callapiReducer,
  resetReducer,
  verifyemailReducer,
  requesReducer,
  dataSheetCreateReducer,
  companyListReducer,
  requesTypeListReducer,
  requestSubTypeListReducer,
  directorStateReducer,
  shareholderStateReducer,
  requesEditTypeListReducer,
  requesListReducer,
  requesEditTypeSubListReducer,
  requesTrackingReducer,
  agreementGroupStateReducer,
  agreementStateReducer,
  rolesStateReducer,
  userStateReducer,
  generalMenuStateReducer,
  menuconfigStateReducer,
  RequestMassageStateReducer,
  DepartmentManagementStateReducer,
  role_config_request_typeStateReducer,
  RequestAssignStateReducer,
  RequestLogStateReducer,
  RequestLogDashboardStateReducer,
  personal_informationStateReducer,
  serviceTypeStateReducer,
  stripePaymentStateReducer,
  loginlogsStateReducer,
  NoticStateReducer

});

export interface RootReducers {
  registerReducer: RegisterState;
  loginReducer: LoginState;
  stockReducer: StockState;
  stockEditReducer: StockEditState;
  forgotPassReducer: ForgotPassState;
  provincesReducer: ProvincesState;
  callapiReducer: CallapiState;
  resetReducer: ResetState;
  verifyemailReducer: VerifyEmailState;
  requesReducer: RequestState;
  dataSheetCreateReducer: DataSheetCreateState;
  companyListReducer: CompanyListState;
  requesTypeListReducer: RequesTypeListState;
  requestSubTypeListReducer: RequestSubTypeListState;
  directorStateReducer: DirectorState;
  shareholderStateReducer: ShareHolderState;
  requesEditTypeListReducer: RequesEditTypeListState;
  requesListReducer: RequesListState;
  requesEditTypeSubListReducer: RequesEditSubTypeListState;
  requesTrackingReducer: RequestTrackingState;
  agreementGroupStateReducer: AgreementGroupState;
  agreementStateReducer: AgreementState;
  rolesStateReducer: RolesState;
  userStateReducer: UserState;
  RequestMassageStateReducer: MassageState;
  generalMenuStateReducer: GeneralMenuState;
  menuconfigStateReducer: MenuconfigState;
  DepartmentManagementStateReducer:DepartmentManagementState;
  role_config_request_typeStateReducer:role_config_request_typeState;
  RequestAssignStateReducer:RequestAssignState;
  RequestLogStateReducer:RequestLogState;
  RequestLogDashboardStateReducer:RequestLogDashboardState;
  NoticStateReducer:NoticState;
  personal_informationStateReducer:Personal_informationStat;
  serviceTypeStateReducer:ServiceTypeState;
  stripePaymentStateReducer:StripePaymentState;
  loginlogsStateReducer:LoginlogsState;
}
