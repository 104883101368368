import * as React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Badge } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import * as loginActions from "../../../actions/login.action";
import * as servicetypeActions from "../../../actions/service_type.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import * as NoticActions from "../../../actions/notic.actions";
import * as CHECKLOGINActions from "../../../actions/check_log_in.action";
import { useAppDispatch } from "../../..";
import Button, { ButtonProps } from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import smeimage from "../assets/SME.PNG";
import AdbIcon from "@mui/icons-material/Adb";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  OK,
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  server,
  TOKEN,
  LOGOUT,
  MEMBER_ID,
  NOTIC_NUMBER
} from "../../../../src/services/Constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ListItemButton from "@mui/material/ListItemButton";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentCut from "@mui/icons-material/ContentCut";
import { NestedDropdown, NestedMenuItem, IconMenuItem } from "mui-nested-menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GavelIcon from "@mui/icons-material/Gavel";
import Popover from "@mui/material/Popover";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {encode,decode} from "../../layouts/assets/calculator/decode_encode";
import { MdHomeRepairService } from "react-icons/md";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { MdOutlineHistory } from "react-icons/md";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledMenuSub = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

type HeaderProp = {
  open: boolean;
  // onDrawerOpen: () => void;
};
const menulist = [
  { id: 1, name: "Personal Information", icon: "AccountCircleIcon" },
  { id: 2, name: "Change Password", icon: "AccountCircleIcon" },
  { id: 3, name: "Logout", icon: "AccountCircleIcon" },
];
const navItems = ["About Us","Hop On & Hop Off","Contact Us","Startup Quiz","FAQ"];
let navItemsisLogin:any = ["Service"];
// , "Business Information", "My Request", "Request Tracking"

let avatarname = "";
let member_id = "";
let contact_name = "";
let contact_phone = "";
let contact_address = "";
let member_roll = "";
let role_id = 0;
let user_type = "";

let checkconfig_general_menu = false;
let checkconfig_general_users = false;
let checkconfig_general_roles = false;
let checkconfig_general_department = false;
let checkconfig_general_request_type = false;
let checkconfig_general_request_sub_type = false;
let checkconfig_management_service_type = false;
let checkconfig_management_agreementgroup = false;
let checkconfig_management_agreement = false;
let checkmainconfig = false;
let checksubgeneral = false;
let checksubmanagementservice = false;
let checksubmanagementrequest = false;
let checksublogs = false;
let checkconfig_logs_login_logs = false;
let checkconfig_logs_request_logs = false;
let checkconfig_logs_business_information_logs = false;

const SelectLanguage = styled(Select)<ButtonProps>(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#CA965C",
  "&:hover": {
    backgroundColor: "#F2903A",
  },
}));

const ButtonRegister = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#CA965C",
  "&:hover": {
    backgroundColor: "#F2903A",
  },
}));

const ButtonLogin = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#F2903A",
  backgroundColor: "#ffffff",
  borderColor: "#F2903A",
  "&:hover": {
    backgroundColor: "#ffffff",
    color: "#CA963c",
    borderColor: "#CA963c",
  },
}));

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileOpenservice, setMobileOpenservice] = React.useState(false);
  const [openadminmobile, setOpenadminmobile] = React.useState(true);

  const [openMobilegeneral, setopenMobilegeneral] = React.useState(false);
  const [openMobilemanagement, setopenMobilemanagement] = React.useState(false);
  const [openMobilemanagementservice, setopenMobilemanagementservice] = React.useState(false);
  const [openMobilemanagementrequest, setopenMobilemanagementrequest] = React.useState(false);
  const [openMobilegeneralservice, setopenMobilegeneralservice] = React.useState(false);
  const [openMobilelogs, setopenMobilelogs] = React.useState(false);
  // const [openMobilemanagementrequest, setopenMobilemanagementrequest] = React.useState(false);
  let [countcart, setcountcart] = React.useState(0);
  let [countnotic, setcountnotic] = React.useState(0);

  let [service_type_list, setservice_type_list] = React.useState<any>([]);
  let [notic_list, setnotic_list] = React.useState<any>([]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t,i18n } = useTranslation();
  const LoginReducer = useSelector((state: RootReducers) => state.loginReducer);
  const NoticReducer = useSelector((state: RootReducers) => state.NoticStateReducer);
  const servicetypeStateReducer = useSelector(
    (state: RootReducers) => state.serviceTypeStateReducer
  );
  const menuconfigReducer = useSelector(
    (state: RootReducers) => state.menuconfigStateReducer
  );
  const agreementStateReducer = useSelector((state: RootReducers) => state.agreementStateReducer);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUserNotic, setAnchorElNotic] = React.useState<null | HTMLElement>(
    null
  );
  const [state, setState] = React.useState(false);
  const [languages, setlanguages] = React.useState(i18next.language);
  const Alllanguages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "th",
      name: "Thai",
    },
  ];

  React.useEffect(() => {
    if (localStorage.getItem(TOKEN)) {


      const token = JSON.stringify(localStorage.getItem(TOKEN));
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let tokenpayload;
      try {
        tokenpayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        contact_name = JSON.parse(tokenpayload).contact_name;
        user_type = JSON.parse(tokenpayload).user_type;
        
        setcountnotic(0)
        setnotic_list([])

      } catch (e) {
        tokenpayload = unescape(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        dispatch(loginActions.logouttologinpage(navigate));
      }
    }
  }, [localStorage.getItem(TOKEN)]);

  
  React.useEffect(() => {
    if(localStorage.getItem("YourCart")){
  try{
    setcountcart(JSON.parse(decode(localStorage.getItem("YourCart"))).length);
  }catch (error) {
    setcountcart(0)
    localStorage.removeItem("YourCart");
}
      agreementStateReducer.isFetchingAddtoCart = false;
    }
    
  }, [agreementStateReducer.isFetchingAddtoCart,localStorage.getItem("YourCart")]);

  React.useEffect(() => {
    dispatch(NoticActions.loadNoticRequest());
  }, [localStorage.getItem(TOKEN)]);
  
  React.useEffect(() => {
      if(NoticReducer.result.length > 0){

        let  dataList:any[] = [];
        let index = 0;
        NoticReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;

        })
        setnotic_list(dataList)
        setcountnotic(Number(dataList[0].notic_number))
      }

  }, [NoticReducer.result]);


  React.useEffect(() => {
    dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
    dispatch(servicetypeActions.loadSERVICE_TYPE());
  }, []);

  React.useEffect(() => {
    if (servicetypeStateReducer.result) {
        setservice_type_list(servicetypeStateReducer.result);
        setservice_type_list(servicetypeStateReducer.result);
      
    }
  }, [servicetypeStateReducer.result]);

  React.useEffect(() => {
    if(user_type == "Member"){
      navItemsisLogin = ["Dashboard","My Request", "Business Information"];
    }else{
      navItemsisLogin = [];
    }
   
    checkconfig_general_menu = false;
    checkconfig_general_users = false;
    checkconfig_general_roles = false;
    checkconfig_general_department = false;
    checkconfig_general_request_type = false;
    checkconfig_general_request_sub_type = false;
    checkconfig_management_service_type = false;
    checkconfig_management_agreementgroup = false;
    checkconfig_management_agreement = false;
    checkmainconfig = false;
    checksubgeneral = false;
    checksubmanagementservice = false;
    checksubmanagementrequest = false;
    checksublogs = false;
    checkconfig_logs_login_logs = false;
    checkconfig_logs_request_logs = false;
    checkconfig_logs_business_information_logs = false;

    if (menuconfigReducer.result_by_id_for_header == null) {
    } else {
      if (menuconfigReducer.result_by_id_for_header.length > 0) {
        menuconfigReducer.result_by_id_for_header.map((x: any, index: any) => {
          if (x.menu_route == "dashboard") {
            navItemsisLogin.unshift("Dashboard");
          }else if (x.menu_route == "business-information") {
            navItemsisLogin.push("Business Information");
          }else if (x.menu_route == "my-request") {
            navItemsisLogin.push("My Request");
          } else if (x.menu_route == "my-request-tracking") {
            navItemsisLogin.push("Request Tracking");
          } else if (x.menu_route == "tracking-history") {
            navItemsisLogin.push("Tracking History");
          } else if (x.menu_route == "general-menu") {
            checkconfig_general_menu = true;
            checksubgeneral = true;
            checkmainconfig = true;
          } else if (x.menu_route == "general-users") {
            checkconfig_general_users = true;
            checksubgeneral = true;
            checkmainconfig = true;
          } else if (x.menu_route == "general-roles") {
            checkconfig_general_roles = true;
            checksubgeneral = true;
            checkmainconfig = true;
          } else if (x.menu_route == "general-department") {
            checkconfig_general_department = true;
            checksubgeneral = true;
            checkmainconfig = true;
          } else if (x.menu_route == "service-type") {
            checkconfig_management_service_type = true;
            checksubmanagementservice = true;
            checkmainconfig = true;
          } else if (x.menu_route == "service-group-management") {
            checkconfig_management_agreementgroup = true;
            checksubmanagementservice = true;
            checkmainconfig = true;
          } else if (x.menu_route == "service-management") {
            checkconfig_management_agreement = true;
            checksubmanagementservice = true;
            checkmainconfig = true;
          }else if (x.menu_route == "request-type") {
            checkconfig_general_request_type = true;
            checksubmanagementrequest = true;
            checkmainconfig = true;
          }else if (x.menu_route == "request-sub-type") {
            checkconfig_general_request_sub_type = true;
            checksubmanagementrequest = true;
            checkmainconfig = true;
          }else if (x.menu_route == "login-logs") {
            checkconfig_logs_login_logs = true;
            checksublogs = true;
            checkmainconfig = true;
          }else if (x.menu_route == "request-logs") {
            checkconfig_logs_request_logs = true;
            checksublogs = true;
            checkmainconfig = true;
          }else if (x.menu_route == "business-information-logs") {
            checkconfig_logs_business_information_logs = true;
            checksublogs = true;
            checkmainconfig = true;
          }
          
        });
      }
    }
  }, [menuconfigReducer.result_by_id_for_header]);

  const menuItemsData: any = {
    label: "File",
    items: [
      {
        label: "New",
        leftIcon: <KeyboardArrowRightIcon />,
        callback: (event: any, item: any) =>
          console.log("New clicked", event, item),
      },
      {
        label: "Save",
        leftIcon: <KeyboardArrowRightIcon />,
        callback: (event: any, item: any) =>
          console.log("Save clicked", event, item),
      },
      {
        label: "Save As",
        leftIcon: <KeyboardArrowRightIcon />,
        items: [
          {
            label: "Option 1",
            rightIcon: <KeyboardArrowRightIcon />,
            callback: (event: any, item: any) =>
              console.log("Save As > Option 1 clicked", event, item),
          },
          {
            label: "Option 2",
            leftIcon: <KeyboardArrowRightIcon />,
            callback: (event: any, item: any) =>
              console.log("Save As > Option 2 clicked", event, item),
            disabled: true,
          },
        ],
      },
      {
        label: "Export",
        leftIcon: <KeyboardArrowRightIcon />,
        rightIcon: <KeyboardArrowRightIcon />,
        items: [
          {
            label: "File Type 1",
            items: [
              {
                label: "Option 1",
                rightIcon: <KeyboardArrowRightIcon />,
                callback: (event: any, item: any) =>
                  console.log("Export > FT1 > O1 clicked", event, item),
                sx: { color: "#FF0000" },
              },
              {
                label: "Option 2",
                leftIcon: <KeyboardArrowRightIcon />,
                callback: (event: any, item: any) =>
                  console.log("Export > FT1 > O2 clicked", event, item),
              },
            ],
          },
          {
            label: "File Type 2",
            callback: (event: any, item: any) =>
              console.log("Export > FT2 clicked", event, item),
          },
        ],
      },
    ],
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNoticMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotic(event.currentTarget);
    localStorage.setItem(NOTIC_NUMBER,"0");
    setcountnotic(Number(localStorage.getItem(NOTIC_NUMBER)));
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorElservice, setAnchorElservice] =
    React.useState<null | HTMLElement>(null);
  const openservice = Boolean(anchorElservice);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  };
  const handleClickadminbomile = () => {
    setOpenadminmobile(!openadminmobile);
  };

  const handleClose = () => {
    // console.log("out")
    setAnchorEl(null);
  };

  const handleClickservice = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(event.currentTarget)
    setAnchorElservice(event.currentTarget);
  };

  const handleCloseservice = () => {
    // console.log("out")
    setAnchorElservice(null);
  };
  const [anchorElsub, setAnchorElsub] = React.useState<null | HTMLElement>(
    null
  );
  const opensub = Boolean(anchorElsub);
  const handleClicksub = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(event.currentTarget)
    setAnchorElsub(event.currentTarget);
  };
  const handleClosesub = () => {
    // console.log("out")
    setAnchorElsub(null);
    setAnchorEl(null);
  };

  const handleSelectgetmenu = () => {
    // console.log("handleSelectgetmenu")
  };

  const handleChangelanguage = (event: SelectChangeEvent) => {
    if (event.target.value == "th") {
      i18next.changeLanguage("th");
      setlanguages(i18next.language);
    } else if (event.target.value == "en") {
      i18next.changeLanguage("en");
      setlanguages(i18next.language);
    } else {
    }
  };

  const handleCloseNavMenu = (page: any) => {
    // console.log(page);
    if (page === "FAQ") {
      navigate("/faq");
      // } else if (page === "Pricing") {
      //   navigate("/pricing");
    }
    else if (page === "Hop On & Hop Off") {
      navigate("/hopon-hopoff")
    }
    else if (page === "About Us") {
      navigate("/about-us");
      // } else if (page === "Pricing") {
      //   navigate("/pricing");
    } else if (page === "Contact Us") {
      navigate("/contact-us");
    } else if (page === "Startup Quiz") {
      navigate("/quiz");
    } else if (page === "Service") {
      navigate("/service");
    } else {
    }
    // navigate("/register")
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = async(menuname: any) => {
    if (menuname == 3) {
     await dispatch(loginActions.GetLogInOutLog(navigate));
      dispatch(loginActions.logouttologinpage(navigate));
    } else if (menuname == 2) {
      navigate(`/reset-password`);
    } else if (menuname == 1) {
      navigate(`/personal-information`);
    } else {
    }
    setAnchorElUser(null);
  };
  const handleCloseNoticMenu = async() => {
    setAnchorElNotic(null);
    dispatch(CHECKLOGINActions.updateNoticDay());
    
  };

  const clicknavItemsisLogin = (
    navItemsisLoginname: any,
    checksubmenu: any
  ) => {
    if (checksubmenu == "havesubmenu") {
      navigate(`/service/${navItemsisLoginname}`);
    } else {
      if (navItemsisLoginname == "FAQ") {
        navigate(`/faq`);
      }
      else if (navItemsisLoginname === "Hop On & Hop Off") {
        navigate(`/hopon-hopoff`)
      }
      else if (navItemsisLoginname == "Dashboard") {
        navigate(`/dashboard`);
      } else if (navItemsisLoginname === "Service") {
        navigate("/service");
      } else if (navItemsisLoginname == "Business Information") {
        navigate(`/business-information`);
      } else if (navItemsisLoginname == "My Request") {
        navigate(`/my-request`);
      } else if (navItemsisLoginname == "Request Tracking") {
        navigate(`/my-request-tracking`);
      } else if (navItemsisLoginname == "Tracking History") {
        navigate(`/tracking-history`);
      } else {
        navigate(`/${navItemsisLoginname}`);
        handleClose();
      }
    }
    setAnchorElservice(null);
    setAnchorElUser(null);
  };
  // console.log(LoginReducer.result)
  // console.log(LoginReducer.resulttoken)

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {!LoginReducer.result && (
        <Box>
          <Typography variant="h6" sx={{ my: 1 }}>
            <Link href="/home" color="inherit">
              <Box>
                <img src={smeimage} style={{ height: "80px" }} />
              </Box>
            </Link>
          </Typography>
          <Divider />
          <List>
            {navItems.map((item) => (
               <>
               {item == "Service" ?
               <>
                   <ListItem
                     button
                     onClick={() => {
                       setMobileOpen(false);
                       setopenMobilemanagement(false);
                       setopenMobilelogs(false);
                       setopenMobilegeneralservice(!openMobilegeneralservice);
                     }}
                   >
                     <ListItemText primary={`${t("Service")}`} />
                     {openMobilegeneralservice ? (
                       <KeyboardArrowUpIcon />
                     ) : (
                       <KeyboardArrowDownIcon />
                     )}
                   </ListItem>
                   <Collapse
                     in={openMobilegeneralservice}
                     timeout="auto"
                     unmountOnExit
                   >
                     <Divider />
                     <List component="div" disablePadding>
                     <ListItem
                           disablePadding
                           onClick={() =>
                             clicknavItemsisLogin(`all-services`, "")
                           }
                         >
                           <ListItemButton sx={{ textAlign: "left" }}>
                             <ListItemText inset primary={t(`All Services`)} />
                        
                           </ListItemButton>
                         </ListItem>
                     {service_type_list &&
                         service_type_list.map((x: any, i: any) => (
                           <ListItem
                           disablePadding
                           onClick={() =>
                             clicknavItemsisLogin(`${x.id}`, "havesubmenu")
                           }
                         >
                           <ListItemButton sx={{ textAlign: "left" }}>
                           {i18n.language === "en" ?(
                             <ListItemText inset primary={t(`${x.name}`)} />
                           )
                           :
                           (
                           <ListItemText inset primary={t(`${x.name_th}`)} />
                           )
                          }
                           </ListItemButton>
                         </ListItem>
                         ))}
                         
                     </List>
                     </Collapse>
               </>
               :
              <ListItem
                key={item}
                disablePadding
                onClick={() => handleCloseNavMenu(item)}
              >
                <ListItemButton sx={{ textAlign: "left" }}>
                  <ListItemText primary={t(item)} />
                </ListItemButton>
              </ListItem>
                }
                </>
            ))}
          </List>
        </Box>
      )}

      {LoginReducer.result && (
        <Box>
          <Typography variant="h6" sx={{ my: 1 }}>
            {/* dashboard */}
            <Link href="#" color="inherit">
              <Box>
                <img src={smeimage} style={{ height: "80px" }} />
              </Box>
            </Link>
          </Typography>
          <Divider />
          <List component="nav" disablePadding>
            {navItemsisLogin.map((item:any) => (
              <>
                {item == "Service" ?
                <>
                    <ListItem
                      button
                      onClick={() => {
                        setMobileOpen(false);
                        setopenMobilemanagement(false);
                        setopenMobilelogs(false);
                        setopenMobilegeneralservice(!openMobilegeneralservice);
                      }}
                    >
                      <ListItemText primary={`${t("Service")}`} />
                      {openMobilegeneralservice ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </ListItem>
                    <Collapse
                      in={openMobilegeneralservice}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Divider />
                      <List component="div" disablePadding>
                      <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin(`all-services`, "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText inset primary={t(`All Service`)} />
                            </ListItemButton>
                          </ListItem>
                      {service_type_list &&
                          service_type_list.map((x: any, i: any) => (
                            <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin(`${x.id}`, "havesubmenu")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                            {i18n.language === "en" ?(
                              <ListItemText inset primary={t(`${x.name}`)} />
                            )
                              :
                              (
                              <ListItemText inset primary={t(`${x.name_th}`)}
                               />
                              )
                          }
                            </ListItemButton>
                          </ListItem>
                          ))}
                          
                      </List>
                      </Collapse>
                </>

                :
              <ListItem
                button
                key={item}
                disablePadding
                onClick={() => clicknavItemsisLogin(item, "")}
              >
                <ListItemButton sx={{ textAlign: "left" }}>
                  <ListItemText primary={t(item)} />
                </ListItemButton>
              </ListItem>
            }
            </>
            ))}
            {user_type != "Member" ? (
              <div>
                {checksubgeneral && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        setMobileOpen(false);
                        setopenMobilemanagement(false);
                        setopenMobilelogs(false);
                        setopenMobilemanagementservice(false);
                        setopenMobilegeneral(!openMobilegeneral);
                      }}
                    >
                      {/* <ListItemIcon >
          <HomeIcon />
        </ListItemIcon> */}
                      <ListItemText primary={`${t("General")}`}/>
                      {openMobilegeneral ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </ListItem>
                    <Collapse
                      in={openMobilegeneral}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Divider />
                      <List component="div" disablePadding>
                        {checkconfig_general_menu && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("general-menu", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText inset primary={t("Menu")} />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_general_users && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("general-users", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText inset primary={t("Users")} />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_general_roles && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("general-roles", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText inset primary={t("Roles")} />
                            </ListItemButton>
                          </ListItem>
                        )}

                        {checkconfig_general_department && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("general-department", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText inset primary={t("Department")} />
                            </ListItemButton>
                          </ListItem>
                        )}

                      </List>
                    </Collapse>
                  </>
                )}

                {checksubmanagementservice && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        setMobileOpen(false);
                        setopenMobilegeneral(false);
                        setopenMobilelogs(false);
                        setopenMobilemanagementrequest(false);
                        setopenMobilemanagementservice(!openMobilemanagementservice);
                      }}
                    >
                      {/* <ListItemIcon >
          <IconLibraryBooks />
        </ListItemIcon> */}
                      <ListItemText primary={`${t("Service Management")}`}/>
                      {openMobilemanagementservice ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </ListItem>
                    <Collapse
                      in={openMobilemanagementservice}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Divider />
                      <List component="div" disablePadding>
                        {checkconfig_management_service_type && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("service-type", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText inset primary={t("Service Type")} />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_management_agreementgroup && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin(
                                "service-group-management",
                                ""
                              )
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Form Group")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_management_agreement && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("service-management", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Form PDF")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                       
                      </List>
                    </Collapse>
                  </>
                )}


                  {checksubmanagementrequest && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        setMobileOpen(false);
                        setopenMobilegeneral(false);
                        setopenMobilemanagementservice(false);
                        setopenMobilelogs(false);
                        setopenMobilemanagementrequest(!openMobilemanagementrequest);
                      }}
                    >
                      {/* <ListItemIcon >
          <IconLibraryBooks />
        </ListItemIcon> */}
                      <ListItemText primary={`${t("Request Management")}`}/>
                      {openMobilemanagementrequest ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </ListItem>
                    <Collapse
                      in={openMobilemanagementrequest}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Divider />
                      <List component="div" disablePadding>
                      {checkconfig_general_request_type && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("request-type", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Request Type")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_general_request_sub_type && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("request-sub-type", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Request Sub Type")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </>
                )}


                {checksublogs && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        setMobileOpen(false);
                        setopenMobilegeneral(false);
                        setopenMobilemanagementservice(false);
                        setopenMobilemanagementrequest(false);
                        setopenMobilelogs(!openMobilelogs);
                      }}
                    >
                      <ListItemText primary={`${t("Logs")}`}/>
                      {openMobilelogs ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </ListItem>
                    <Collapse
                      in={openMobilelogs}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Divider />
                      <List component="div" disablePadding>
                      {checkconfig_logs_login_logs && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("login-logs", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Login Logs")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_logs_request_logs && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("request-logs", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Request Logs")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {checkconfig_logs_business_information_logs && (
                          <ListItem
                            disablePadding
                            onClick={() =>
                              clicknavItemsisLogin("business-information-logs", "")
                            }
                          >
                            <ListItemButton sx={{ textAlign: "left" }}>
                              <ListItemText
                                inset
                                primary={t("Business Information Logs")}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
             {/* <ListItem
                button
                key={"FAQ"}
                disablePadding
                onClick={() => clicknavItemsisLogin("FAQ", "")}
              >
                <ListItemButton sx={{ textAlign: "left" }}>
                  <ListItemText primary={t("FAQ")} />
                </ListItemButton>
              </ListItem> */}
          </List>
        </Box>
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{
          background: "#ffffff",
          color: "#000000",
          position: "fixed",
          height: "90px",
          justifyContent: "center",
        }}
      >
        {!LoginReducer.result && (
          <Toolbar>
            <Link href="/home" color="inherit">
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <img
                  src={smeimage}
                  style={{ display: "flex", height: "80px" }}
                />
              </Box>
            </Link>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              {navItems.map((item) => (
                <>
                  {item == "Service" ? (
                    <>
                      <Button
                        key="Admin"
                        // onClick={() => clicknavItemsisLogin("Admin","")}
                        sx={{
                          textTransform: "none",
                          color: "black",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        onClick={handleClickservice}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {t("Service")}
                      </Button>
                      <Menu
                        disableScrollLock
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                          //  onMouseLeave: handleClose,
                        }}
                        anchorEl={anchorElservice}
                        open={openservice}
                        onClose={handleCloseservice}
                      >
                        <IconMenuItem
                              onClick={() =>
                                clicknavItemsisLogin(`all-services`, "")
                              }
                              leftIcon={<ListAltIcon />}
                              label={`${t(`All Services`)}`}
                            />
                       {service_type_list &&
                          service_type_list.map((x: any, i: any) => (
                            <Box>
                            {i18n.language === "en" ?(
                            <IconMenuItem
                              onClick={() =>
                                clicknavItemsisLogin(`${x.id}`, "havesubmenu")
                              }
                              leftIcon={<ListAltIcon />}
                              label={`${t(`${x.name}`)}`}
                            />
                            )
                          :(
                          <IconMenuItem
                              onClick={() =>
                                clicknavItemsisLogin(`${x.id}`, "havesubmenu")
                              }
                              leftIcon={<ListAltIcon />}
                              label={`${x.name_th}`}
                            />
                          )
                            }
                            </Box>
                          ))}
                      </Menu>
                    </>
                  ) : (
                    <MenuItem
                      key={item}
                      onClick={() => handleCloseNavMenu(item)}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ color: "black", fontWeight: "400" }}
                      >
                        {t(item)}
                      </Typography>
                    </MenuItem>
                  )}
                </>
              ))}
            </Box>

            <Box sx={{ flexGrow: 1 }}> </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Select
                MenuProps={{ disableScrollLock: true }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={languages}
                label="Age"
                onChange={handleChangelanguage}
                variant="outlined"
                sx={{
                  ".MuiSvgIcon-root ": {
                    // fill: "white !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                }}
              >
                {Alllanguages.map(({ code, name }) => (
                  <MenuItem
                    key={code}
                    value={code}
                    onClick={() => handleChangelanguage}
                  >
                    {t(name)}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              {/* <IconButton size="small" sx={{mr:1}} onClick={() => navigate("/your-cart")}>
              <Badge badgeContent={countcart} color="error">
              <ShoppingCartIcon id="cartnotlogin" fontSize="medium" sx={{color: "#876445",size:"18px",alignSelf:"center" }} />
              </Badge>
              </IconButton> */}

              <ButtonRegister
                onClick={() => navigate("/register")}
                size="medium"
                sx={{ display: { marginLeft: "0.5rem" } }}
              >
                {t("Register")}
              </ButtonRegister>
              <ButtonLogin
                onClick={() => navigate("/login")}
                size="medium"
                variant="outlined"
                sx={{ display: { marginLeft: "0.5rem" } }}
              >
                {t("Login")}
              </ButtonLogin>
            </Box>
          </Toolbar>
        )}
        {LoginReducer.result && (
          <Toolbar>
            {/* dashboard */}
            <Link href="#" color="inherit">
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <img
                  src={smeimage}
                  style={{ display: "flex", height: "80px" }}
                />
              </Box>
            </Link>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              {navItemsisLogin.map((item:any) => (
                <>
                  {item == "Service" ? (
                    <>
                      <Button
                        key="Admin"
                        // onClick={() => clicknavItemsisLogin("Admin","")}
                        sx={{
                          textTransform: "none",
                          color: "black",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        onClick={handleClickservice}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {t("Service")}
                      </Button>
                      <Menu
                        disableScrollLock
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                          //  onMouseLeave: handleClose,
                        }}
                        anchorEl={anchorElservice}
                        open={openservice}
                        onClose={handleCloseservice}
                      >
                         <IconMenuItem
                              onClick={() =>
                                clicknavItemsisLogin(`all-services`, "")
                              }
                              leftIcon={<ListAltIcon />}
                              label={`${t(`All Services`)}`}
                            />
                        {service_type_list &&
                          service_type_list.map((x: any, i: any) => (
                            <Box>
                            {i18n.language === "en" ?(
                            <IconMenuItem
                              onClick={() =>
                                clicknavItemsisLogin(`${x.id}`, "havesubmenu")
                              }
                              leftIcon={<ListAltIcon />}
                              label={`${t(`${x.name}`)}`}
                            />
                            )
                          :(
                          <IconMenuItem
                              onClick={() =>
                                clicknavItemsisLogin(`${x.id}`, "havesubmenu")
                              }
                              leftIcon={<ListAltIcon />}
                              label={`${x.name_th}`}
                            />
                          )
                            }
                            </Box>
                          ))}
                      </Menu>
                    </>
                  ) : (
                    <MenuItem
                      key={item}
                      onClick={() => clicknavItemsisLogin(item, "")}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ color: "black", fontWeight: "400" }}
                      >
                        {t(item)}
                      </Typography>
                    </MenuItem>
                  )}
                </>
              ))}

              {user_type != "Member" ? (
                <div>
                  {checkmainconfig && (
                    <>
                      <Button
                        key="Admin"
                        // onClick={() => clicknavItemsisLogin("Admin","")}
                        sx={{
                          textTransform: "none",
                          color: "black",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        onClick={handleClick}
                        // onMouseEnter={() => {console.log("Adminhover")}}
                        // onMouseLeave={() => {console.log("Adminleave")}}
                        // onMouseOver={handleClick}
                        // onMouseOut={handleClose}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {t("Configuration")}
                      </Button>
                      <Menu
                        disableScrollLock
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                          //  onMouseLeave: handleClose,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {/* <IconMenuItem   onClick={() => clicknavItemsisLogin("member-management","")}  leftIcon={<ManageAccountsIcon />}  label={`${t("Member Management")}`} />  */}
                        {checksubgeneral && (
                          <NestedMenuItem
                            leftIcon={<AssignmentIcon />}
                            rightIcon={<KeyboardArrowRightIcon />}
                            label={`${t("General")}`}
                            parentMenuOpen={open}
                          >
                            {checkconfig_general_menu && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("general-menu", "")
                                }
                                leftIcon={<AssignmentIcon />}
                                label={`${t("Menu")}`}
                              />
                            )}
                            {checkconfig_general_users && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("general-users", "")
                                }
                                leftIcon={<AssignmentIcon />}
                                label={`${t("Users")}`}
                              />
                            )}
                            {checkconfig_general_roles && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("general-roles", "")
                                }
                                leftIcon={<AssignmentIcon />}
                                label={`${t("Roles")}`}
                              />
                            )}
                            {checkconfig_general_department && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("general-department", "")
                                }
                                leftIcon={<AssignmentIcon />}
                                label={`${t("Department")}`}
                              />
                            )}
                             
                          </NestedMenuItem>
                        )}

                        {checksubmanagementservice && (
                          <NestedMenuItem
                            leftIcon={<MdHomeRepairService fontSize="24px" />}
                            rightIcon={<KeyboardArrowRightIcon />}
                            label={`${t("Service Management")}`}
                            parentMenuOpen={open}
                          >
                            {checkconfig_management_service_type && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("service-type", "")
                                }
                                leftIcon={<MdHomeRepairService fontSize="24px" />}
                                label={`${t("Service Type")}`}
                              />
                            )}
                            {checkconfig_management_agreementgroup && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin(
                                    "service-group-management",
                                    ""
                                  )
                                }
                                leftIcon={<MdHomeRepairService fontSize="24px" />}
                                label={`${t("Form Group")}`}
                              />
                            )}
                            {checkconfig_management_agreement && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("service-management", "")
                                }
                                leftIcon={<MdHomeRepairService fontSize="24px" />}
                                label={`${t("Form PDF")}`}
                              />
                            )}
                              
                          </NestedMenuItem>
                        )}


                      {checksubmanagementrequest && (
                          <NestedMenuItem
                            leftIcon={<VscGitPullRequestGoToChanges fontSize="24px" />}
                            rightIcon={<KeyboardArrowRightIcon />}
                            label={`${t("Request Management")}`}
                            parentMenuOpen={open}
                          >
                            {checkconfig_general_request_type && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("request-type", "")
                                }
                                leftIcon={<VscGitPullRequestGoToChanges fontSize="24px" />}
                                label={`${t("Request Type")}`}
                              />
                            )}
                            {checkconfig_general_request_sub_type && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("request-sub-type", "")
                                }
                                leftIcon={<VscGitPullRequestGoToChanges fontSize="24px" />}
                                label={`${t("Request Sub Type")}`}
                              />
                            )}
                          </NestedMenuItem>
                        )}

                          {checksublogs && (
                          <NestedMenuItem
                            leftIcon={<MdOutlineHistory fontSize="24px" />}
                            rightIcon={<KeyboardArrowRightIcon />}
                            label={`${t("Logs")}`}
                            parentMenuOpen={open}
                          >
                            {checkconfig_logs_login_logs && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("login-logs", "")
                                }
                                leftIcon={<MdOutlineHistory fontSize="24px" />}
                                label={`${t("Login Logs")}`}
                              />
                            )}
                            {checkconfig_logs_request_logs && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("request-logs", "")
                                }
                                leftIcon={<MdOutlineHistory fontSize="24px" />}
                                label={`${t("Request Logs")}`}
                              />
                            )}
                             {checkconfig_logs_business_information_logs && (
                              <IconMenuItem
                                onClick={() =>
                                  clicknavItemsisLogin("business-information-logs", "")
                                }
                                leftIcon={<MdOutlineHistory fontSize="24px" />}
                                label={`${t("Business Information Logs")}`}
                              />
                            )}
                          </NestedMenuItem>
                        )}


                      </Menu>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
              {/* <MenuItem
                      key={"FAQ"}
                      onClick={() => clicknavItemsisLogin("FAQ", "")}
                    >
                      <Typography
                        textAlign="center"
                        sx={{ color: "black", fontWeight: "400" }}
                      >
                        {t("FAQ")}
                      </Typography>
                    </MenuItem> */}
            </Box>

            <Box sx={{ flexGrow: 1 }}> </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Select
                MenuProps={{ disableScrollLock: true }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={languages}
                label="Age"
                onChange={handleChangelanguage}
                variant="outlined"
                sx={{
                  ".MuiSvgIcon-root ": {
                    // fill: "white !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                  },
                }}
              >
                {Alllanguages.map(({ code, name }) => (
                  <MenuItem
                    key={code}
                    value={code}
                    onClick={() => handleChangelanguage}
                  >
                    {t(name)}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <IconButton size="small" sx={{mr:1}} onClick={handleOpenNoticMenu}>
                <Badge badgeContent={countnotic} color="error">

                  <NotificationsIcon id="cartnotlogin" fontSize="medium" sx={{color: "#876445",size:"18px",alignSelf:"center" }} />
                </Badge>
              </IconButton>

              {/* notic menu */}
              <Menu
                disableScrollLock
                sx={{ 
                  mt: "45px",
                }}
                id="menu-appbar"
                anchorEl={anchorElUserNotic}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUserNotic)}
                onClose={handleCloseNoticMenu}
                PaperProps={{
                  sx: {
                    minWidth: "370px", // กำหนดความกว้างของ Paper (ตัว menu)
                    width: "370px"     // กำหนดความกว้างคงที่
                  }
                }}

              >
                  <Grid container sx={{padding: "20px 20px 0px 20px"}}>
                    <Grid item xs={10} sx={{ display: "flex"}}>
                      <Badge 
                        badgeContent={countnotic} 
                        color="error" 
                      >
                        <NotificationsIcon id="cartnotlogin" fontSize="large" sx={{color: "#876445",size:"18px"}} />
                      </Badge>
                      <Typography 
                        sx={{
                          paddingLeft: "15px",
                          paddingTop: "5px",
                          fontSize: "20px",
                          fontWeight: "700", 
                          fontFamily: "Arial",
                          color: "#876445"
                        }}
                      >Notifications</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end"}}>
                      <IconButton onClick={handleCloseNoticMenu}>
                        <CloseIcon   id="cartnotlogin" fontSize="medium" sx={{color: "#876445",size:"18px",alignSelf:"center" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider 
                    sx={{ 
                      my: 1, 
                      borderBottomWidth: 1, 
                      borderColor: "#f4d0b0",
                      width: "90%", 
                      mx: "auto"
                    }}
                  />
                  <Grid >
                  { 
                      notic_list.length > 0 ? notic_list.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                          <MenuItem  component="a" href={`${item.link_data}`} onClick={handleCloseNoticMenu}>
                            <ListItemIcon>
                              <Grid container sx={{padding: "0 0 0 10px"}}>
                                <Grid item xs={12} sx={{ display: "flex"}}>
                                  <Typography 
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "700", 
                                      fontFamily: "Arial",
                                      color: "#876445"
                                    }}
                                  >
                                    Request No.{item.request_no} {/* แสดง request_no */}
                                  </Typography>
                                </Grid>
           
                                <Grid item xs={12} sx={{ display: "flex"}}>
                                  <Typography 
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "300", 
                                      fontFamily: "Arial",
                                      color: "#876445"
                                    }}
                                  >
                                    {
                                      item.status == "msg" ? (
                                        <>
                                        {item.name} has made a comment. {/* แสดงข้อมูลอื่นๆ */}
                                        </>
                                      ):(
                                        <>
                                        {
                                          item.status == "10" ? (
                                            <>
                                              {item.name}  has changed the request <br/>due date to <b>{item.status_name} </b>{/* แสดงข้อมูลอื่นๆ */}
                                            </>
                                          ):(
                                            <>
                                              {item.name}  changed status to <b>{item.status_name} </b>{/* แสดงข้อมูลอื่นๆ */}
                                            </>
                                          )
                                        }
                                        </>
                                      )
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItemIcon>
                          </MenuItem>
                          <Divider 
                            sx={{ 
                              my: 1, 
                              borderBottomWidth: 1, 
                              borderColor: "#f4d0b0",
                              width: "90%", 
                              mx: "auto"
                            }}
                          />
                        </React.Fragment>
                      )): (
                        <>
                        <MenuItem >
                            <ListItemIcon>
                              <Grid container sx={{padding: "0 0 0 10px"}}>
                                <Grid item xs={12} sx={{ display: "flex"}}>
                                  <Typography sx={{ fontSize: "14px", color: "#876445" }}>
                                      No notifications available.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItemIcon>
                            </MenuItem>
                              <Divider 
                              sx={{ 
                                my: 1, 
                                borderBottomWidth: 1, 
                                borderColor: "#f4d0b0",
                                width: "90%", 
                                mx: "auto"
                              }}
                            />
                        </>
                        
                      )
                    }
                </Grid>
              </Menu>

                {/* end notic menu */}
              {/* <IconButton  size="small" sx={{mr:1}} onClick={() => navigate("/your-cart")}>
              <Badge badgeContent={countcart} color="error">
              <ShoppingCartIcon id="cartlogin" fontSize="medium" sx={{color: "#876445",size:"18px",alignSelf:"center" }} />
              </Badge>
              </IconButton> */}
              
              <Typography sx={{  color: "#000000" ,alignSelf:"center"}}>
                {t("Welcome")} {contact_name}
              </Typography>
              </Box>
            <Box
              sx={{
                pl: 1,
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              {/* <Tooltip title="Open settings"> */}
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* src="/static/images/avatar/2.jpg" */}
                <Avatar
                  alt={avatarname}
                  sx={{ backgroundColor: "#f4d0b0", color: "#F2903A" }}
                />
              </IconButton>
              {/* </Tooltip> */}

              <Menu
                disableScrollLock
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {menulist.map((menuname) => (
                  <MenuItem
                    key={menuname.name}
                    onClick={() => handleCloseUserMenu(menuname.id)}
                  >
                    <ListItemIcon>
                      {menuname.id == 1 ? (
                        <AccountCircleIcon fontSize="small" />
                      ) : (
                        ""
                      )}
                      {menuname.id == 2 ? (
                        <ChangeCircleIcon fontSize="small" />
                      ) : (
                        ""
                      )}
                      {menuname.id == 3 ? <LogoutIcon fontSize="small" /> : ""}
                    </ListItemIcon>
                    <Typography textAlign="center">
                      {t(`${menuname.name}`)}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        )}
      </AppBar>
      <nav>
        <Drawer
          disableScrollLock
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "none",
              xl: "none",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
